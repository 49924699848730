import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Draggable from 'react-draggable'
import axios from '../../../api'
import ax from 'axios'
import { connect } from 'react-redux'
import { Divider, Loader, Slider } from '@mantine/core'

import { setPopup, changePreferredSpeaker } from '../../../actions'

import { Icon, Message } from 'semantic-ui-react'
import SoundPopupStyles, { Speaker } from './SoundPopupStyles'

import ProgressiveSettingsToggle from '../admin/ProgressiveSettingsToggle'

import { recordSplit, progressify, createFuriData, patchReading } from '../admin/editor/SoundIcon'
import AdminAccessFields from './AdminAccessFields'

import useMobile from '../../../hooks/useMobile'
import useStaffRoles from '../../../hooks/useStaffRoles'

import kanako from '../../../assets/sound_popup/vocals_kanako.png'
import takeru from '../../../assets/sound_popup/vocals_takeru.png'
import hikari from '../../../assets/sound_popup/vocals_hikari.png'
import decoration from '../../../assets/sound_popup/page_accent_top.svg'
import { FlexRow, PaddingTop4Div } from 'src/assets/styles/globalStyles'
import { CopyToClipboardButton } from '../sublessonpart/WidgetEditView'
import SoundBugReport from './SoundBugReport'
import useSoundText from 'src/hooks/useSoundText'

import { AudioContext } from 'src/contexts/AudioContext'

/* eslint-disable */
const speaker = {
  kanako: kanako,
  yukari: kanako,
  takeru: takeru,
  hikari: hikari,
  seoyeon: hikari,
  Seoyeon: hikari,
  tomoko: hikari,
  natsumi: hikari,
  andres: takeru,
  mia: hikari,
  fukumachi: takeru,
  jiyoon: hikari,
  hyunwoo: takeru,
  sunhee: hikari,
}

const speakerNameMap = {
  kanako: 'Kanako',
  yukari: 'Yukari',
  takeru: 'Takeru',
  hikari: 'Hikari',
  fukumachi: 'Mr. Fukumachi',
  tomoko: 'Tomoko',
  natsumi: 'Natsumi',
  andres: 'Andres',
  mia: 'Mia',
  seoyeon: 'Seoyeon',
  jiyoon: 'jiyoon',
  hyunwoo: 'hyunwoo',
  sunhee: 'sunhee',
}
/* eslint-enable */

export const RenderProgSettingChoice = (props) => {
  const { furis, splitRecord } = recordSplit(props.record)
  const newFuriData = createFuriData(splitRecord, furis)
  const { soundText } = useSoundText(props.record, {
    ...props, main_default: props.localProgSettings, setHoldsFuri: () => null
  })

  if (!['progressive', 'hirakata', 'romaji', 'kanji'].includes(props.localProgSettings)) {
    return <span key={'popup-english'}>{props.english || props.record?.english?.text || props.record?.english}</span>
  }

  return soundText
}

const AddToNotebook = (props) => {
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const [category, setCategory] = useState(null)
  const [newCategory, setNewCategory] = useState('')
  const [isNewCategory, setIsNewCategory] = useState(false)
  const [added, setAdded] = useState(false)
  const [error, setError] = useState(false)
  const [categoryCreationError, setCategoryCreationError] = useState([false, ''])
  const [showOptions, setShowOptions] = useState(false)

  const { location } = props

  const fetchCategories = () => {
    setLoading(true)

    axios
      .get('/notebook/categories')
      .then((res) => {
        const incomingCats = res.data.categories?.map((c) => {
          return {
            text: c.title,
            value: c._id,
          }
        })

        setCategory(incomingCats[0])
        setNewCategory(incomingCats[0].text)
        setCategories(incomingCats)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    setAdded(false)
  }, [category])

  const addToNotebook = () => {
    if (!category || loading) return
    setLoading(true)

    axios
      .post(`/notebook/category/${category.value}/new_entry`, {
        record: props.record._id,
        location: location.pathname,
      })
      .then((res) => {
        if (res.data.success) {
          setAdded(true)
        } else {
          setError(true)
        }
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setCategoryCreationError([true, 'backend'])
        setLoading(false)
        setError(true)
      })
  }

  const handleSubmit = () => {
    if (loading) return
    setLoading(true)

    if (isNewCategory) {
      if (!newCategory?.trim().length) return

      axios
        .post('/notebook/category/new', {
          title: newCategory.trim(),
          record: props.record._id,
          isNewCategoryAndEntry: isNewCategory,
        })
        .then((res) => {
          if (res.data.success) {
            setNewCategory('')
            setShowOptions(false)
            setLoading(false)
            setAdded(true)
            setError(false)
            fetchCategories()
          } else {
            setError(true)
          }
        })
        .catch((err) => {
          console.error(err)
          setCategoryCreationError([true, 'backend'])
          setLoading(false)
        })
    } else {
      addToNotebook()
    }
  }

  const handleSelectCategory = (_category) => {
    setCategory(_category)
    setNewCategory(_category?.text)
    setShowOptions(false)
    setIsNewCategory(false)
  }

  const handleNewCategory = (value) => {
    if (!value || !value.trim().length) {
      setCategoryCreationError([false, ''])
      setNewCategory(value)
      return
    }
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].text === value) {
        handleSelectCategory(categories[i])
        setCategoryCreationError([true, 'existing'])
        setIsNewCategory(false)
        break
      } else {
        setCategoryCreationError([true, 'new'])
        setNewCategory(value)
        setIsNewCategory(true)
      }
    }
  }

  return (
    <div className='add-notebook'>
      <h2 className='no-select'>Add to Notebook</h2>
      {categoryCreationError[0] && categoryCreationError[1] === 'new' && (
        <aside className='no-select'>
          <Icon name='warning circle' />A new category will be added to your notebook!
        </aside>
      )}
      {categoryCreationError[0] && categoryCreationError[1] === 'existing' && (
        <aside className='no-select'>
          <Icon name='warning circle' />
          That category already exists! :) You can still add to this category!
        </aside>
      )}
      {categoryCreationError[0] && categoryCreationError[1] === 'backend' && (
        <aside className='no-select'>
          <Icon name='warning circle' />
          Oops! We&apos;re having trouble saving your category right now!
        </aside>
      )}
      <div className='selinput'>
        <div>
          <div
            className={`pseudo-select no-select flex-center ${showOptions && 'focused'}`}
            tabIndex='0'
            onClick={() => setShowOptions((prev) => !prev)}
            onTouchEnd={() => setShowOptions((prev) => !prev)}
            onKeyUpCapture={(e) => e.key === 'Enter' && setShowOptions((prev) => !prev)}
            onBlur={() => props.handleMouseLeave()}
          >
            <p>▼</p>
          </div>
          <label
            htmlFor='category'
            style={{
              position: 'absolute',
              left: '-10000px',
              top: 'auto',
              width: '1px',
              height: '1px',
              overflow: 'hidden',
            }}
          >
            Select or type a category
          </label>
          <input
            id='category'
            type='text'
            placeholder='Select or Type a Category'
            onChange={(e) => handleNewCategory(e.target.value)}
            value={newCategory}
            onPointerDown={() => props.setNoDrag(true)}
            onTouchEnd={(e) => e.target.focus()}
            onFocus={() => props.setNoDrag(true)}
            onBlur={() => props.handleMouseLeave()}
          />
          {showOptions && (
            <div className='pseudo-options' onMouseLeave={() => setShowOptions(false)}>
              <div
                tabIndex='0'
                onClick={() => handleSelectCategory({ text: '' })}
                onTouchEnd={() => handleSelectCategory({ text: '' })}
                onKeyUpCapture={(e) => e.key === 'Enter' && handleSelectCategory({ text: '' })}
                onBlur={() => props.handleMouseLeave()}
              >
                + Add New
              </div>
              {categories.map((c) => (
                <div
                  tabIndex='0'
                  key={`${c.value}`}
                  onClick={() => handleSelectCategory(c)}
                  onTouchEnd={() => handleSelectCategory(c)}
                  onKeyUpCapture={(e) => e.key === 'Enter' && handleSelectCategory(c)}
                  onBlur={() => props.handleMouseLeave()}
                >
                  {c.text}
                </div>
              ))}
            </div>
          )}
        </div>
        <button onClick={handleSubmit} onTouchEnd={handleSubmit} disabled={loading}>
          Add
        </button>
      </div>
      {props.noDrag && false && (
        <>
          <aside style={{ fontFamily: 'Jost', margin: '10px 0 0' }}>
            <Icon name='warning circle' /> Dragging locked while you type.
          </aside>
          <aside style={{ fontFamily: 'Jost', margin: '0 0 15px 24px' }}>
            Click twice outside the input field to drag again!
          </aside>
        </>
      )}
      {added && <Message success>Added to notebook successfully!</Message>}
      {error && <Message error>Already added to that notebook category!</Message>}
    </div>
  )
}

const HeartClick = ({ preferred, speaker: sp, onHeart }) => {
  const isPreferred = preferred === sp
  const nameToSet = isPreferred ? 'Kanako' : sp

  return (
    <Icon
      color={isPreferred ? 'red' : 'white'}
      name='heart'
      fitted
      // onClick={() => {
      //   console.log('clicked heart', nameToSet)
      //   onHeart(nameToSet)
      // }}
      onPointerUp={() => {
        // console.log('on pointer up', nameToSet)
        onHeart(nameToSet)
      }}
    />
  )
}

// filter out non-ascii characters and convert to snake case
const convertEnglishToSnakeCase = (english) => {
  if (!english?.text) return ''
  const asciiOnly = english.text?.replace(/\s+/g, '-')?.replace(/[^A-Za-z0-9-]/g, '')
  return asciiOnly?.toLowerCase()
}

const handleDownload = async (url, filename) => {
  try {
    const response = await ax.get(url, {
      responseType: 'blob'
    })

    const blob = new Blob([response.data], { type: 'audio/mpeg' })
    const blobUrl = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = blobUrl
    link.download = filename

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(blobUrl)
  } catch (err) {
    console.error(err)
  }
}

const DownloadAudio = ({ audioFor, downloadSrc, locationPathname, english }) => {
  const [downloaded, setDownloaded] = useState(false)
  const snakeEnglish = convertEnglishToSnakeCase(english)

  return (
    <button
      style={{ backgroundColor: 'transparent' }}
      onClick={() => {
        setDownloaded(true)
        handleDownload(downloadSrc, `${snakeEnglish}.mp3`)
      }}
    >
      <Icon
        color={downloaded ? 'teal' : 'white'}
        name='download'
        fitted
      />
    </button>
  )
}

const handleDisplayName = (speakerName) => {
  let newName = ''
  switch (speakerName.toLowerCase()) {
    case 'takeru':
      newName = 'Kairo'
      break
    case 'mr. fukamachi':
      newName = 'Fukamachi'
      break
    default:
      newName = speakerName
  }

  // capitalize first letter
  return newName.charAt(0).toUpperCase() + newName.slice(1)
}

// return dict mapping speaker names to audio objects only if that audio src
// returns a 200 status code
const checkSoundsHead = async (sounds) => {
  // console.log('sounds', sounds)
  const newDict = {}
  // eslint-disable-next-line
  // for (const [k, v] of Object.entries(sounds)) {
  //   // console.log('k', k, 'v', v)
  //   try {
  //     // eslint-disable-next-line no-await-in-loop
  //     await ax.head(typeof v === 'string' ? v : v?.cf_url)
  //     newDict[k] = new Audio(typeof v === 'string' ? v : v?.cf_url)
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  // turn above into Promise.all
  const promises = Object.keys(sounds).map(async (k) => {
    try {
      // await ax.head(typeof sounds[k] === 'string' ? sounds[k] : sounds[k]?.cf_url)
      return [k, new Audio(typeof sounds[k] === 'string' ? sounds[k] : sounds[k]?.cf_url)]
    } catch (err) {
      console.error(err)
    }
  })

  const resolved = (await Promise.all(promises)).filter(Boolean)
  resolved.forEach((r) => {
    const [key, audio] = r
    newDict[key] = audio
  })
  return newDict
}

const RecordSounds = ({
  record, preferred_speaker, isMobile, savePreferredSpeaker, audioFor, adminAccess
}) => {
  const [sources, setSources] = useState({})
  const [loading, setLoading] = useState(false)
  const { audioPlaybackRate } = useContext(AudioContext)

  const resolveSounds = useCallback(async () => {
    if (!record?.sounds) return
    // const newDict = {}
    // eslint-disable-next-line
    // Object.keys(record?.sounds).forEach((k) => (newDict[k] = new Audio(record.sounds[k])))
    setLoading(true)
    const ss = await checkSoundsHead(record.sounds)
    setLoading(false)
    setSources(ss)
  }, [record])

  useEffect(() => {
    resolveSounds()
  }, [record])

  useEffect(() => {
    if (typeof sources !== 'object') return
    if (Object.keys(sources).length === 0) return
    // eslint-disable-next-line
    Object.keys(sources).forEach((k) => (sources[k].playbackRate = audioPlaybackRate))
  }, [sources, audioPlaybackRate])

  if (loading) return <div className='flex-center' style={{ marginTop: '10px' }}><Loader /></div>

  return (
    <div className='record-sounds'>
      {/* filter out 'Yukari' and 'Mr. Fukamachi' keys */}
      {Object.keys(sources).filter((k) => k.toLowerCase() !== 'yukari' && k.toLowerCase() !== 'mr. fukamachi').map((k, idx) => (
        <div
          tabIndex='0'
          key={`${speaker}-${idx}-${record?._id}`}
          className='flex-center'
          style={{ whiteSpace: 'nowrap' }}
        >
          <Speaker
            isDefault={speakerNameMap[k.toLowerCase()] === preferred_speaker}
            isMobile={isMobile}
          >
            <img
              src={speaker[k.toLowerCase()] || takeru}
              onClick={() => sources[k].play()}
              onKeyUpCapture={(e) => e.key === 'Enter' && sources[k].play()}
              onTouchEnd={() => sources[k].play()}
              draggable={false}
              className='no-select'
              alt={`Voice actor ${k}`}
            />
          </Speaker>
          {handleDisplayName(k)}
          <div className='tw-flex tw-items-center tw-gap-4'>
            <HeartClick
              speaker={speakerNameMap[k.toLowerCase()]}
              onHeart={savePreferredSpeaker}
              preferred={preferred_speaker}
            />
            {adminAccess && (
              <div className='tw-mt-1'>
                <DownloadAudio
                  audioFor={audioFor}
                  downloadSrc={sources?.[k]?.src}
                  locationPathname={location.pathname}
                  english={record.english}
                />
              </div>
            )}
          </div>
        </div>
      ))}
      {/* slider to control playback rate */}
      {/* <RangeSlider value={rangeValue} onChange={setRangeValue} /> */}
    </div>
  )
}

const MARKS = [
  { value: 12.5, label: '25%' },
  { value: 25, label: '50%' },
  { value: 37.5, label: '75%' },
  { value: 50, label: '100%' },
  { value: 62.5, label: '125%' },
  { value: 75, label: '150%' },
  { value: 87.5, label: '175%' },
  { value: 100, label: '200%' },
]

const SoundPopup = (props) => {
  // set by redux state
  const [record, setRecord] = useState(null)
  const [note, setNote] = useState(null)
  const [english, setEnglish] = useState(props.soundPopup?.english)
  const [widget, setWidget] = useState(null)
  const [path, setPath] = useState(null)
  const [onSave, setOnSave] = useState(null)
  const [playbackValue, setPlaybackValue] = useState(1)
  const { setAudioPlaybackRate } = useContext(AudioContext)

  const widgetType = props.soundPopup?.widgetType

  // map of speaker names to whether a HEAD request has been made for their audio
  // and came back with a 200 status code
  const [audioStatus, setAudioStatus] = useState({})

  // console.log(props)

  const [localProgSettings, setLocalProgSettings] = useState(
    props.progressive_settings?.main_default
  )

  // used for positioning
  const [noDrag, setNoDrag] = useState(false)
  const [transform, setTransform] = useState({
    x: 0,
    y: 0,
  })
  const [recentlyScrolled, setRecentlyScrolled] = useState(false)

  const location = useLocation()
  const isMobile = useMobile()
  const [adminAccess] = useStaffRoles('admin', 'dev', 'teacher')

  const handleKeyShortcuts = (e) => {
    if (e.key === 'Escape') props.setPopup(null)
  }

  const onScroll = () => {
    if (recentlyScrolled) return

    if (window.scrollY > transform.y) {
      setRecentlyScrolled(true)
      setTransform({
        ...transform,
        y: window.scrollY,
      })
      setTimeout(() => setRecentlyScrolled(false), 100)
    }
  }

  const savePreferredSpeaker = (name) => {
    props.changePreferredSpeaker(name)
    axios
      .post('/user/change_preferred_speaker', { preferred_speaker: name })
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    if (record == null) return
    document.addEventListener('scroll', onScroll)

    return () => document.removeEventListener('scroll', onScroll)
  }, [record])

  useEffect(() => {
    if (record == null) return
    document.addEventListener('keydown', handleKeyShortcuts)
    return () => document.removeEventListener('keydown', handleKeyShortcuts)
  }, [record])

  useEffect(() => {
    if (!props.soundPopup?.record) return
    setAudioPlaybackRate(playbackValue)
  }, [playbackValue])

  useEffect(() => {
    setRecord(props.soundPopup.record)
    setNote(props.soundPopup.note)
    const e = ['TextBoxWidget', 'TableWidget'].includes(widgetType)
      ? props.soundPopup.record?.english || props.soundPopup.english
      : props.soundPopup.english || props.soundPopup.record?.english
    setEnglish(e)
    setTransform({
      x: props.soundPopup.x,
      y: props.soundPopup.y,
    })
    setPath(props.soundPopup.path)
    setOnSave(props.soundPopup.onSave)
    setWidget(props.soundPopup.widget)
  }, [props.soundPopup])

  const resetPopupState = () => {
    handleKeyShortcuts({ key: 'Escape' }) // sets redux popup state back to null
    setRecord(null)
  }

  useEffect(() => {
    resetPopupState()
  }, [location])

  const handleCloseModal = () => {
    handleKeyShortcuts({ key: 'Escape' })
  }

  const handleMouseLeave = () => {
    setNoDrag(false)
  }

  const handleProgSettingsToggleChange = (updatedSetting) => {
    if (localProgSettings !== updatedSetting) {
      setLocalProgSettings(updatedSetting)
    }
  }

  return (
    <>
      {record && record._id && (
        <Draggable
          // cancel=".mantine-Slider-root, .mantine-Slider-thumb, .mantine-Slider-bar, .mantine-Slider-track"
          disabled={noDrag} // stops dragging (used while user is in input fields)
          enableUserSelectHack={false} // removes built-in 'no-select' class
          positionOffset={isMobile
            ? { x: window.innerWidth / 2 - 400 / 2, y: transform.y } // moved left to show "x"
            : { x: transform.x, y: transform.y }
          }
        >
          <SoundPopupStyles className='draggable'>
            <div style={{ width: '100%' }}>
              <div
                tabIndex='0'
                className='exit flex-center'
                onClick={handleCloseModal}
                onKeyUpCapture={(e) => e.key === 'Enter' && handleCloseModal()}
                onTouchEnd={handleCloseModal}
              >
                <div>
                  <Icon name='close' className='flex-center' />
                </div>
              </div>
              <img
                src={decoration}
                alt='popup decoration'
                className='decoration top no-select'
                draggable={false}
              />
              <img
                src={decoration}
                alt='popup decoration'
                className='decoration bottom no-select'
                draggable={false}
              />
              {adminAccess && (
                <AdminAccessFields
                  english={english}
                  handleMouseLeave={handleMouseLeave}
                  note={note}
                  onSave={onSave}
                  path={path}
                  record={record}
                  setEnglish={setEnglish}
                  setNoDrag={setNoDrag}
                  setNote={setNote}
                  setRecord={setRecord}
                  widget={widget}
                />
              )}
              <div style={{ margin: '10px', marginBottom: '25px' }}>
                <p style={{ color: '#eaeaea' }}>Playback Speed</p>
                <Slider
                  label={(val) => `${val * 2}% Playback Speed`}
                  marks={MARKS}
                  defaultValue={50}
                  step={12.5}
                  value={(playbackValue * 100) / 2}
                  onChange={(value) => {
                    setPlaybackValue((value * 2) / 100)
                    setNoDrag(true)
                  }}
                  onChangeEnd={(value) => {
                    setPlaybackValue((value * 2) / 100)
                    setNoDrag(false)
                  }}
                  onFocus={() => setNoDrag(true)}
                  style={{ marginBottom: '20px' }}
                />
              </div>
              <Divider style={{ paddingTop: '5px', marginTop: '15px' }} />
              <AddToNotebook
                widget={widget}
                record={record}
                noDrag={noDrag}
                localProgSettings={localProgSettings}
                progressive_data={props.progressive_data}
                setNoDrag={setNoDrag}
                handleMouseLeave={handleMouseLeave}
                location={location}
              />
              <div className='flex-center record-text'>
                <RenderProgSettingChoice
                  key={localProgSettings}
                  progressive_data={props.progressive_data}
                  record={record}
                  localProgSettings={localProgSettings}
                  english={props.soundPopup?.english}
                  bonusData={props.soundPopup?.bonusData}
                />
              </div>
              <PaddingTop4Div>
                <div className='tw-flex tw-flex-col tw-items-center tw-gap-4'>
                  {record?.type === 'JapaneseRecord' && (
                    <ProgressiveSettingsToggle
                      blackWhite
                      local
                      onToggleChange={handleProgSettingsToggleChange}
                      hasEnglish={!!record.english}
                      localProgSettings={localProgSettings}
                      setting={localProgSettings || props.progressive_data?.main_default}
                    />
                  )}
                  <CopyToClipboardButton
                    id={localProgSettings === 'english' ? record.english?.text : record[localProgSettings]}
                    leadingMessage='Copy text'
                    hideId
                  />
                </div>
              </PaddingTop4Div>
              <RecordSounds
                record={record}
                preferred_speaker={props.preferred_speaker}
                audioFor={props?.soundPopup?.record?.romaji}
                isMobile={isMobile}
                savePreferredSpeaker={savePreferredSpeaker}
                adminAccess={adminAccess}
              />
              <SoundBugReport record={record} style={{ marginTop: '10px' }} />
            </div>
            {adminAccess && <div id='admin-fields-portal'></div>}
          </SoundPopupStyles>
        </Draggable>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    soundPopup: state?.soundPopup,
    roles: state.auth.roles,
    progressive_settings: state.auth.progressive_settings,
    progressive_data: state.auth.progressive_data,
    preferred_speaker: state.auth.preferred_speaker
  }
}
export default connect(mapStateToProps, { setPopup, changePreferredSpeaker })(SoundPopup)
