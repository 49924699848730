import { useSelector } from 'react-redux'
import useNavigationContext from 'src/hooks/context/useNavigationContext'
import HamburgerIcon from './HamburgerIcon'
import FZLogo from './FZLogo'
import { AffiliateIcon, AdminIcon } from '../Icons'
import { RootState } from 'src/configureStore'

const LeftNav = () => {
  const { isMobileNav, isAdmin, isAffiliate } = useNavigationContext()
  const isSignedIn = useSelector((state: RootState) => state.auth.isSignedIn)

  if (!isSignedIn) return <FZLogo />

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <HamburgerIcon />
      <FZLogo />
      {isAdmin && !isMobileNav && <AdminIcon /> /* prio admin badge per George comment */ }
      {isAffiliate && !isAdmin && !isMobileNav && <AffiliateIcon />}
    </div>
  )
}

export default LeftNav
