import { UnstyledLink } from 'src/components/ui/styledComponents'
import { CLOUDFRONT_BASE } from 'src/util/constants'

const navbarBase = `${CLOUDFRONT_BASE}/images/navbar`
const affiliateDesktop = `${navbarBase}/nav-icon-fz-partner-desktop-revised.svg`
const affiliateMobile = `${navbarBase}/nav-icon-fz-partner-desktop.svg`
const affiliateSmall = `${navbarBase}/nav-icon-fz-partnet-mobile.svg`
const adminDesktop = `${navbarBase}/nav-icon-fz-admin-desktop-revised.svg`
const adminMobile = `${navbarBase}/nav-icon-admin-desktop.svg`
const adminSmall = `${navbarBase}/nav-icon-admin-mobile.svg`

const desktopStyle = { height: '25px', maxWidth: 'unset' } // maxWidth: 'unset' to prevent image from being squished if screen gets small
export const iconBreakpoint = 1040

export const AffiliateIcon = () => {
  return (
    <img src={affiliateDesktop} alt='FZ Partner' style={desktopStyle} />
  )
}

export const AdminIcon = () => {
  return (
    <UnstyledLink to='/admin'>
      <img src={adminDesktop} alt='Admin Icon' style={desktopStyle} />
    </UnstyledLink>
  )
}

export const Chevron = ({ left = false }) => {
  const style = { height: '13px', transform: left ? 'translateX(-1.5px)' : 'translateX(1.5px)' } // slight adjustment for centering

  return (
    <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      {left
        ? <path d="M2.57592 9.24165C0.336128 10.8365 0.33613 14.1635 2.57592 15.7584L14.1798 24.0211C16.8279 25.9067 20.5 24.0135 20.5 20.7628L20.5 4.23724C20.5 0.986444 16.8279 -0.906695 14.1798 0.978897L2.57592 9.24165Z" fill="white"/>
        : <path d="M18.4241 9.24165C20.6639 10.8365 20.6639 14.1635 18.4241 15.7584L6.82016 24.0211C4.17209 25.9067 0.5 24.0135 0.5 20.7628L0.5 4.23724C0.5 0.986444 4.1721 -0.906695 6.82016 0.978897L18.4241 9.24165Z" fill="white"/>
      }
    </svg>
  )
}
