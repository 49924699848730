import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import axios from 'src/api'
import useAPI from 'src/hooks/useAPI'
import useMobile from 'src/hooks/useMobile'
import defaultAvatar from 'src/util/defaultAvatars'
import tomoko_default from 'src/assets/chibi/default_avatars/default_standard_1.svg'
import { RootState } from 'src/configureStore'
import { UserAvatarResponse } from 'src/types'
import { REDUX_NAV_ACTIONS } from 'src/actions/types'

// I think this is way too complicated for handling an avatar
// but for now I'm not going to refactor it
// there's some commented out code in Avatar.tsx that "works" but I don't think it handles caching issues and other glitches
// fyi
const useAvatar = () => {
  const [userAvatar, setUserAvatar] = useState<string>()
  const [bgColor, setBgColor] = useState<string>()

  const { auth, navigator } = useSelector((state: RootState) => state)
  const { avatar_url, default_avatar } = auth
  const { user_avatar_cf, avatar_expires_at } = navigator

  const navigatorDispatch = useDispatch()
  const isMobile = useMobile()
  const [userAvatarCfResponse, loadingPost, postRequestUserAvatarCf, postError] = useAPI<UserAvatarResponse>('/user/signed_avatar', 'POST')

  const handleAvatarSrc = useCallback(() => {
    let updateAvatar
    let updateBgColor = '#61a1e2'
    
    if (default_avatar) {
      updateAvatar = defaultAvatar(default_avatar.color, default_avatar.version).icon
      updateBgColor = defaultAvatar(default_avatar.color, default_avatar.version).bg_color
    } else {
      updateAvatar = tomoko_default
    }
    
    setUserAvatar(updateAvatar)
    setBgColor(updateBgColor)
  }, [])

  const setReduxAvatar = (url: string, expires_at?: Date) => {
    navigatorDispatch({
      type: REDUX_NAV_ACTIONS.SET_AVATAR_CF,
      payload: {
        user_avatar_cf: url,
        avatar_expires_at: url.length ? expires_at : ''
      }
    })
  }

  const getCfPath = (path) => {
    axios
      .post('/user/signed_avatar', {
        url: path,
      })
      .then((res) => {
        setUserAvatar(res.data.url)
        setReduxAvatar(res.data.url, res.data.expires_at)
      })
      .catch((err) => console.error(err))
  }

  const getAvatarPath = () => {
    axios
      .get('/user/avatar')
      .then((res) => {
        if (res.data.success) getCfPath(res.data.avatar)
      })
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    if (userAvatarCfResponse?.success) {
      // response will be successful if the user has an s3_avatar_url on user doc
      // it returns a cached_at date which expires every 24 hours
      setUserAvatar(userAvatarCfResponse.url)
      setReduxAvatar(userAvatarCfResponse.url, userAvatarCfResponse.expires_at)
    }
    // if there is no s3_avatar_url, the response will be 404 and
    // we can set their avatar src as the default avatar on their user doc
    if (postError?.status > 399) handleAvatarSrc()
  }, [loadingPost, userAvatarCfResponse])

  useEffect(() => {
    // if the user does not have a s3_avatar_url,
    // assign them their default chibi avatar which is added to their user doc on registration
    if (!avatar_url?.trim()?.length) {
      handleAvatarSrc()
      return
    }

    // if there is no redux state for their cf url, request it from the backend
    if (avatar_url.trim().length && !user_avatar_cf) {
      postRequestUserAvatarCf({ url: avatar_url })
    }
  }, [avatar_url])

  const checkCfUrlValidity = () => {
    // checking if their cf url is expired
    const yesterday = moment().subtract(23, 'hours').subtract(59, 'minutes')
    if (avatar_url?.trim()?.length
      && avatar_expires_at
      && moment(avatar_expires_at).isBefore(yesterday)
    ) {
      // if it expired, request a new url from backend
      postRequestUserAvatarCf({ url: avatar_url })
    }
  }

  useEffect(() => {
    // if they have a cf link in redux state, set it as their avatar src
    if (!user_avatar_cf?.length) return
    checkCfUrlValidity()
  }, [user_avatar_cf, isMobile])

  useEffect(() => {
    if (avatar_url) {
      getAvatarPath()
    }
    if (user_avatar_cf?.length) {
      setUserAvatar(user_avatar_cf)
    }
  }, [isMobile])

  useEffect(() => {
    // before page unloads, unset their redux avatar state
    // avatar state is also cleared on logout,
    // but this is supposed to allow them to stay logged in and maintain a valid url...
    // ...when it works
    window.addEventListener('beforeunload', () => setReduxAvatar(''))
    return () => window.removeEventListener('beforeunload', () => setReduxAvatar(''))
  }, [])

  return { userAvatar, bgColor, handleAvatarSrc }
}

export default useAvatar
