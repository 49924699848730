import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import { AxiosError } from 'axios'

import axios from 'src/api'

import useNavigationContext from 'src/hooks/context/useNavigationContext'

import { Menu } from '@headlessui/react'
import BackgroundSettingsStyles from '../../../styles/BackgroundSettingsStyles'

import { REDUX_ACTIONS } from 'src/types'
import { ChevronDownSolid, ChevronUpSolid } from '@graywolfai/react-heroicons'

const CourseBackgroundSettings = () => {
  const [setting, setSetting] = useState<string>()
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const { courseInfo: { isKorean } } = useNavigationContext()
  const course_background = useSelector((state: RootState) => state.auth?.course_background)
  const course_background_kr = useSelector((state: RootState) => state.auth?.course_background_kr)
  const dispatchBGSetting = useDispatch()

  useEffect(() => {
    if (course_background) {
      setSetting(course_background)
      return
    }
    setSetting('Scenery')
  }, [course_background])

  useEffect(() => {
    if (course_background_kr) {
      setSetting(course_background_kr)
      return
    }
    setSetting('Hangul')
  }, [course_background_kr])

  const settingNames = {
    scenery: 'Scenery',
    kana: 'Kana',
    scenery_plus: 'Scenery+',
    stationery: 'Stationery',
    // japan_dynamic: 'Japan Dynamic (Beta)',
    // japan_day: 'Japan Day',
    // japan_night: 'Japan Night',
    // japan_dawn: 'Japan Dawn',
    study_mode: 'Study Mode',
    none: 'None',
  }
  const settingsNamesKorean = {
    hangul: 'Hangul',
    hangul_slant: 'Hangul (Slant)',
    hangul_inverted: 'Hangul (Inverted)',
    hangul_stationery: 'Stationery',
    none: 'None'
  }
  const settingsList = Object.keys(settingNames)
  const settingsListKorean = Object.keys(settingsNamesKorean)

  const changeSetting = async (course_bg_setting) => {
    setSetting(course_bg_setting)
    dispatchBGSetting({ type: REDUX_ACTIONS.CHANGE_COURSE_BACKGROUND, payload: course_bg_setting })
    try {
      await axios.post('/user/change_course_background', { course_background: course_bg_setting })
    } catch (err) {
      const error = err as AxiosError
      console.error(error?.code)
      console.error(error?.message)
    }
  }

  const changeSettingKr = async (course_bg_setting) => {
    setSetting(course_bg_setting)
    dispatchBGSetting({ type: REDUX_ACTIONS.CHANGE_COURSE_BACKGROUND_KR, payload: course_bg_setting })
    try {
      await axios.post('/user/change_course_background_kr', { course_background_kr: course_bg_setting })
    } catch (err) {
      const error = err as AxiosError
      console.error(error?.code)
      console.error(error?.message)
    }
  }

  return (
    <BackgroundSettingsStyles>
      <Menu as='div'>
        <Menu.Button onClick={() => setOpenMenu((p) => !p)} style={{ backgroundColor: 'inherit', background: 'inherit' }}>
          <span>Background:{' '}</span>
          <span style={{ fontFamily: 'Figtree', fontWeight: 500 }}>{isKorean ? settingsNamesKorean[setting] : settingNames[setting]}</span>
          {openMenu
            ? <ChevronUpSolid height='20px' className='tw-inline tw-fill-white' fill='white' />
            : <ChevronDownSolid height='20px' className='tw-inline tw-fill-white' fill='white' />
          }
        </Menu.Button>
          {openMenu && (
            <Menu.Items static className='menu-items'>
              {(isKorean ? settingsListKorean : settingsList).map((_setting: string) => (
                <Menu.Item
                  key={_setting}
                  disabled={setting === _setting}
                >
                  <p
                    className={`menu-item tw-p-0 ${setting === _setting ? 'active' : ''}`}
                    onClick={() => isKorean ? changeSettingKr(_setting) : changeSetting(_setting)}
                  >
                    {isKorean ? settingsNamesKorean[_setting] : settingNames[_setting]}
                  </p>
                </Menu.Item>
              ))}
            </Menu.Items>
          )}
      </Menu>
    </BackgroundSettingsStyles>
  )
}

export default CourseBackgroundSettings
