import { useEffect, useState } from 'react'
import { useUserStreak } from 'src/hooks/queries/user'
import { usePutStreakNotified } from 'src/hooks/mutations/user'

import { Popover } from '@mantine/core'
import { StreakSpan, FlameIcon, PopoverMessage } from '../../styles/Streak.styles'
import {
  StreakModalWrapper,
  StreakModalText,
  GoldText,
  StreakText,
  ExitModalButton,
  StreakModalTop,
  KeepItUp,
  TomokoCheer,
  StreakModalBottom,
  StreakModalBottomText,
  StreakModalBottomButton
} from '../../styles/StreakModalStyles'
import { CLOUDFRONT_BASE } from 'src/util/constants'

const streak = `${CLOUDFRONT_BASE}/images/navbar/icon-streak-flame.svg`

const StreakModal = ({ streak_count, open, onClose }) => {
  return (
    <StreakModalWrapper
      onClose={onClose}
      size='md'
      padding='md'
      withCloseButton
      opened={open}
      centered
      closeOnClickOutside={false}
    >
      <ExitModalButton onClick={onClose} />
      <div>
        <StreakModalTop>
          <StreakModalText>
            <GoldText text={`${streak_count} Day`}>
              {streak_count} Day
            </GoldText>
            <StreakText> Streak!</StreakText>
          </StreakModalText>
          <KeepItUp />
          <TomokoCheer />
        </StreakModalTop>
        <StreakModalBottom>
          <StreakModalBottomText>
            You&apos;ve studied every day for {streak_count} days!
          </StreakModalBottomText>
          <StreakModalBottomButton onClick={onClose}>
            Keep Studying
          </StreakModalBottomButton>
        </StreakModalBottom>
      </div>
    </StreakModalWrapper>
  )
}

const Streak = ({ dropdown = false }) => {
  const { data: streakData } = useUserStreak()
  const { mutate: updateStreak } = usePutStreakNotified(streakData?._id)
  const [modalOpened, setModalOpened] = useState(true)
  const [shouldAnimate, setShouldAnimate] = useState(false)

  const { streak_count, user_notified: seen, modal_acknowledged } = streakData || {}

  useEffect(() => {
    if (streak_count && !seen) {
      setShouldAnimate(true)
      updateStreak({ seen: true })
    }
  }, [streak_count])

  useEffect(() => {
    if (streak_count && !modal_acknowledged) setModalOpened(true)
  }, [streak_count, modal_acknowledged])

  return (
    <>
      {!modal_acknowledged && modal_acknowledged !== undefined && (
        <StreakModal
          open={modalOpened}
          streak_count={streak_count}
          onClose={() => {
            setModalOpened(false)
            updateStreak({ modal_acknowledged: true })
          }}
        />
      )}
      <Popover
        position='bottom-end'
        offset={4}
        radius={'lg'}
        withArrow
        arrowOffset={32}
        arrowSize={16}
        arrowRadius={4}
        shadow='0 2px 0 rgba(0, 0, 0, 0.03)'
        disabled={dropdown}
      >
        <Popover.Target>
          <div style={{ position: 'relative' }}>
            <FlameIcon
              src={streak}
              alt='Streak'
              animate={shouldAnimate}
              onAnimationEnd={() => setShouldAnimate(false)}
            />
            <StreakSpan>
              {streak_count}
            </StreakSpan>
          </div>
        </Popover.Target>
        <Popover.Dropdown>
          <PopoverMessage>
            You&apos;ve studied for {streak_count} day{streak_count > 1 ? 's straight' : ''}!
          </PopoverMessage>
        </Popover.Dropdown>
      </Popover>
    </>
  )
}

export default Streak
