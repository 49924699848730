import styled from '@emotion/styled'
import { motion } from 'framer-motion'

// eslint-disable-next-line import/prefer-default-export
export const SidebarWrapper = styled(motion.div)`
  position: absolute;
  z-index: 4;
  top: 70px;
  left: 0px;
  width: 250px;
  height: calc(100vh - 70px);
  display: grid;
  gap: 24px;
  align-content: start;
  border-radius: 0 20px 20px 0;
  background-color: var(--fz-course-primary);
  padding: 16px;
  overflow: auto;

  // general styles for dropdown items, can be overridden by specific dropdown items
  * {
    font-size: 1.2rem;
    font-family: 'Tilt Warp';
    color: white;
  }
`
