import FuriOpacitySlider from './FuriOpacitySlider'
import ColorizedFuriToggle from './ColorizedFuriToggle'

const KanjiOptions = () => {
  return (
    <>
      <FuriOpacitySlider />
      <div className='tw-h-4' />
      <ColorizedFuriToggle />
    </>
  )
}

export default KanjiOptions
