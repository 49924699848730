import React, { useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'src/api'
import * as R from 'ramda'
import styled from 'styled-components'

import { Button, Grid, Label, Segment } from 'semantic-ui-react'

import { LanguageRecord } from '../../../types'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'
import SoundRenderer from './SoundRenderer'

import useMobile from '../../../hooks/useMobile'

import { KanjiSegment } from './styles/KanjiWidget.styles'

type Examples = {
  english: string
  japanese: string | LanguageRecord,
  _id: string
}

interface KanjiProps {
  widget: {
    examples: Examples[]
    kanji: LanguageRecord
    kunyomi: LanguageRecord
    onyomi: LanguageRecord
    radical: LanguageRecord
    meaning: string
    number: number
    no_sound?: boolean
    plays_sound?: boolean
    sound_instances?: any
    sounds?: {
      [key: string]: string
    }[]
    tags?: string[]
    strokes?: number
    type: string
    _id: string
    structure?: [{[key: string]: string}]
  },
  course?: string
}

const ExampleContainer = styled.div`
  container-type: inline-size;
  text-align: center;
  width: 100%;
  /* height: 100%; */
  /* margin-top: 42px; */
  p {
    font-size: 10cqw !important;
    line-height: 12cqw !important;
  }
  div {
    left: -12px;
  }
`

const FontSizeColumn = styled.div`
  container-type: inline-size;
  display: flex !important;
  align-items: center !important;
  width: 100%;
  height: 100%;
  span {
    font-size: 30cqw;
  }

  @media (max-width: 1200px) {
    span {
      font-size: 30cqw;
    }
  }
`

const FontSizeColumnHeader = styled(FontSizeColumn)`
  span {
    font-size: 10cqw;
    color: var(--blue-gray);
  }

  @media (max-width: 1000px) {
    span {
      font-size: 10cqw;
    }
  }

  @media (max-width: 1200px) {
    span {
      font-size: 10cqw;
    }
  }
`

const ColorSpan = styled.span`
  color: white;
  z-ndex: 5;
  width: 100%;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 50px;

  @media (min-width: 991px) {
    max-height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media (min-width: 1200px) {
    max-height: 70px;
    padding-top: 10px;
    padding-bottom: 14px;
  }
`

const ExtendedBorderRow = styled.div`
  display: flex;
  /* border-bottom: 4px solid; */
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  width: 100% !important;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`

const BottomRow = styled.div`
  display: flex;
  width: 100%;

  min-height: 50px;

  align-items: center;
  justify-content: center;

  @media (min-width: 991px) {
    max-height: 60px;
    min-height: 60px;
    font-size: 1.75rem !important;
  }

  @media (min-width: 1200px) {
    min-height: 70px;
    max-height: 70px;
    font-size: 2rem !important;
  }
`

const StrokeOrderDiagram = ({ kanji }) => {
  const [showDiagram, setShowDiagram] = useState(false)

  const toggleDiagram = () => setShowDiagram(!showDiagram)

  return (
    <div>
      <Button onClick={toggleDiagram}>Toggle Diagram</Button>
      {showDiagram && (
        <img
          src={`https://raw.githubusercontent.com/KanjiVG/kanjivg/master/kanji/${kanji}.svg`}
          alt={`Stroke order diagram for ${kanji}`}
        />
      )}
    </div>
  )
}

const KanjiWidget: React.FC<KanjiProps> = ({ widget, course }) => {
  const [number, setNumber] = useState<number>()
  const [strokes, setStrokes] = useState<number>()
  const [meaning, setMeaning] = useState<string>()
  const [structure, setStructure] = useState([])
  const [examples, setExamples] = useState<Examples[]>()
  const [radical, setRadical] = useState<LanguageRecord>()
  const [kunyomi, setKunyomi] = useState<LanguageRecord>()
  const [onyomi, setOnyomi] = useState<LanguageRecord>()
  const [kanji, setKanji] = useState<LanguageRecord>()
  const isMobile = useMobile()
  const [cfPath, setCfPath] = useState('')
  const [imageError, setImageError] = useState(false)

  // fetch stroke order diagram
  const getCfPath = useCallback(async () => {
    if (!widget || !['kanji1', 'kanji2', 'jfz3'].includes(course)) return
    try {
      const urlRes = await axios.post('/user/signed', { url: `courses/images/kanji/stroke_images/kanji${widget.number}-strokes.png`, widget: widget?._id })

      if (urlRes?.data?.success) {
        setCfPath(urlRes?.data?.url)

        const img = new Image()
        img.src = urlRes?.data?.url
        img.onerror = () => {
          setImageError(true)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }, [widget, course])

  const filterEmpty = (toFilter) => {
    if (typeof toFilter === 'object') return toFilter?.filter((el) => el)?.flat()?.[0]
    return toFilter
  }

  const initializeWidget = useCallback(() => {
    if (!widget) return
    getCfPath()

    setNumber(widget?.number)
    setStrokes(widget?.strokes)
    setMeaning(widget?.meaning)
    setExamples(widget?.examples)
    setStructure(widget?.structure)

    const _radical = filterEmpty(widget?.radical)
    const _kanji = filterEmpty(widget?.kanji)
    const _kunyomi = filterEmpty(widget?.kunyomi)
    const _onyomi = filterEmpty(widget?.onyomi)
    // const _structure = filterEmpty(widget?.structure)

    // console.log(_radical)
    // console.log(_kanji)
    // console.log(_kunyomi)
    // console.log(_onyomi)

    setKanji(_kanji)
    setRadical(_radical)
    setKunyomi(_kunyomi)
    setOnyomi(_onyomi)
    // setStructure(_structure)
  }, [])

  useEffect(() => {
    if (!widget) return
    // console.log(widget)
    initializeWidget()
  }, [widget])

  useEffect(() => {
    console.log(structure)
  }, [structure])

  if (!widget?._id) return <PlaceholderWidget widget_type='KanjiWidget' />

  const st = Array.isArray(structure) && structure?.map((s) => s.text).join('')

  const kanjiDiagram = useMemo(() => {
    return (
      <div style={{ fontSize: '10rem', marginTop: '-1rem', marginBottom: '-1.5rem', textAlign: 'center' }}>
        {(cfPath && !imageError) ? (
          <img
            src={cfPath}
            alt={`Stroke order diagram for ${kanji}`}
            style={{ width: 'auto', height: 'auto', maxHeight: '100%', maxWidth: '100%' }}
          />
        ) :
        kanji
          && typeof kanji === 'object'
          ? kanji?.text
          : kanji
        }
      </div>
    )
  }, [kanji, cfPath, imageError])

  return (
    <WidgetWrapper widget={{ widgetId: widget?._id, widgetType: 'KanjiWidget' }}>
      <KanjiSegment
        data-t={`widget:kanji-id:${widget?._id}`}
        isMobile={isMobile}
      >
        <Grid
          className='fz-dark-font'
          style={{
            fontFamily: 'Milliard Book, Yu Gothic UI Regular, YesJapan Gothic',
            fontSize: '1.25rem',
          }}
        >
          {!isMobile ? (
            <>
              <ExtendedBorderRow
                style={{
                  zIndex: '5',
                  marginBottom: '7px',
                  backgroundColor: 'var(--fz-course-bg-light)',
                }}
              >
                <div
                  style={{
                    width: '30%',
                    padding: '0px',
                    fontFamily: 'Milliard Bold, Yu Gothic UI Regular',
                    boxShadow: '6px 0 6px -5px rgba(214, 222, 248, 1)'
                  }}
                >
                  <div
                    style={{
                      paddingTop: '0px',
                      display: 'flex',
                    }}
                  >
                    <FontSizeColumn
                      style={{ color: 'white', padding: '0', width: '33%' }}
                    >
                      <ColorSpan
                        style={{ background: 'var(--fz-course-tertiary)' }}
                      >
                        {number}
                      </ColorSpan>
                    </FontSizeColumn>
                    <FontSizeColumn style={{ fontWeight: 'bold', zIndex: '5', padding: '0', width: '34%' }}>
                      <ColorSpan
                        style={{ background: 'var(--fz-course-secondary)' }}
                      >
                        {radical
                          && typeof radical === 'object'
                          ? radical?.text
                          : radical
                        }
                      </ColorSpan>
                    </FontSizeColumn>
                    <FontSizeColumn
                      style={{
                        fontFamily: 'Milliard Book, Yu Gothic UI Regular',
                        paddingLeft: '2px',
                        paddingRight: '14px',
                        zIndex: '5',
                        padding: '0',
                        height: '100%',
                        width: '33%',
                      }}
                    >
                      <ColorSpan
                        style={{
                          color: 'var(--blue-grey)',
                        }}
                      >
                        {strokes && `${strokes} 画`}
                      </ColorSpan>
                    </FontSizeColumn>
                  </div>
                </div>
                <div style={{ padding: '0px', width: '70%' }}>
                  <div style={{ alignItems: 'center', justifyContent: 'center', padding: '0px', display: 'flex', paddingLeft: '30px' }}>
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '10%' }}
                    >
                      <Label size='large' circular style={{ color: 'white', position: 'relative', background: 'var(--fz-course-tertiary)' }}>くん</Label>
                    </div>
                    <FontSizeColumnHeader
                      style={{
                        width: '40%'
                      }}
                    >
                      <span>
                        {kunyomi
                          && typeof kunyomi === 'object'
                          ? kunyomi?.text
                          : kunyomi
                        }
                      </span>
                    </FontSizeColumnHeader>
                    <div
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '10%' }}
                    >
                      <Label size='large' circular style={{ color: 'white', position: 'relative', background: 'var(--fz-course-tertiary)' }}>おん</Label>
                    </div>
                    <FontSizeColumnHeader
                      style={{
                        width: '40%'
                      }}
                    >
                      <span>
                        {onyomi
                          && typeof onyomi === 'object'
                          ? onyomi?.text
                          : onyomi
                        }
                      </span>
                    </FontSizeColumnHeader>
                  </div>
                </div>
              </ExtendedBorderRow>

              {/* kanji second row after readings */}
              <div style={{ display: 'flex', width: '100%' }}>
                {/* kanji diagram */}
                <div style={{ width: '30%' }}>
                  {kanjiDiagram}
                </div>
                <div style={{ width: '70%' }}>
                  <Grid style={{ height: '100%', paddingLeft: '26px', marginRight: '20px', marginTop: '0px' }}>
                    {examples?.length && R.splitEvery(3)(examples).map((exs, index) => (
                      <Grid.Row key={index} style={{ height: '50%', borderBottom: index === 0 ? '2px dashed #ccc' : 'none' }}>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '6px 0 6px -5px rgba(214, 222, 248, 1)' }}>
                          {exs?.[0]
                            && <ExampleContainer>
                              <SoundRenderer text={exs[0]?.japanese} />
                              <p style={{ color: 'var(--fz-course-tertiary', fontSize: 'var(--reg-font-size)' }}>{exs[0]?.english}</p>
                            </ExampleContainer>
                          }
                        </Grid.Column>
                        <Grid.Column width={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '6px 0 6px -5px rgba(214, 222, 248, 1)' }}>
                          {exs?.[1]
                            && <ExampleContainer>
                              <SoundRenderer text={exs[1]?.japanese} />
                              <p style={{ color: 'var(--fz-course-tertiary', fontSize: 'var(--reg-font-size)' }}>{exs[1]?.english}</p>
                            </ExampleContainer>
                          }
                        </Grid.Column>
                        <Grid.Column width={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {exs?.[2]
                            && <ExampleContainer>
                              <SoundRenderer text={exs[2]?.japanese} />
                              <p style={{ color: 'var(--fz-course-tertiary', fontSize: 'var(--reg-font-size)' }}>{exs[2]?.english}</p>
                            </ExampleContainer>
                          }
                        </Grid.Column>
                      </Grid.Row>
                    ))}
                  </Grid>
                </div>
              </div>
              {st ? (
                <BottomRow
                  style={{
                    color: 'var(--blue-grey)',
                    backgroundColor: 'var(--fz-course-bg-light)',
                    marginTop: '8px',
                    borderBottom: '4px solid var(--fz-course-tertiary)',
                    textAlign: 'center',
                  }}
                >
                  <div style={{ fontWeight: 'bold', width: '50%' }}>
                    {meaning}
                  </div>
                  <div style={{ width: '50%' }}>
                    {Array.isArray(structure) && structure?.map((s) => s.text).join('')}
                  </div>
                </BottomRow>
              ) : (
                <BottomRow
                  style={{
                    color: 'var(--blue-grey)',
                    backgroundColor: 'var(--fz-course-bg-light)',
                    marginTop: '8px',
                    borderBottom: '4px solid var(--fz-course-tertiary)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ fontWeight: 'bold', width: '100%', textAlign: 'center', paddingBottom: '2px' }}>
                    {meaning}
                  </div>
                </BottomRow>
              )}
            </>
          ) : (
            <>
              <Grid.Row textAlign='center' style={{ paddingTop: '4px', paddingBottom: 0 }}>
                <Grid.Column
                  width={5}
                  style={{ backgroundColor: '#a22ebc', color: 'white' }}
                >
                  {number}
                </Grid.Column>
                <Grid.Column width={6} style={{ color: 'white', background: 'var(--fz-course-secondary)' }}>
                  {radical
                    && typeof radical === 'object'
                    ? radical?.text
                    : radical
                  }
                </Grid.Column>
                <Grid.Column
                  width={5}
                  style={{
                    fontFamily: 'Milliard Book, Yu Gothic UI Regular',
                  }}
                >
                  {strokes && `${strokes} 画`}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Grid.Column width={16} textAlign='center'>
                  <div
                    style={{
                      fontSize: '10rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div style={{ marginTop: '1rem', marginBottom: '1.5rem', width: '200px' }}>
                      {kanjiDiagram}
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingTop: 0 }}>
                <Grid.Column
                  textAlign='center'
                  width={16}
                  style={{ fontFamily: 'Milliard Bold' }}
                >
                  {meaning}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingBottom: 0 }}>
                <Grid.Column
                  textAlign='center'
                  width={6}
                  style={{ color: 'white', background: 'var(--fz-course-tertiary)' }}
                >
                  くんよみ
                </Grid.Column>
                <Grid.Column width={10}>
                  {kunyomi
                    && typeof kunyomi === 'object'
                    ? kunyomi?.text
                    : kunyomi
                  }
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingTop: 0 }}>
                <Grid.Column
                  textAlign='center'
                  width={6}
                  style={{ color: 'white', background: 'var(--fz-course-secondary)' }}
                >
                  おんよみ
                </Grid.Column>
                <Grid.Column width={10}>
                  {onyomi
                    && typeof onyomi === 'object'
                    ? onyomi?.text
                    : onyomi
                  }
                </Grid.Column>
              </Grid.Row>
              {examples?.length && R.splitEvery(2)(examples).map((exs, index) => (
                <Grid.Row key={index}>
                  <Grid.Column width={8}>
                    {exs?.[0]
                      && <>
                        <SoundRenderer text={exs[0]?.japanese} /> {exs[0]?.english}
                      </>
                    }
                  </Grid.Column>
                  <Grid.Column width={8}>
                  {exs?.[1]
                    && <>
                      <SoundRenderer text={exs[1]?.japanese} /> {exs[1]?.english}
                    </>
                  }
                  </Grid.Column>
                </Grid.Row>
              ))}
            </>
          )}
        </Grid>
        {/* <StrokeOrderDiagram kanji={kanji?.text || kanji} /> */}
      </KanjiSegment>
    </WidgetWrapper>
  )
}

export default KanjiWidget
