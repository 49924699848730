import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PubSub from 'pubsub-js'

import styled, { css } from 'styled-components'
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'
import SoundRenderer from './SoundRenderer'
import LineSegment from './globalWidgetComponents/LineSegment'

import useMobile from '../../../hooks/useMobile'
import usePlayAll from '../hooks/usePlayAll'
import useStaffRoles from 'src/hooks/useStaffRoles'
import useStudyMode from '../hooks/useStudyMode'

const blurCSS = css`
  filter: blur(${({ blur }) => blur ? 4 : 0}px);
  &:hover {
    filter: blur(0px);
  }
`

const Spacer = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: 20px;
`

const BlurSpan = styled.span`
  ${blurCSS}
`

const BlurPar = styled.p`
  ${blurCSS}
`

const BlurDiv = styled.div`
  ${blurCSS}
`

const SentencesWidget = (props) => {
  const [widget, setWidget] = useState(props.widget)
  const [loading, setLoading] = useState(!props?.widget)
  const [title, setTitle] = useState(props.widget.title)
  const [sounds, setSounds] = useState({})
  const [startNum, setStartNum] = useState(1)
  const [ordered, setOrdered] = useState(props.widget?.ordered)
  const [sentences, setSentences] = useState(props.widget.sentences)
  const [vertical, setVertical] = useState(props.widget.vertical)
  const [spaceEvery, setSpaceEvery] = useState(null)
  const [playsSound, setPlaysSound] = useState(props.widget.plays_sound)
  const [pageChangeTriggered, setPageChangeTriggered] = useState(false)
  const progressive_settings = useSelector(
    (state) => state.auth.progressive_settings
  )
  const soundsRef = useRef(sounds)
  const isMobile = useMobile()
  const pcRef = useRef(pageChangeTriggered)
  const { playAll, play, stop, current, register, next } = usePlayAll()
  const [currentPlayAll, setCurrentPlayAll] = useState(-1)
  const [entries, setEntries] = useState({})
  const { hideEnglish, hideOtherLang } = useStudyMode()
  // const entriesRef = useRef(entries)
  // const currentRef = useRef(currentPlayAll)

  useEffect(() => {
    PubSub.subscribe('PAGE CHANGE', () => {
      PubSub.publishSync('AUDIO PLAY')
      setPageChangeTriggered(true)
    })

    PubSub.subscribe('PLAY ALL', () => {
      PubSub.publishSync('AUDIO PLAY')
      if (!playAll) {
        // setPlayAll(true)
      } else {
        setPageChangeTriggered(true)
        // setPlayAll(false)
      }
    })
  }, [])

  useEffect(() => {
    pcRef.current = pageChangeTriggered
  }, [pageChangeTriggered])

  useEffect(() => {
    if (widget.startNum != undefined) {
      widget.startNum == 0 ? setStartNum(1) : setStartNum(widget.startNum)
    }

    widget
      && widget.sentences.map((s) => {
        if (s.other_lang?.[0]?.text?.length >= 25 || isMobile) setVertical(true)
      })
  }, [])

  useEffect(() => {
    soundsRef.current = sounds
  }, [sounds])

  useEffect(() => {
    if (!props?.widget) return
    setSpaceEvery(props.widget?.spaceEvery)
  }, [props.widget])

  if (loading) return <PlaceholderWidget widget_type="SentencesWidget" />

  return (
    <WidgetWrapper
      widget={{ widgetId: widget._id, widgetType: 'SentencesWidget' }}
    >
      <Segment
        className="sentences-widget"
        style={{
          margin: '10px 0px',
          padding: isMobile ? '10px 0' : '20px 45px',
          borderRadius: '',
          borderStyle: 'none',
          borderColor: isMobile ? 'transparent' : '',
          borderWidth: '',
          boxShadow: isMobile ? 'none' : '0px 0px 50px -40px #03002F',
        }}
      >
        {sentences && (
          <>
            <Grid
              style={{ color: 'var(--blue-gray)', fontSize: 'var(--reg-font-size)' }}
              stackable
              // verticalAlign="middle"
            >
              {title && (
                <Grid.Row
                  centered
                  style={{
                    borderBottom: '2px dashed #ddd',
                    marginBottom: '10px',
                  }}
                >
                  <Grid.Column
                    width={16}
                    style={{ padding: isMobile ? '10px' : '' }}
                    className="widget-title"
                  >
                    <Header
                      as="h2"
                      style={{
                        fontFamily: 'Milliard Bold',
                        color: 'var(--fz-course-primary)',
                        margin: 0,
                        padding: 0,
                        marginLeft: '0px',
                        fontSize: isMobile
                          ? 'var(--large-font-size)'
                          : 'var(--reg-font-size)',
                      }}
                    >
                      <SoundRenderer text={title} />
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              )}
              {sentences.map((s, idx /* horizontal layout */) => !vertical && !isMobile && !props.isGridWidget ? (
                  <React.Fragment key={`g-horizontal-${idx}`}>
                    <>
                      <Grid.Row
                        style={{ padding: '14px', height: '70px' }}
                        stretched
                      >
                        <Grid.Column
                          width={8}
                          floated="left"
                          style={{ lineHeight: '3rem', color: 'var(--fz-course-tertiary)', fontSize: 'var(--reg-font-size)' }}
                        >
                          {ordered ? (
                            <Grid>
                              <Grid.Row>
                                <Grid.Column width={1}>
                                  <span
                                    style={{
                                      fontFeatureSettings: 'tnum',
                                      fontVariantNumeric: 'tabular-nums',
                                    }}
                                  >
                                    {`${startNum + idx}. `}
                                  </span>
                                </Grid.Column>
                                <Grid.Column width={14}>
                                  <SoundRenderer
                                    index={idx}
                                    playingNum={current}
                                    num={idx}
                                    onSound={register}
                                    text={s.english}
                                    ping={next}
                                    placeholder={hideEnglish}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          ) : (
                            <>
                              <SoundRenderer
                                playingNum={current}
                                num={idx}
                                onSound={register}
                                text={s.english}
                                ping={next}
                                placeholder={hideEnglish}
                                index={idx}
                              />
                            </>
                          )}
                        </Grid.Column>

                        <Grid.Column width={8} floated="right">
                          <div
                            style={{
                              fontFamily: 'Milliard Book, Yu Gothic UI Regular',
                              fontSize: 'var(--reg-font-size)',
                              height: '100%',
                            }}
                          >
                            <div style={{ lineHeight: '3rem' }}>
                              <SoundRenderer
                                playingNum={current}
                                num={idx}
                                onSound={register}
                                text={s.other_lang}
                                ping={next}
                                placeholder={hideOtherLang}
                                index={idx}
                              />
                            </div>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      {s?.commentary?.content?.length > 0 && (
                        <Grid.Row
                          key={`g-horizontal-commentary-${idx}`}
                          style={{ margin: '-28px 0 -14px' }}
                        >
                          <Grid.Column
                            width={8}
                            style={{
                              fontSize: '1rem',
                              marginLeft: ordered ? '22px' : '',
                            }}
                          >
                            {s.commentary.align && (
                              <SoundRenderer
                                playingNum={current}
                                num={idx}
                                onSound={register}
                                text={s.commentary?.content}
                                ping={next}
                              />
                            )}
                          </Grid.Column>
                          <Grid.Column
                            width={8}
                            style={{
                              fontFamily: 'Milliard Book, Yu Gothic UI Regular',
                              fontSize: '1rem',
                              marginLeft: '26px',
                              color: 'var(--fz-course-tertiary)',
                            }}
                          >
                            {!s.commentary.align && (
                              <SoundRenderer
                                playingNum={current}
                                num={idx}
                                onSound={register}
                                text={s.commentary?.content}
                                ping={next}
                              />
                            )}
                          </Grid.Column>
                        </Grid.Row>
                      )}
                    </>
                    {!!s?.pairs?.length
                      && s.pairs.map((pair, pidx) => (
                        <Grid.Row key={`g-horizontal-${idx}-${pidx}`} stretched>
                          <Grid.Column
                            width={8}
                            floated="left"
                            style={{ marginLeft: ordered ? '22px' : '', color: 'var(--fz-course-tertiary)', fontSize: 'var(--reg-font-size)' }}
                          >
                            <p>
                              <SoundRenderer
                                playingNum={current}
                                num={pidx}
                                onSound={register}
                                text={pair.english}
                                ping={next}
                              />
                            </p>
                          </Grid.Column>

                          <Grid.Column width={8} floated="right">
                            <div
                              style={{
                                fontFamily:
                                  'Milliard Book, Yu Gothic UI Regular',
                                fontSize: 'var(--reg-font-size)',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div style={{ lineHeight: '3rem' }}>
                                <SoundRenderer
                                  playingNum={current}
                                  num={pidx}
                                  onSound={register}
                                  text={pair.other_lang}
                                  ping={next}
                                />
                              </div>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      ))}
                    {spaceEvery > 0
                      && (idx + 1) % spaceEvery === 0
                      && idx !== sentences.length - 1 && (
                        <Spacer key={`spacer-${idx}`} />
                    )}
                  </React.Fragment>
              ) : (
                  // vertical layout
                  <React.Fragment>
                    <>
                      <Grid.Row
                        key={`g-vertical-${idx}`}
                        style={{
                          paddingTop: '0px',
                          marginBottom: s.commentary
                            ? '0'
                            : idx === sentences.length - 1
                              ? '20px'
                              : '',
                        }}
                      >
                        <Grid.Column>
                          <LineSegment
                            key={idx}
                            line_num={idx}
                            index={idx}
                            indexing={'numeric'}
                            showEnglish={true}
                            other_lang={s.other_lang}
                            english={s.english}
                            displayOrder={true} // force J→E
                            shouldOrder={ordered}
                            displayIn="group"
                            widget={widget._id}
                            plays_sound={playsSound}
                            conversation_length={s.other_lang?.length}
                            click={null}
                            current={current}
                            next={next}
                            register={register}
                            commentary={s?.commentary}
                            hideEnglish={hideEnglish}
                            hideOtherLang={hideOtherLang}
                            widgetType='sentences'
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </>
                    {!!s?.pairs?.length && (
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'left',
                          marginTop: '-10px',
                        }}
                      >
                        {s.pairs.map((pair, pidx) => (
                          <Grid.Row
                            key={`g-vertical-pair-${idx}-${pidx}`}
                            style={{
                              padding: '0 0 0 23px',
                              margin: '0',
                            }}
                          >
                            <Grid.Column width={16}>
                              <div style={{ color: 'var(--blue-gray)', fontSize: 'var(--reg-font-size)' }}>
                                <div
                                  style={{
                                    marginTop:
                                      progressive_settings?.main_default
                                      === 'romaji'
                                        ? '-15px'
                                        : '0px',
                                  }}
                                >
                                  <SoundRenderer
                                    playingNum={current}
                                    num={idx}
                                    onSound={register}
                                    text={pair.other_lang}
                                    ping={next}
                                  />
                                </div>
                                <div
                                  style={{
                                    fontSize: 'var(--reg-font-size)',
                                    fontFamily:
                                      'Milliard Book, Yu Gothic UI Regular',
                                    marginTop: '-10px',
                                    color: 'var(--blue-gray)',
                                  }}
                                >
                                  <p>
                                    <SoundRenderer
                                      playingNum={current}
                                      num={idx}
                                      onSound={register}
                                      text={pair.english}
                                      ping={next}
                                    />
                                  </p>
                                </div>
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        ))}
                      </div>
                    )}
                    {spaceEvery > 0
                      && (idx + 1) % spaceEvery === 0
                      && idx !== sentences.length - 1 && (
                        <Spacer key={`spacer-${idx}`} />
                    )}
                  </React.Fragment>
              ))}
              {playAll && sentences.length > 1 && (
                <Grid.Row style={{ paddingTop: '0px' }}>
                  <Grid.Column width={8} style={{ padding: '0px' }}>
                    {(vertical || isMobile) && (
                      <div
                        style={{
                          marginTop: isMobile ? '-16px' : '20px',
                          marginLeft: isMobile
                            ?
                              ordered
                                ? '42px'
                                : '25px'
                              :
                              ordered
                                ? '62px'
                                : '40px'
                        }}
                      >
                        <Button
                          className={
                            current === -1 ? 'fz-play-all' : 'fz-stop-all'
                          }
                          onClick={() => play(props.widget?._id)}
                        >
                          <Icon name="play" />
                          {current === -1 ? 'Play All' : 'Stop All'}
                        </Button>
                      </div>
                    )}
                  </Grid.Column>
                  <Grid.Column width={8}>
                    {!vertical && !isMobile && (
                      <div
                        style={{
                          marginTop: isMobile ? '-36px' : '20px',
                          marginLeft: isMobile ? '32px' : '0',
                        }}
                      >
                        <Button
                          className={
                            current === -1 ? 'fz-play-all' : 'fz-stop-all'
                          }
                          onClick={() => play(props.widget?._id)}
                        >
                          <Icon name="play" />
                          {current === -1 ? 'Play All' : 'Stop All'}
                        </Button>
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </>
        )}
      </Segment>
    </WidgetWrapper>
  )
}

export default SentencesWidget
