import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import { ADMIN_ROLE, DEV_ROLE, TEACHER_ROLE } from 'src/util/constants'
import { AdminRolesResponse } from 'src/types'
import { REDUX_NAV_ACTIONS } from 'src/actions/types'
import { LockClosedSolid, LockOpenSolid } from '@graywolfai/react-heroicons'
import useStaffRoles from 'src/hooks/useStaffRoles'
import useMobile from 'src/hooks/useMobile'
import useAPI from 'src/hooks/useAPI'
import useToolkitStore from 'src/stores/toolkit.store'

const AdminViewButton = () => {
  /* eslint-disable no-multi-spaces */
  const IAM: string         = useSelector((state: RootState) => state.auth?._id)
  const admin_view: boolean = useSelector((state: RootState) => state.navigator?.admin_view)
  const alteredWidgetIds    = useToolkitStore((state) => state.alteredWidgetIds)
  const resetToolkit        = useToolkitStore((state) => state.reset)
  /* eslint-enable */

  const dispatchAdminView = useDispatch()
  const isMobile = useMobile(1300)
  const [isStaffRole] = useStaffRoles()
  const [limitedToRoles] = useStaffRoles(ADMIN_ROLE, DEV_ROLE, TEACHER_ROLE)
  const [adminRoles, loadingRoles, fetchRoles] = useAPI<AdminRolesResponse>('/admin/current', 'LAZYGET')

  const checkUserRoles = (): boolean => (adminRoles?.success
    ? !!adminRoles.admin.find((a) => a._id === IAM)
    : false
  )

  const handleEditMode = () => {
    if (!admin_view) {
      const canViewAdmin = checkUserRoles()
      if (!canViewAdmin) return
    }
    
    if (alteredWidgetIds.length) {
      const confirmMessage = 'You have unsaved changes. Are you sure you want to leave Admin View?'
      if (!window.confirm(confirmMessage)) return
      resetToolkit()
    }

    dispatchAdminView({
      type: REDUX_NAV_ACTIONS.SET_ADMIN_VIEW,
      payload: { admin_view: !admin_view }
    })
  }

  useEffect(() => {
    if (!isStaffRole) return
    fetchRoles()
  }, [location.pathname])

  useEffect(() => {
    checkUserRoles()
  }, [adminRoles, loadingRoles])

  useEffect(() => {
    if (!isStaffRole && admin_view) {
      dispatchAdminView({
        type: REDUX_NAV_ACTIONS.SET_ADMIN_VIEW,
        payload: { admin_view: false }
      })
    }
  }, [isStaffRole])

  const showAdminViewButton = useMemo(() => {
    if (!isStaffRole) return false
    if (isMobile) return false
    const editable_routes = ['ask', 'courses', 'vault']
    const root = location.pathname.split('/').filter((p) => p)[0]
    if (isStaffRole && editable_routes.includes(root)) return true
  }, [location.pathname, isMobile, isStaffRole])
  
  if (!limitedToRoles || !showAdminViewButton) return <></>

  return (
    <button
      onClick={handleEditMode}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        borderRadius: '20px',
        background: 'transparent',
        border: '1px solid black'
      }}>
      {admin_view ? (
          <>
            <span className="icon">
              <LockOpenSolid height='25px' className='tw-inline tw-fill-blue' fill='blue' />
            </span>
            <span className="view">View: Admin</span>
          </>
      ) : (
        <>
          <span className="icon">
            <LockClosedSolid height='25px' className='tw-inline tw-fill-blue' fill='blue' />
          </span>
          <span className="view">View: User</span>
        </>
      )}
    </button>
  )
}

export default AdminViewButton
