import styled from 'styled-components'

export default styled.div`
  * {
    color: var(--off-white);
  }

  .menu-items {
  }

  .menu-item {
    border-radius: 8px;
    padding: 6px 0;
    margin: 3px 0;
    cursor: pointer;
    font-family: Figtree;
    font-weight: 500;

    p { padding: 0 !important; }

    :hover {
      color: var(--fz-course-tertiary);
    }
  }

  .active {
    color: var(--fz-course-tertiary);

    :hover {
      background-color: transparent;
    }
  }
`
