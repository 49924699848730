import useNavigationContext from 'src/hooks/context/useNavigationContext'
import ClickableOverlay from './ClickableOverlay'
import LeftNav from './left_nav/LeftNav'
import CenterNav from './center_nav/CenterNav'
import RightNav from './right_nav/RightNav'
import SidebarMenu from './sidebar_menu/SidebarMenu'
import DropdownMenu from './dropdown_menu/DropdownMenu'
import WidgetPalette from 'src/components/fz_courses/admin/creation_toolkit/parts/WidgetPalette'
import useStaffRoles from 'src/hooks/useStaffRoles'
import NavigationWrapper from '../styles/NavigationStyles'

import 'src/assets/main.css'

const Navigation = () => {
  const { isMobileCourseNav, courseInfo: { isCourseLesson } } = useNavigationContext()
  const [isStaffRole] = useStaffRoles()

  return (
    <NavigationWrapper white={!isCourseLesson} isMobileCourseNav={isMobileCourseNav}>
      <LeftNav />
      <CenterNav />
      <RightNav />
      <DropdownMenu />
      <SidebarMenu />
      {isStaffRole && <WidgetPalette /> /* why is this here? */}
    </NavigationWrapper>
  )
}

export default Navigation
