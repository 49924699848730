import styled from 'styled-components'
import { Slider } from '@mantine/core'

export const SliderMain = styled.div`
  display: grid;
  gap: 8px;

  p { font-weight: lighter; }
`

export const FuriSlider = styled(Slider)<{ furiOpacity: number }>`
  .mantine-Slider-track::before {
    background-color: var(--fz-course-tertiary);
  }

  .mantine-Slider-bar {
    background-color: white;
    width: ${({ furiOpacity }) => `calc(${furiOpacity}% + 2*0.5rem)`}; // fixing mantines calc that's based on the full size of the thumb while clicked vs smaller size
  }

  .mantine-Slider-thumb {
    border: none;
  }
`

export const SliderExplanation = styled.aside`
  font-family: 'Figtree' !important;
  font-size: 0.9rem !important;
  font-weight: 500;
  color: var(--fz-course-tertiary) !important;
`
