import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'src/configureStore'

import {
  recordSplit,
  generateTokens,
  createFuriData,
  cleanProgressiveTokens,
  progressivePostProcess,
  removeSpaces
} from './sound_icon_util'

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// given a string of text that is a concatenated series of single-character tokens mapping to various
// rich-text styles (like "b" for bold), create a styles object we can later pass to our ruby tags in order
// to apply those styles to them. also takes a color variable in case "c" was passed, which will be used
// for font color, as well as a highlight color for background color
export const createFormatStyles = (styleStr, color, highlightColor) => {
  if (!styleStr) return {}
  const styles = {}
  if (styleStr.includes('b')) styles['fontWeight'] = 'bold'
  if (styleStr.includes('i')) styles['fontStyle'] = 'italic'
  if (styleStr.includes('u')) styles['textDecoration'] = 'underline'
  if (styleStr.includes('c')) styles['color'] = color
  if (styleStr.includes('h')) {
    styles['backgroundColor'] = highlightColor
    styles['opacity'] = '1'
  }
  return styles
}

const ProgressiveTextSpan = ({
  record,
  progressive_data,
  bonusData,
  styles,
  custom_enabled,
  custom_settings,
  isMobile,
  furi_opacity,
  resizeAfter,
  widget,
  formatText
}) => {
  const calligraphyMode = useSelector((state: RootState) => state.auth?.calligraphy_mode)

  useEffect(() => {
    // console.log('calligraphyMode', calligraphyMode)
  }, [calligraphyMode])

  if (record.type === 'KoreanRecord') {
    return (
      <span className="text-2xl font-bold">
        {record.base_text}
      </span>
    )
  }

  // the lexical data used to determine whether spaces should be added
  const { breakdown } = record

  // data used to calculate the base/furi pairs in newFuriData
  const { furis, splitRecord } = recordSplit(record)

  // an array of text/furi pairs (only kanji will have furi)
  const newFuriData = createFuriData(splitRecord, furis)
  const progMap = progressive_data

  let progressiveTokens
  try {
    progressiveTokens = generateTokens(breakdown, newFuriData, progMap, bonusData, null, record, formatText)
    // if (record.base_text === 'そうです') console.log(progressiveTokens)
  } catch (e) {
    console.log(e)
    return <span>{record.base_text}</span>
  }

  const fullStringLength = newFuriData.reduce((p, c) => p + c[0].length, 0)

  const newStyles = { ...styles }
  const parentStyle: {
    fontSize?: string
  } = {}

  if (parentStyle && newStyles && !styles?.fontSize && resizeAfter && fullStringLength >= resizeAfter) {
    const newSize = (isMobile ? 0.82 : 1.1) - (fullStringLength - resizeAfter)
    * (isMobile ? 0.04 : 0.03)
    parentStyle.fontSize = `${newSize}rem`
    newStyles.fontSize = `${newSize}rem`
  }

  if (calligraphyMode) newStyles.fontFamily = 'Calligraphy'

  const containsPeriod = newFuriData.some(([kanji, furi]) => {
    return (kanji !== undefined && kanji.indexOf('。') !== -1) || (furi !== undefined && furi.indexOf('。') !== -1)
  })

  const spacesRemovedTokens = progressivePostProcess(removeSpaces(cleanProgressiveTokens(progressiveTokens, bonusData)), bonusData)

  // V2 progressive system render
  return (
    <>
      {record && spacesRemovedTokens?.map(({ text, capitalized, pre, post, bd, formatted, force_lower }, index) => (
        // eslint-disable-next-line no-nested-ternary
        <>
          {pre && index > 0 && !spacesRemovedTokens[index - 1]?.post && <span style={{ width: '10px' }}>&nbsp;&nbsp;</span>}
          {(formatted?.length ? formatted : text)?.map((token, idx) => {
            let t, furigana
            const textToken = formatted?.length ? token?.text : token

            const formatStyles = formatted?.length ? createFormatStyles(token?.format, token?.color, token?.highlightColor) : {}
            if (Array.isArray(textToken)) {
              [t, furigana] = textToken
            } else {
              t = ((capitalized && idx === 0) || (index === 0 && idx === 0 && containsPeriod && bd.basic_form !== 'いいえ' && !force_lower)) ? capitalize(textToken) : textToken
            }
            return furigana ? (
              <ruby key={`${index}-${furigana}`} style={{ ...newStyles, ...formatStyles, display: `${furigana ? '' : 'inline-flex'}` }}>
                {t || ' '}
                {furigana && <rt style={{ textAlign: 'center' }} className={furi_opacity < 10 ? 'furi-on-hover' : ''}>{furigana}</rt>}
              </ruby>
            ) : (
              <ruby key={`${text}-${t}-${idx}-${index}-${furigana}-${widget}-r`} style={{ ...newStyles, ...formatStyles, display: `${furigana ? '' : 'inline-flex'}` }}>
                <span key={`${text}-${t}-${idx}-${index}-${furigana}-${widget}-text`}>{t === ' ' ? <>&nbsp;</> : t}</span>
              </ruby>
            )
          })}
          {post && text[0] === ',' && <span>&nbsp;&nbsp;</span>}
          {post && text[0] !== ',' && <span style={{ width: '10px' }}>&nbsp;&nbsp;</span>}
        </>
      ))}
    </>
  )
}

export default ProgressiveTextSpan
