import React, { useState } from 'react'

import { ColumnSwapper as SwapperRow, StylesPopup } from '../../styles'
import { IconButton, ToolInput } from '../../parts'
import { TableAxisStyles, FZTable } from 'src/types'

interface TableHeaderProps {
  columnStyles: TableAxisStyles[]
  headerRow: string[]
  showStyleButtons: boolean
  handleShiftColumns: () => void
  handleAddColumn: () => void
  handleColumnStyles: (e: any, arg: number) => void
  storedTable: FZTable
}

const TableHeader: React.FC<TableHeaderProps> = ({
  columnStyles,
  headerRow,
  showStyleButtons,
  handleShiftColumns,
  handleAddColumn,
  handleColumnStyles,
  storedTable
}) => {
  const [showStyleOptions, setShowStyleOptions] = useState(null)

  return (
    <SwapperRow>
      <IconButton
        icon={headerRow.length > 2 ? 'arrow left' : 'exchange'}
        color='var(--blue-gray)'
        contrastColor='var(--off-white)'
        handleClick={() => handleShiftColumns()}
        title='Shift Columns Left'
      />
      {headerRow.map((_, idx: number) => (
        <div key={idx} className='flex-center tw-flex-col' style={{ position: 'relative' }}>
          {showStyleButtons && (
            <IconButton
              icon='css3'
              handleClick={() => setShowStyleOptions((p) => p === idx ? null : idx)}
              color='var(--blue-gray)'
              contrastColor='var(--off-white)'
              title={`Column ${idx + 1} Styles`}
              active={showStyleOptions === idx}
              style={{ margin: '-10px 0 4px' }}
            />
          )}
          {showStyleButtons && showStyleOptions === idx && (
            <StylesPopup table>
              <div style={{ gridColumn: '1 / -1', placeSelf: 'center', padding: '0 0 8px' }}>--- Styles ---</div>
              <label htmlFor='font-size-select'><strong>Font Size: </strong></label>
              <select name='fontSize' id='font-size-select' value={storedTable.columnStyles[idx]?.fontSize ?? null} onChange={(e) => handleColumnStyles(e, idx)}>
                <option value={null}>Default</option>
                <option value='0.75rem'>xs</option>
                <option value='0.875rem'>sm</option>
                <option value='1rem'>md</option>
                <option value='1.125rem'>lg</option>
                <option value='1.25rem'>xl</option>
                <option value='var(--reg-font-size)'>2xl (reg font size)</option>
                <option value='1.875rem'>3xl</option>
                <option value='2.25rem'>4xl</option>
                <option value='3rem'>5xl</option>
                <option value='3.75rem'>6xl</option>
                <option value='4.5rem'>7xl</option>
                <option value='6rem'>8xl</option>
                <option value='8rem'>9xl</option>
              </select>
              <label htmlFor='font-weight-select'><strong>Font Weight: </strong></label>
              <select name='fontWeight' id='font-weight-select' value={storedTable.columnStyles[idx]?.fontWeight ?? 'normal'} onChange={(e) => handleColumnStyles(e, idx)}>
                <option value='normal'>Normal</option>
                <option value='bold'>Bold</option>
              </select>
              <label htmlFor='font-size-select'><strong>Text Align: </strong></label>
              <select name='textAlign' id='text-align-select' value={storedTable.columnStyles[idx]?.textAlign ?? 'left'} onChange={(e) => handleColumnStyles(e, idx)}>
                <option value='left'>Left</option>
                <option value='center'>Center</option>
                <option value='right'>Right</option>
              </select>
              <label htmlFor='bg-color-select'><strong>Background Color: </strong></label>
              <select name='backgroundColor' id='bg-color-select' value={storedTable.columnStyles[idx]?.backgroundColor ?? ''} onChange={(e) => handleColumnStyles(e, idx)}>
                <option value={''}>Default</option>
                <option value='courseColor'>Course Color</option>
              </select>
              <label htmlFor='width-select'><strong>Column Width: </strong></label>
              <select name='columnSize' id='width-select' value={storedTable.columnStyles[idx]?.columnSize ?? '1fr'} onChange={(e) => handleColumnStyles(e, idx)}>
                <option value='1fr'>Evenly Space</option>
                <option value='auto'>Shrink-to-fit</option>
              </select>
              <div style={{ gridColumn: '1 / -1', placeSelf: 'center', padding: '8px 0' }}>--- Options ---</div>
              <label htmlFor='study-mode-override'><strong>Block Study Mode</strong></label>
              <select name='studyModeOverride' id='study-mode-override' value={storedTable.columnStyles[idx]?.studyModeOverride ?? 'false'} onChange={(e) => handleColumnStyles(e, idx)}>
                <option value='false'>False</option>
                <option value='true'>True</option>
              </select>
            </StylesPopup>
          )}
          <ToolInput
            value={`table.header.${idx}`}
            placeholder={`Header ${idx + 1}`}
          />
        </div>
      ))}
      <IconButton
        icon='plus'
        color='var(--blue-gray)'
        contrastColor='var(--off-white)'
        handleClick={() => handleAddColumn()}
        title='Add Column'
      />
    </SwapperRow>
  )
}

export default TableHeader
