import React from 'react'
import { useSelector } from 'react-redux'
import StudyModeOptions from '../Tools/StudyModeOptions'
import ProgSettingsToggler from '../Tools/ProgSettingsToggler'
import KanjiOptions from './KanjiOptions'
import CourseBackgroundSettings from '../Tools/CourseBackgroundSettings'
import CalligraphyModeToggle from '../Tools/CalligraphyModeToggle'
import V8Toggle from '../Tools/V8Toggle'
import { HorizontalDivider } from 'src/components/navigation/styles/DropdownStyles'
import { motion, AnimatePresence } from 'framer-motion'
import { RootState } from 'src/configureStore'

const tools = [
  StudyModeOptions,
  ProgSettingsToggler,
  KanjiOptions,
  CalligraphyModeToggle,
  // V8Toggle,
  CourseBackgroundSettings,
]

const Tools = () => {
  const progSettings = useSelector((state: RootState) => state.auth.progressive_settings)
  const showKanjiOptions = progSettings?.main_default === 'kanji' || progSettings?.main_default === 'progressive'

  return (
    <div className='tw-mb-3'>
      {tools.map((Tool, i) => {
        const showTool = !(i === 2 && !showKanjiOptions) // don't show kanji options if not in kanji/progressive mode
        const showDivider = i < tools.length - 1

        return (
          <div key={i}>
            <AnimatePresence initial={false}>
              {showTool && (
                <motion.section
                  key={`tool-${i}`}
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                >
                  {/** spacer; for avoiding glitches with framer motion */}
                  {i !== 0 && <div className='tw-h-4' />}
                  <Tool />
                  {showDivider && (
                    <>
                      {/** spacer; for avoiding glitches with framer motion */}
                      <div className='tw-h-4' />
                      <HorizontalDivider />
                    </>
                  )}
                </motion.section>
              )}
            </AnimatePresence>
          </div>
        )
      })}
    </div>
  )
}

export default Tools
