import React, { useEffect, useRef, useState } from 'react'
import PubSub from 'pubsub-js'

import styled, { css } from 'styled-components'
import { Icon, Segment } from 'semantic-ui-react'
import { DashLine } from './styles/QnAStyles'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'
import SoundRenderer from './SoundRenderer'

import usePlayAll from '../hooks/usePlayAll'
import useMobile from '../../../hooks/useMobile'
import useStudyMode from '../hooks/useStudyMode'

const horizontalGrid = ({ isOrdered }) => css`
  grid-template-columns: ${isOrdered ? 'auto 1fr 1fr' : '1fr 1fr'};
`

const verticalGrid = ({ isOrdered }) => css`
  grid-template-columns: ${isOrdered ? 'auto 1fr' : '1fr'};
`

const ExamplesGrid = styled.div`
  font-family: 'Milliard Book', 'Yu Gothic UI Regular';
  font-size: var(--reg-font-size);
  color: var(--blue-grey);
  display: grid;
  grid-gap: ${({ isMobile }) => isMobile ? '1rem 0.5rem' : '2rem 0.5rem'};
  align-items: baseline;
  ${({ isVerticalGrid }) => isVerticalGrid ? verticalGrid : horizontalGrid}
`

const ExamplesHeader = styled.h2`
  font-family: 'Milliard Bold';
  font-size: var(--reg-font-size);
  color: var(--fz-course-primary);
  margin: ${({ isMobile }) => isMobile ? '0' : '0 0 -1rem 0'} !important;
  padding: 0;
  grid-column: 1 / -1;
`

const WidgetDashedLine = styled(DashLine)`
  grid-column: 1 / -1;
  margin-bottom: ${({ isMobile }) => isMobile && '1rem'};
`

const RenderedSound = styled.div`
  grid-column: ${({ isVerticalGrid, isOrdered }) => isVerticalGrid && isOrdered && 2};
  margin: ${({ isVerticalGrid, english }) => isVerticalGrid && english && '-1rem 0 1rem 25px'};
`

const Spacer = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 2px dashed #ddd;
  width: 100%;
`

const GridSpacer = styled(Spacer)`
  grid-column: 1 / -1;
  margin-top: ${({ isMobile }) => isMobile && '-10px'};
`

const ExamplesPlayAll = styled.button`
  font-family: Lato, 'Helvetica Neue', Arial,Helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
  margin: 1rem 0;
  padding: .5rem;
  border-radius: 4px;
  max-width: 100px;
  grid-column: ${({ isVerticalGrid, isOrdered, swapColumnDisplay }) => isVerticalGrid && isOrdered && 2};
  grid-column: ${({ isVerticalGrid, isOrdered, swapColumnDisplay }) => !isVerticalGrid && isOrdered && swapColumnDisplay && 3};
  grid-column: ${({ isVerticalGrid, isOrdered, swapColumnDisplay }) => !isVerticalGrid && isOrdered && !swapColumnDisplay && 2};
  grid-column: ${({ isVerticalGrid, isOrdered, swapColumnDisplay }) => !isVerticalGrid && !isOrdered && swapColumnDisplay && 2};
  margin-left: ${({ isVerticalGrid }) => isVerticalGrid && '25px'};
`

const ExamplesWidget = (props) => {
  // const [widget, setWidget] = useState(props.widget)
  const [loading, setLoading] = useState(!props.widget)
  const [startNum, setStartNum] = useState(1)
  const [examples, setExamples] = useState(props.widget.examples || [])
  const [title, setTitle] = useState(props.widget.title || 'Examples')
  const [vertical, setVertical] = useState(props.widget.vertical)
  const [isOrdered, setIsOrdered] = useState(props.widget.ordered)
  const [swapColumnDisplay, setSwapColumnDisplay] = useState(
    props.widget?.swapColumnDisplay
  ) // false = other first
  const [spaceEvery, setSpaceEvery] = useState(null)
  const [playsSound, setPlaysSound] = useState(props.widget?.plays_sound)
  const [pageChangeTriggered, setPageChangeTriggered] = useState(false)
  // const progressive_settings = useSelector(state => state.auth.progressive_settings)
  const isMobile = useMobile()
  const pcRef = useRef(pageChangeTriggered)
  const { playAll, play, stop, current, register, next } = usePlayAll()
  const { hideEnglish, hideOtherLang } = useStudyMode()
  const isVerticalGrid = isMobile || vertical

  useEffect(() => {
    PubSub.subscribe('PAGE CHANGE', () => {
      PubSub.publishSync('AUDIO PLAY')
      setPageChangeTriggered(true)
    })

    PubSub.subscribe('PLAY ALL', () => {
      PubSub.publishSync('AUDIO PLAY')
      if (!playAll) {
        // setPlayAll(true)
      } else {
        setPageChangeTriggered(true)
        // setPlayAll(false)
      }
    })
  }, [])

  useEffect(() => {
    pcRef.current = pageChangeTriggered
  }, [pageChangeTriggered])

  useEffect(() => {
    setLoading(!props.widget)

    // console.log(props.widget)
    
    if (parseInt(props?.widget?.startNum, 10) === 0) {
      setStartNum(1)
    } else {
      setStartNum(props?.widget?.startNum)
    }

    examples?.forEach((s) => {
      if (s.other_lang?.[0]?.text?.length >= 25) setVertical(true)
    })
  }, [])

  useEffect(() => {
    if (!props?.widget) return
    setSpaceEvery(props.widget?.spaceEvery)
  }, [props.widget])

  if (loading) return <PlaceholderWidget widget_type="ExamplesWidget" />

  return (
    <WidgetWrapper
      widget={{ widgetType: 'ExamplesWidget', widgetId: props.widget._id }}
    >
      <Segment
        style={{
          padding: isMobile ? '14px' : '2rem 45px',
          borderRadius: '',
          borderStyle: 'none',
          borderColor: isMobile ? 'transparent' : '',
          borderWidth: '',
          boxShadow: isMobile ? 'none' : '0px 0px 50px -40px #03002F',
        }}
      >
        {examples && (
          <ExamplesGrid isVerticalGrid={isVerticalGrid} isOrdered={isOrdered} isMobile={isMobile}>
            <ExamplesHeader isMobile={isMobile}>
              <SoundRenderer text={title} bold />
            </ExamplesHeader>
            <WidgetDashedLine color={'#ddd'} isMobile={isMobile} />
            {examples.map((ex, i) => (
              <React.Fragment key={`examples-${i}`}>
                {isOrdered && <span>{startNum + i}.</span>}
                {swapColumnDisplay && !isVerticalGrid && ( // English First
                  <RenderedSound isVerticalGrid={isVerticalGrid} isOrdered={isOrdered} english>
                    <SoundRenderer
                      num={examples[i].pairs.length ? i * 2 : i}
                      playingNum={current}
                      onSound={register}
                      text={ex.english}
                      ping={next}
                      placeholder={hideEnglish}
                      index={examples[i].pairs.length ? i * 2 : i}
                    />
                  </RenderedSound>
                )}
                <RenderedSound isVerticalGrid={isVerticalGrid} isOrdered={isOrdered}>
                  <SoundRenderer
                    num={examples[i].pairs.length ? i * 2 : i}
                    playingNum={current}
                    onSound={register}
                    text={ex.other_lang}
                    ping={next}
                    placeholder={hideOtherLang}
                    index={examples[i].pairs.length ? i * 2 : i}
                  />
                </RenderedSound>
                {(!swapColumnDisplay || isVerticalGrid) && ( // English Second
                  <RenderedSound isVerticalGrid={isVerticalGrid} isOrdered={isOrdered} english>
                    <SoundRenderer
                      num={examples[i].pairs.length ? i * 2 : i}
                      playingNum={current}
                      onSound={register}
                      text={ex.english}
                      ping={next}
                      placeholder={hideEnglish}
                      index={examples[i].pairs.length ? i * 2 : i}
                    />
                  </RenderedSound>
                )}
                {examples[i].pairs.length ? (
                  examples[i].pairs.map((pair, ip) => (
                    <React.Fragment key={`examplesPair-${i}-${ip}`}>
                      {isOrdered && <div></div>}
                      {swapColumnDisplay && !isVerticalGrid && ( // English First
                        <RenderedSound
                          isVerticalGrid={isVerticalGrid}
                          isOrdered={isOrdered}
                          english
                        >
                          <SoundRenderer
                            num={i * 2 + 1}
                            playingNum={current}
                            onSound={register}
                            text={pair.english}
                            ping={next}
                            placeholder={hideEnglish}
                            index={i * 2 + 1}
                          />
                        </RenderedSound>
                      )}
                      <RenderedSound isVerticalGrid={isVerticalGrid} isOrdered={isOrdered}>
                        <SoundRenderer
                          num={i * 2 + 1}
                          playingNum={current}
                          onSound={register}
                          text={pair.other_lang}
                          ping={next}
                          placeholder={hideOtherLang}
                          index={i * 2 + 1}
                        />
                      </RenderedSound>
                      {(!swapColumnDisplay || isVerticalGrid) && ( // English Second
                        <RenderedSound
                          isVerticalGrid={isVerticalGrid}
                          isOrdered={isOrdered}
                          english
                        >
                          <SoundRenderer
                            num={i * 2 + 1}
                            playingNum={current}
                            onSound={register}
                            text={pair.english}
                            ping={next}
                            placeholder={hideEnglish}
                            index={i * 2 + 1}
                          />
                        </RenderedSound>
                      )}
                    </React.Fragment>
                  ))
                )
                  : <></>
                }
                {spaceEvery > 0
                    && (i + 1) % spaceEvery === 0
                    && i !== examples.length - 1 && <GridSpacer key={`spacer-${i}`} isMobile={isMobile} />
                }
              </React.Fragment>
            ))}
            {playAll && examples.length > 1 && (
              <ExamplesPlayAll
                className={current === -1 ? 'fz-play-all' : 'fz-stop-all'}
                onClick={() => play(props.widget._id)}
                isVerticalGrid={isVerticalGrid}
                isOrdered={isOrdered}
                swapColumnDisplay={swapColumnDisplay}
              >
                <Icon name="play" />
                {current === -1 ? 'Play All' : 'Stop All'}
              </ExamplesPlayAll>
            )}
          </ExamplesGrid>
        )}
      </Segment>
    </WidgetWrapper>
  )
}

export default ExamplesWidget
