import { useGetCourseQuizProgress } from 'src/hooks/queries/progress'
import { CourseIdentifier } from './useCourseInfo'

// work in progress - kinda a simple hack port half copy pasta from old homeroom
const useQuizProgress = (courseIdentifier: CourseIdentifier) => {
  const { data: quizProgress } = useGetCourseQuizProgress({ courseIdentifier }) // based on bookmark only
  const { lessons } = quizProgress || { lessons: [] }

  const currentQuizProgress = {
    lessonsCompleted: lessons?.filter((l) => l?.status === 'pass').length,
    totalLessons: lessons?.length,
    // completed: isComplete === _lessons?.length,
    // lessonsRemaining: (_lessons?.length || 0) - isComplete,
  }

  return {
    currentQuizProgress,
  }
}

export default useQuizProgress
