import React from 'react'

import { LanguageRecord } from 'src/types'

import { Icon } from 'semantic-ui-react'

import { FZButton } from 'src/assets/styles/globalStyles'
import ErrorReportModal from 'src/components/modals/ErrorReportModal'

interface ISoundBugReport {
  record: LanguageRecord
}

const SoundBugReport: React.FC<ISoundBugReport> = ({ record }) => {
  return (
      <ErrorReportModal
        soundPopup
        record={record}
        trigger={
          <FZButton
            tabIndex={0}
            className='tw-mt-5 tw-ml-auto'
            hoverBackgroundColor='var(--jfz-blue)'
            hoverBorderColor='var(--jfz-blue)'
            title='Report an issue with this sound or associated text'
            style={{ marginTop: '30px', margin: 'auto' }}
          >
            <Icon name='bug' style={{ position: 'relative', top: '-4px' }} />
            Report an issue
          </FZButton>
        }
      />
  )
}

export default SoundBugReport
