import styled from '@emotion/styled'

export const GridWidgetRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 2px;
  overflow: hidden;
  margin-bottom: 16px;

  @media (max-width: 1000px) { grid-auto-flow: row; }
`

export default GridWidgetRow
