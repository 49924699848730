import axios from 'src/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { SetBookmarkParams, PutStreakNotifiedOptions } from 'src/types/mutations/user.mutations.types'

// hope we can start factoring out redux stuff at some point and also get rid of any dispatches
export const usePostLogoutUser = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const history = useHistory()

  return useMutation({
    mutationFn: () => axios.post('/user/logout'),
    onSuccess: () => {
      queryClient.invalidateQueries() // invalidates all queries - makes sense I think?
      dispatch({ type: 'SIGN_OUT' })
      history.push('/')
    }
  })
}

export const useSetBookmark = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (updates : SetBookmarkParams) => axios.post('/user/bookmark', updates),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/user/bookmark'] })
    }
  })
}

export const usePutStreakNotified = (id: string) => {
  const queryClient = useQueryClient()

  // mutation to update user streak to show it was seen/modal acknowledged
  return useMutation({
    mutationFn: (options: PutStreakNotifiedOptions) => axios.put(`/user/streak/${id}`, options),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['/user/streak'] })
  })
}
