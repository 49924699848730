import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, Divider, Grid, Header, Icon } from 'semantic-ui-react'
import styled from 'styled-components'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'
import SoundRenderer from './SoundRenderer'

import useMobile from '../../../hooks/useMobile'
import usePlayAll from '../hooks/usePlayAll'

const TheRevealer = styled.div`
  button {
    border-radius: 10px !important;
  }

  &:focus-within {
    border-radius: 10px;
    outline: 2px solid #333;
  }
`

const DialogueWidget = (props) => {
  const [widget, setWidget] = useState(props.widget)
  const [loading, setLoading] = useState(!props.widget)
  const [lines, setLines] = useState(props.widget.lines || [])
  const [title, setTitle] = useState(props.widget.title || '')
  const [subtitle, setSubtitle] = useState(props.widget.subtitle || '')
  const [bottomTitle, setBottomTitle] = useState(props.widget.bottomTitle || '')
  const [disableReveal, setDisableReveal] = useState(props.widget.disableReveal)
  const [hasOtherLang, setHasOtherLang] = useState(false)
  const [showOtherLang, setShowOtherLang] = useState(false)
  // const progressive_settings = useSelector(state => state.auth.progressive_settings)
  // const [playsSound, setPlaysSound] = useState(props.widget.plays_sound || false)
  // const [sounds, setSounds] = useState({})
  const isMobile = useMobile()
  const { playAll, play, _stop, current, register, next } = usePlayAll()

  console.log(lines)

  useEffect(() => {
    if (widget?.lines) {
      const findOtherLang = !!widget.lines.filter((lines) => !!lines.other_lang)
      setHasOtherLang(findOtherLang)
    }
  }, [widget])

  if (loading) return <PlaceholderWidget widget_type="DialogueWidget" />

  return (
    <WidgetWrapper widget={{ widgetType: 'DialogueWidget', widgetId: widget._id }}>
      <div
        style={{
          backgroundColor: 'white',
          padding: isMobile ? '10px 18px' : '30px 45px',
          fontFamily: 'Milliard Book, Yu Gothic UI Regular',
          fontSize: '1.5rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          marginLeft: !isMobile && '-45px', // hasNoPadding didn't work
          marginRight: !isMobile && '-45px', // hasNoPadding didn't work
          boxShadow: '0px 0px 50px -40px #03002F'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: isMobile ? '15px' : (subtitle ? '0' : '30px'),
          }}
        >
        {title && (
          <Header
            data-t={`widget:dialogue-title-${widget._id}`}
            as="h2"
            style={{
              fontFamily: 'Milliard Bold, Yu Gothic UI Bold',
              fontSize: isMobile ? 'var(--large-font-size)' : 'var(--reg-font-size)',
              color: 'var(--fz-course-primary)',
              margin: '0',
            }}
          >
            <SoundRenderer text={title} />
          </Header>
        )}
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          {!disableReveal && <TheRevealer isMobile={isMobile}>
            <Button
              onClick={() => setShowOtherLang(!showOtherLang)}
              className="fz-button"
              icon={showOtherLang ? 'eye slash' : 'eye'}
            />
          </TheRevealer>}
        </div>
      </div>
      {subtitle && (
        <Header
          as="h3"
          style={{
            color: 'var(--light-blue-gray)',
            fontFamily: 'Milliard Book',
            margin: '5px 0 30px',
          }}
        >
          {subtitle}
        </Header>
      )}
      <div>
        <Grid className="fz-dark-font">
          {lines?.map((l, idx) => (
            <Grid.Row key={idx} style={{ display: 'flex', alignItems: 'baseline' }}>
              <Grid.Column
                width={4}
                style={{
                  fontFamily: 'Milliard Bold, Yu Gothic UI Regular',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: !isMobile && (props.progressive_settings?.main_default !== 'kanji' ? '-3px' : '6px'),
                }}
              >
                <SoundRenderer text={l?.speaker || l?.speaker[0]?.text} wrapAtIcon />
              </Grid.Column>
              <Grid.Column soundKey={idx} width={12}>
                <SoundRenderer
                  playingNum={current} onSound={register}
                  ping={next} num={idx}
                  text={l.text || l.text[0]}
                  wrapAtIcon
                  translation={l.other_lang}
                />
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
        {hasOtherLang && showOtherLang &&
            <>
              <Divider style={{ margin: '30px auto' }} />
              <Grid className="fz-dark-font">
                {lines?.map((l, idx) => (
                  <Grid.Row key={idx}>
                    <Grid.Column
                      width={4}
                      style={{ fontFamily: 'Milliard Bold, Yu Gothic UI Regular, sans-serif', }}
                    >
                      <SoundRenderer text={l.speaker || l.speaker[0].text} />
                    </Grid.Column>
                    <Grid.Column soundKey={idx} width={12}>
                      <SoundRenderer
                        playingNum={current} onSound={register}
                        ping={next} num={idx}
                        text={l.other_lang}
                        translation={l.other_lang}
                      />
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            </>}
        {bottomTitle && (
          <Header
            as="h3"
            style={{
              color: 'var(--blue-gray)',
              fontFamily: 'Milliard Book',
              margin: '25px 0 0',
            }}
          >
            {bottomTitle}
          </Header>
        )}
        {playAll && lines.length > 1 &&
          <Grid>
              <Grid.Row>
                  <Grid.Column width={4}></Grid.Column>
                  <Grid.Column width={12}>
                      <Button className={current === -1 ? 'fz-play-all' : 'fz-stop-all'} onClick={() => play(props.widget._id)}>
                          <Icon name="play" />
                          {current === -1 ? 'Play All' : 'Stop All'}
                      </Button>
                  </Grid.Column>
              </Grid.Row>
          </Grid>}
        </div>
      </div>
    </WidgetWrapper>
  )
}

export default DialogueWidget
