//
// place for styled components that are used in multiple places
// don't change these styles carelessly, better to just compose them into a new component if you need customization
// if they end up being used all the time we could probably create common options for them via optional props
// trying to give them sensible defaults tho for now, it's a WIP
//

import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { writingGuide, black, highlight } from './styles'

// Header that looks like text written in a notebook
export const WritingGuideHeader = styled.h1`
  font-family: 'Tilt Warp';
  background-image: url(${writingGuide});
  background-size: 100%;
  padding: 0 12px;
  color: ${black};
`

// Default text that's ready to use for highlighting
export const HighlightText = styled.p`
  position: relative;
  font-family: Outfit;
  font-weight: 600;
  font-size: 1.25rem;
  color: ${black};
  z-index: 0;
`

export const Highlight = styled.span`
  display: inline-grid;
  margin-bottom: -.7em; // offset the height of the ::after pseudo-element

  &::after {
    content: '';
    height: .7em;
    background-color: ${highlight};
    border-radius: .2em;
    width: calc(100% + 0.3em);
    justify-self: center;
    position: relative;
    bottom: 110%;
    z-index: -1;
  }
`

export const UnstyledLink = styled(Link)`
  margin: 0;
  border: none;
  text-decoration: none !important;
  color: inherit;
`
