import { useSelector } from 'react-redux'
import useNavigationContext from 'src/hooks/context/useNavigationContext'
import { RootState } from 'src/configureStore'
import LoginButton from '../LoginButton'
import Streak from './Streak'
import Avatar from './Avatar'
import AdminViewButton from './AdminViewButton'

const RightNav = () => {
  const isSignedIn = useSelector((state: RootState) => state.auth.isSignedIn)
  const { isMobileNav, slug } = useNavigationContext()

  if (slug === '/es' || slug === '/claim') return <></>
  if (!isSignedIn) return <LoginButton />

  return (
    <div style={{ gridColumn: 3, justifySelf: 'end', display: 'flex', alignItems: 'center', gap: '12px' }}>
      {!isMobileNav && <AdminViewButton />}
      <Streak />
      <Avatar />
    </div>
  )
}

export default RightNav
