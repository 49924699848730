import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation, withRouter } from 'react-router-dom'
import { signIn, loadCourses, loadSubs } from 'src/actions'
import queryString from 'query-string'
import styled from 'styled-components'
import { Loader } from '@mantine/core'

import useMobile from 'src/hooks/useMobile'
import axios from 'src/api'
import TomokoSubSuccess from '../pages/account/subscriptions/TomokoSubSuccess'

import { Obscured, ModalStyles, ModalXContainer } from 'src/assets/styles/DefaultModalStyle'
import {
  AuthBackgroundImage,
  AuthPageStyles,
  BackgroundImage,
  GlobalStyle,
} from 'src/assets/styles/authPageStyles'

import FZExclamation from '../FZExclamation'
import { YJClaimModal } from '../pages/RegisterPage'

import brush_banner from 'src/assets/sign_up_log_in_brush_stroke_chibi.png'
import tomoko from 'src/assets/chibi/tomoko/tomoko_culture_clip.png'

import AuthInputFields from './auth/AuthInputFields'

const renderBlacklist = ['/', '/ask', '/books', '/policy', '/tos', '/bug_report', '/claim']
const modalBlacklist = ['/login', '/register', '/resend', '/reset', '/forgot_password']

const YJExplanationWrapper = styled.div`
  padding: 30px;
  margin-top: -150px;
  margin-bottom: 10px !important;
  font-family: Milliard Book;

  & > h1 {
    font-size: 1.5rem !important;
    text-decoration: underline;
    color: var(--jfz-blue);
  }

  & > ul > li {
    margin-left: 14px;
  }

  & > h4 {
    font-size: 1.25rem !important;
    color: var(--jfz-blue);
  }

  & > p {
    font-size: 1rem !important;
    margin: 20px 0 !important;
  }
`

const YJExplanation = (props) => {
  return (
    <YJExplanationWrapper>
      <h1>YesJapan.com (JapaneseFromZero.com) has moved to FromZero.com</h1>
      <p>
        If you have a paying subscription on YesJapan.com, we have transferred your account
        to FromZero.com. We appreciate your support, and we're excited to share the new
        site with you!
      </p>
      <h4>Account migration details</h4>
      <ul>
        <li>
          You will retain your current subscription price
        </li>
        <li>
          You will have access to all premium courses on FromZero.com
        </li>
      </ul>
      <p>
        To claim your FromZero.com account, please complete the steps below:
      </p>
    </YJExplanationWrapper>
  )
}

const LoginModal = (props) => {
  const {
    asLogin = true,
    flat = false,
    // message = '',
    renderOverride = false,
    yj = false,
    onYjRedeem = () => {},
  } = props

  const [isLogin, setIsLogin] = useState(asLogin)
  const [isFlat, setIsFlat] = useState(flat)
  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  const [shake, setShake] = useState(false)
  const isSignedIn = useSelector((state) => state.auth.isSignedIn)
  const [inputFocused, setInputFocused] = useState(false)
  const [yjRedeemed, setYJRedeemed] = useState(false)
  const [showClaimModal, setShowClaimModal] = useState(false)

  const isMobile = useMobile()
  const location = useLocation()

  const queryValues = queryString.parse(props.location.search)

  const hideBanner = isMobile && inputFocused

  useEffect(() => {
    if (modalBlacklist.includes(props.location.pathname)) setIsFlat(true)
  }, [])

  const fetchCourses = () => {
    console.log('fetching courses...')
    if (!isSignedIn) return
    fetchBookmark()

    axios
      .get('/user/get_courses')
      .then((res) => {
        if (res.data.success) {
          props.loadCourses(res.data.courses)
          props.loadSubs(res.data.subs)

          if (flat) {
            props.history.push('/home')
          } else if (props.openModal) props.openModal(false)
        }
      })
      .catch((err) => {
        console.error(err)
        props.openModal(true)
      })
  }

  useEffect(() => {
    if (isSignedIn && props.asLogin) {
      // console.log('fetching courses + redirecting...')
      fetchCourses()
      props.history.push(props.redirect)
    }
  }, [])

  // auth failure
  const onAuthFailure = () => {
    setShake(true)

    setTimeout(() => {
      setShake(false)
    }, 200)
  }

  const handleCloseTransfer = () => {
    props.history.push('/home')
  }

  if (isSignedIn && !yj && !yjRedeemed) {
    return (
      <div style={{ padding: '100px', minHeight: '95vh', maxHeight: '100vh', height: '95vh', textAlign: 'center' }}>
        <Loader centered style={{ margin: 'auto' }} />
      </div>
    )
  }

  return (
    <>
    <GlobalStyle />
    {showClaimModal && <YJClaimModal />}
      {(!renderBlacklist.includes(location.pathname) || renderOverride) && (
        <>
          {isFlat ? (
            <>
              {isMobile && isLogin ? (
                <BackgroundImage />
              ) : (
                isMobile && <AuthBackgroundImage />
              )}
              <AuthPageStyles
                success={registrationSuccess}
                isMobile={isMobile}
                isLogin={isLogin}
              >
                <div
                  className={`auth-fields${
                    shake ? ' horizontalShake shakeIt' : ''
                  }`}
                >
                  <div className="flex-center"></div>
                  {yj && <YJExplanation />}
                  {(yj || yjRedeemed) && (
                    <TomokoSubSuccess
                      closePopup={handleCloseTransfer}
                      showing={registrationSuccess}
                      mainText="You've successfully claimed your YesJapan account!"
                    />
                  )}
                  {isMobile && (
                    <FZExclamation
                      title={isLogin ? 'Login_mobile' : 'Register_mobile'}
                      centered
                      color="blue"
                      style={{
                        margin: '100px auto 0',
                        width: '80%',
                      }}
                    />
                  )}
                  {!isMobile && (
                    <FZExclamation
                      title={yj ? 'Claim Your Account' : (isLogin ? 'Login' : 'Register')}
                      centered
                      color="blue"
                    />
                  )}
                  <AuthInputFields
                    asLogin={asLogin}
                    isLogin={isLogin}
                    location={location}
                    queryValues={queryValues}
                    registrationSuccess={registrationSuccess}
                    setRegistrationSuccess={setRegistrationSuccess}
                    signIn={props.signIn}
                    noLabels
                    onAuthFailure={onAuthFailure}
                    yj={yj || yjRedeemed}
                    onYjRedeem={() => {
                      onYjRedeem()
                      setYJRedeemed(true)
                    }}
                    setShowClaimModal={setShowClaimModal}
                    flat={isFlat}
                  />
                </div>
                {!isMobile && (
                  <div className="fireworks-town">
                    <div>
                      <img
                        src={tomoko}
                        draggable={false}
                        className="no-select"
                      />
                    </div>
                  </div>
                )}
              </AuthPageStyles>
            </>
          ) : (
            <>
              <Obscured
                onClick={() => props.obscuredOnClick != null ? props.obscuredOnClick() : null}
              />
              <ModalStyles isMobile={isMobile} flat={isFlat}>
                <ModalXContainer
                  onClick={() => props.obscuredOnClick != null ? props.obscuredOnClick() : null}
                >
                  ✕
                </ModalXContainer>
                <div className="fz-cast">
                  {!hideBanner && <img src={brush_banner} alt="From Zero! students" />}
                </div>
                {!!props.message && (
                  <h3 className="message">{props.message}</h3>
                )}
                <h2 style={{ margin: props.message ? '' : '10px auto' }}>
                  {isLogin ? 'Sign in to' : 'Register with'}
                </h2>
                <FZExclamation
                  centered
                  title="FromZero"
                  color="blue"
                  size="big"
                  style={{ marginLeft: '30px' }}
                />
                <AuthInputFields
                  asLogin={asLogin}
                  isLogin={isLogin}
                  inModal={true}
                  location={location}
                  queryValues={queryValues}
                  signIn={props.signIn}
                  onAuthFailure={onAuthFailure}
                  flat={flat}
                  setInputFocused={setInputFocused}
                  setShowClaimModal={setShowClaimModal}
                />
              </ModalStyles>
            </>
          )}
        </>
      )}
    </>
  )
}

const routerLoginPage = withRouter(LoginModal)

export default connect(null, { signIn, loadCourses, loadSubs })(routerLoginPage)
