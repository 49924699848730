import { CLOUDFRONT_BASE } from 'src/util/constants'
import { COURSE_DATA } from 'src/util/courseInfo'

// landing page v3 assets
const landingPageV3BaseDesktop = `${CLOUDFRONT_BASE}/images/landing_page/v3/desktop`
const landingPageV3BaseMobile = `${CLOUDFRONT_BASE}/images/landing_page/v3/mobile`
export const writingGuide = `${landingPageV3BaseDesktop}/writing-guide-flex.svg`

// colors
export const black = '#3C4043'
export const white = '#FFFFFF'
export const blue = '#7BBBFF'
export const red = '#FF3559'
export const green = '#82C760'
export const yellow = '#FFEEB1'
export const highlight = '#FFEEB1'
export const seafoam = '#68DECB'
export const bluegrey = '#C8D0EA'
export const lightgrey = '#DBE1F5'

export type CourseColors = {
  primary: string
  secondary: string
  tertiary: string
  highlight: string
}

const courseColors: Record<string, CourseColors> = {
  course1: {
    primary: '#7BBBFF',
    secondary: '#63A8F2',
    tertiary: '#3E74C6',
    highlight: '#9FCEFF'
  },
  course2: {
    primary: '#FF6C6C',
    secondary: '#E75A5A',
    tertiary: '#BE3232',
    highlight: '#FF8F88',
  },
  course3: {
    primary: '#79D882',
    secondary: '#50C35C',
    tertiary: '#388D40',
    highlight: '#ABE4B1',
  },
  course4: {
    primary: '#CE7DCA',
    secondary: '#BF68BB',
    tertiary: '#8D4294',
    highlight: '#D7A3D4',
  },
  course5: {
    primary: '#EE659C',
    secondary: '#E24D89',
    tertiary: '#B9335C',
    highlight: '#F493BB',
  },
  kanji1: {
    primary: '#9FB9ED',
    secondary: '#8AAAE9',
    tertiary: '#536EA2',
    highlight: '#C4D4F6',
  },
  kanji2: {
    primary: '#E087A7',
    secondary: '#C36D8C',
    tertiary: '#A53C62',
    highlight: '#E8A7BE',
  },
}

const courseMap = {
  jfz1: 'course1',
  jfz2: 'course2',
  jfz3: 'course3',
  jfz4: 'course4',
  jfz5: 'course5',
  japanese1: 'course1',
  japanese2: 'course2',
  japanese3: 'course3',
  japanese4: 'course4',
  japanese5: 'course5',
  kanji1: 'kanji1',
  kanji2: 'kanji2',
  kfz1: 'course1',
  kfz2: 'course2',
  kfz3: 'course3',
  korean1: 'course1',
  korean2: 'course2',
  korean3: 'course3',
  cfz1: 'course1',
  spanish1: 'course1',
  jin5: 'course1',
  ask: 'course2',
  daily: 'course3',
  hitokoto: 'course4',
  jtm: 'course5',
  jlptn5: 'course1',
  jlptn4: 'course2',
  jlptn3: 'course3',
  jlptn2: 'course4',
  jlptn1: 'course5',
}

export const getCourseColors = (identifier: string) => courseColors[courseMap[identifier]] ?? courseColors.course1

export const theme = {
  // course_data: COURSE_DATA, // old styles that will hopefully be replaced and then we can throw this junk away
}
