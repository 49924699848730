import styled from '@emotion/styled'
import { useLocation } from 'react-use'
import { UnstyledLink } from './ui/styledComponents'

const year = new Date().getFullYear()

const Wrapper = styled.div<{ colorBG: boolean }>`
  display: grid;
  font-family: Figtree;
  font-size: 1rem;
  font-weight: 500;
  align-items: center;
  padding: 16px;
  grid-row-gap: 8px; // only shows up on small screens when footer stacks
  color: ${({ colorBG }) => colorBG ? 'white' : 'var(--fz-course-tertiary)'};
  background-color: ${({ colorBG }) => colorBG ? 'var(--fz-course-primary)' : ''};
`

const Copyright = styled.p`
  grid-column: 1;
  grid-row: 1;

  @media (max-width: 600px) {
    grid-row: 2;
    justify-self: center;
  }
`

const Links = styled.div`
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
  display: flex;
  gap: 8px;
`

const Footer = () => {
  const { pathname } = useLocation()
  const basePath = pathname.split('/')[1]
  const colorBG = ['', 'home'].includes(basePath)

  return (
    <Wrapper colorBG={colorBG}>
      <Copyright>&copy;{year} From Zero LLC</Copyright>
      <Links>
        <UnstyledLink to='/privacy'>Privacy Policy</UnstyledLink>
        <p>|</p>
        <UnstyledLink to='/tos'>Terms of Use</UnstyledLink>
      </Links>
    </Wrapper>
  )
}

export default Footer
