import { AxiosError, AxiosResponse } from 'axios'
import React, { useState } from 'react'
import axios from 'src/api'

import { ToolOptionsProps } from '../interfaces/CreationToolkit.interface'
import useToolkitStore from '../../../../../stores/toolkit.store'
import { ToolOptions as OptionsBody } from '../styles'
import DeleteConfirmationModal from '../../modals/DeleteConfirmation.modal'
import ToolInput from './ToolInput.part'
import useSublessonStore from 'src/stores/sublesson.store'
import { Icon } from 'semantic-ui-react'

const ToolOptions: React.FC<ToolOptionsProps> = ({
  children,
  id,
}) => {
  const optionsVisible = useToolkitStore((state) => state.optionsVisible)
  const deleteWidget = useToolkitStore((state) => state.deleteWidget)
  const [deletePromptOpen, setDeletePromptOpen] = useState<boolean>()
  const [copied, setCopied] = useState<boolean>()
  const [recaching, setRecaching] = useState<boolean>()
  const [recached, setRecached] = useState<boolean>()
  const [cacheError, setCacheError] = useState<boolean>()

  const parts = useSublessonStore((state) => state.parts)
  const setParts = useSublessonStore((state) => state.setParts)

  const copyId = () => {
    navigator.clipboard.writeText(id)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 5000)
  }

  const refreshCache = async (polly = false, force = false) => {
    if (recaching) return

    setRecaching(() => true)
    let response: AxiosResponse
    try {
      response = await axios.put(`/widgets/${id}/refresh_cache`, { polly, force })
    } catch (err) {
      const error = err as AxiosError
      console.error(error?.message)
      console.error(error?.name)
      setCacheError(() => true)
    } finally {
      if (response.data.success) {
        setRecached(() => true)
        setCacheError(() => false)
      }
      setRecaching(() => false)
    }
  }

  const promptDelete = () => setDeletePromptOpen(() => true)

  // marks the part stale, meaning it should be refetched in SubLessonPart or higher
  // also ejects the tool from Zustand state

  // should work *flawlessly* for MoreDetails/Grid nested tools,
  // since their inner tool ids will never match the parent tool id
  // (Grid is capable of ejecting its own nested tools)
  const markPartStale = () => {
    const _parts = [...parts]
    const updated_parts = []
    _parts.forEach((p) => {
      let stale_part = false
      const _widgets = p.widgets.filter((w) => {
        if (w._id === id) stale_part = true
        return w._id !== id
      })
      updated_parts.push({ ...p, widgets: _widgets, stale_part })
    })
    setParts(updated_parts)
  }

  const duplicateWidget = async () => {
    try {
      // const response: AxiosResponse =
      await axios.post('/course/duplicate/widget', { widget: id })
    } catch (err) {
      const error = err as AxiosError
      console.error(error?.message)
      console.error(error?.name)
    } finally {
      markPartStale()
    }
  }

  const handleDeleteWidgetConfirmed = () => {
    deleteWidget(id)
    markPartStale()
    setDeletePromptOpen(() => false)
  }

  if (!optionsVisible.includes(id)) return (<></>)

  return (
    <OptionsBody id='tk-tool-options'>
      <DeleteConfirmationModal
        message='This widget will be'
        emphasized='permanently removed from the database and cache'
        deletePromptOpen={deletePromptOpen}
        setDeletePromptOpen={setDeletePromptOpen}
        handleDeleteConfirmed={handleDeleteWidgetConfirmed}
      />

      <div className='extra-options tw-flex-wrap'>
        <div>
          <ToolInput
            value='is_draft'
            inputLabel='Drafting'
            inputType='checkbox'
            labelPosition='after'
            title='Hides widget from users while this is checked'
          />
        </div>
        {children}
      </div>
      <div className='default-options'>
        <button
          type='button'
          className='copy-id'
          onClick={() => copyId()}
          style={{ minWidth: 'max-content' }} // prevents line break before "ID"
          title='Copy widget ID to clipboard'
        >
          {copied ? 'Copied!' : 'Copy ID'}
        </button>
        <button
          type='button'
          className='cache'
          disabled={recaching}
          onClick={() => refreshCache()}
          title='Refresh cached widget to match this one'
        >
          {recached ? 'Refreshed!' : 'Recache'}
        </button>
        <button
          type='button'
          className='cache'
          onClick={() => duplicateWidget()}
          title='Clones widget'
        >
          <span style={{ textShadow: '0 6px rgba(255,255,255,0.5), 0 10px rgba(255,255,255,0.25)' }}>
            Duplicate
          </span>
        </button>
        <button
          type='button'
          className='polly'
          disabled={recaching}
          onClick={() => refreshCache(true)}
          title='Add Polly'
        >
          +Polly
          {recached && '🦜'}
          {cacheError && '👎'}
        </button>
        <button
          type='button'
          className='polly force'
          disabled={recaching}
          onClick={() => refreshCache(true, true)}
          title='Force Add Polly'
        >
          +Polly!
          {recached && '🦜'}
          {cacheError && '👎'}
        </button>
        <button
          type='button'
          className='danger-delete'
          onClick={() => promptDelete()}
          title='Yeet this widget out the window'
        >
          Delete
        </button>
      </div>
    </OptionsBody>
  )
}

export default ToolOptions
