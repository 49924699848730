import GridWidget from './GridWidget'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import MoreDetailsStyles from './styles/MoreDetailsStyles'
import SoundRenderer from './SoundRenderer'
import useMobile from 'src/hooks/useMobile'

const MoreDetailsWidget = ({ widget, course }) => {
  const { _id, contents, title } = widget
  const isMobile = useMobile()

  // console.log(widget)

  return (
      <MoreDetailsStyles isMobile={isMobile}>
        <WidgetWrapper widget={{ widgetId: _id, widgetType: 'MoreDetails' }}>
          <section>
            <h2 style={{ background: 'var(--fz-course-tertiary)' }}>
              <SoundRenderer text={title || 'More Details'} />
            </h2>
          </section>
          <section>
            <GridWidget widget={contents || widget} course={course} isMoreDetailsWidget />
          </section>
        </WidgetWrapper>
      </MoreDetailsStyles>
  )
}

export default MoreDetailsWidget
