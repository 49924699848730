// center nav - not used anymore
export const navigationItems = [
  { name: 'Courses', href: '/courses', key: 'Courses' },
  { name: 'Ask-a-Teacher', href: '/ask', key: 'Ask' },
  { name: 'Notebook', href: '/notebook', key: 'Notebook' },
  { name: 'Games', href: '/games', key: 'Games' },
  { name: 'Video Vault', href: '/vault', key: 'Vault' },
  { name: 'Shop', href: '/books', key: 'Shop' },
  { name: 'Search', href: '/search', key: 'Search' },
]

export const dropdownNavigationItems = [
  { name: 'Settings', href: '/account', key: 'Settings' },
  { name: 'Notifications', href: '/notifications', key: 'Notifications' },
  { name: 'Support', href: '/support', key: 'Support' },
]

export const sidebarNavigationItems = [
  { name: 'Home', href: '/home', key: 'Home' },
  { name: 'Courses', href: '/courses', key: 'Courses' },
  { name: 'Ask-a-Teacher', href: '/ask', key: 'Ask' },
  { name: 'Notebook', href: '/notebook', key: 'Notebook' },
  { name: 'Games', href: '/games', key: 'Games' },
  { name: 'Video Vault', href: '/vault', key: 'Vault' },
  { name: 'Shop', href: '/books', key: 'Shop' },
  { name: 'Search', href: '/search', key: 'Search' },
]
