import { useState, useEffect } from 'react'
import useNavigationContext from 'src/hooks/context/useNavigationContext'
import { useLocation, useHistory } from 'react-router-dom'
import NavIcon from './NavIcon'

const NavLinkItemsSection = ({ items, dropdown = false }) => {
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null)
  const { pathname } = useLocation()
  const history = useHistory()
  const { slug } = useNavigationContext()


  // prevent sticking hover state on mobile
  useEffect(() => {
    setHoveredIcon(null)
  }, [pathname])

  return (
    <>
      {items.map((item) => {
        const hovered = hoveredIcon === item.key
        const active = slug === item.href.split('/')[1] && pathname.split('/').length === 2 // only active if we're on the root of the link
        const activeOrHovered = active || hovered

        return (
          <div
            key={item.key}
            className='tw-flex tw-items-center tw-content-center tw-cursor-pointer tw-gap-3'
            onClick={() => !active && history.push(item.href)}
            onMouseEnter={() => setHoveredIcon(item.key)}
            onMouseLeave={() => setHoveredIcon(null)}
          >
            <NavIcon key={item.key} item={item} parentIsHovered={hovered} dropdown={dropdown} />
            <h3 style={{ color: activeOrHovered ? 'var(--fz-course-tertiary)' : 'white', fontSize: dropdown ? '1.2rem' : '' }}>{item.name}</h3>
          </div>
        )
      })}
    </>
  )
}

export default NavLinkItemsSection
