import styled, { keyframes } from 'styled-components'
import { black } from 'src/components/ui/styles'

export const StreakSpan = styled.span`
  z-index: 1;
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px !important;
  font-weight: 900;
  font-family: 'Figtree', sans-serif;
  color: #fff;
  user-select: none;
  cursor: pointer;
`

export const flameWiggle = keyframes`
  0% { transform: scale(1) rotate(0deg); }
  20% { transform: scale(1.2) rotate(-5deg); }
  40% { transform: scale(1.2) rotate(5deg); }
  60% { transform: scale(1.2) rotate(-5deg); }
  80% { transform: scale(1.2) rotate(5deg); }
  100% { transform: scale(1) rotate(0deg); }
`

export const FlameIcon = styled.img<{ animate: boolean }>`
  transform-origin: center center;
  animation: ${({ animate }) => animate ? flameWiggle : 'none'} 0.8s ease-in-out;
  height: 25px;
  max-width: unset;
  cursor: pointer;
`

export const PopoverMessage = styled.p`
  font-family: 'Figtree', sans-serif;
  font-size: 1.1rem;
  color: ${black};
`
