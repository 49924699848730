export const BUY_COURSE = 'BUY_COURSE'
export const CHANGE_COURSE_BACKGROUND = 'CHANGE_COURSE_BACKGROUND'
export const CHANGE_COURSE_BACKGROUND_KR = 'CHANGE_COURSE_BACKGROUND_KR'
export const CHANGE_HOMEROOM_NOTIFICATIONS = 'CHANGE_HOMEROOM_NOTIFICATIONS'
export const CHANGE_SETTING = 'CHANGE_SETTING'
export const CHANGE_SETTINGS = 'CHANGE_SETTINGS'
export const CHANGE_STUDY_MODE = 'CHANGE_STUDY_MODE'
export const CHANGE_PREFERRED_SPEAKER = 'CHANGE_PREFERRED_SPEAKER'
export const CHANGE_PROGRESSIVE = 'CHANGE_PROGRESSIVE'
export const CHANGE_USERNAME = 'CHANGE_USERNAME'
export const LOAD_COURSES = 'LOAD_COURSES'
export const LOAD_SUBS = 'LOAD_SUBS'
export const SET_BADGES = 'SET_BADGES'
export const SET_DEFAULT_AVATAR = 'SET_DEFAULT_AVATAR'
export const SET_OWN_AVATAR = 'SET_OWN_AVATAR'
export const SET_POPUP = 'SET_POPUP'
export const SET_REFERRER = 'SET_REFERRER'
export const SET_ROLES = 'SET_ROLES'
export const SET_USER_AGENT = 'SET_USER_AGENT'
export const SET_V7_LAST_QUIZ = 'SET_V7_LAST_QUIZ'
export const SET_V8_DISCLAIMER = 'SET_V8_DISCLAIMER'
export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const SUBSCRIBE = 'SUBSCRIBE'
export const SWITCH_COLORIZED_FURI = 'SWITCH_COLORIZED_FURI'
export const SWAP_FURI_COLORS = 'SWAP_FURI_COLORS'
export const SWITCH_CUSTOM_PROGRESSIVE = 'SWITCH_CUSTOM_PROGRESSIVE'
export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE'
export const TOGGLE_V8_PROGRESSIVE = 'TOGGLE_V8_PROGRESSIVE'
export const UNSET_BADGES = 'UNSET_BADGES'
export const UNSET_OWN_AVATAR = 'UNSET_OWN_AVATAR'
export const UPDATE_HISTORY = 'UPDATE_HISTORY'

// navigator types (for TypeScript import REDUX_NAV_ACTIONS types)

// eslint-disable-next-line no-shadow
export enum REDUX_NAV_ACTIONS {
  SET_ADMIN_VIEW = 'SET_ADMIN_VIEW',
  SET_AVATAR_CF = 'SET_AVATAR_CF',
  SET_BAR_COLOR = 'SET_BAR_COLOR',
  SET_COURSE_INFO = 'SET_COURSE_INFO',
  SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT',
  SET_SLUG = 'SET_SLUG',
  SET_SUBLESSON = 'SET_SUBLESSON',
  SET_NUM_PRELESSONS = 'SET_NUM_PRELESSONS',
  SET_NUM_LESSONS = 'SET_NUM_LESSONS'
}

export const SET_AVATAR_CF = 'SET_AVATAR_CF'
export const SET_BAR_COLOR = 'SET_BAR_COLOR'
export const SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT'
export const SET_SLUG = 'SET_SLUG'
export const SET_SUBLESSON = 'SET_SUBLESSON'
export const SET_NUM_PRELESSONS = 'SET_NUM_PRELESSONS'
export const SET_NUM_LESSONS = 'SET_NUM_LESSONS'
