import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'src/configureStore'
import { changeStudyMode } from '../../../../../actions/index'

import { StudyModeOptionsWrapper, StudyModeButton, LangSelector, Toggle } from '../../../styles/StudyModeOptionsStyles'

const StudyModeOptions = () => {
  const studyModeSettings = useSelector((state: RootState) => state.auth?.study_mode_settings)
  const { inStudyMode, shownLanguage } = studyModeSettings || { inStudyMode: false, shownLanguage: 'other' }
  const [checkedState, setCheckedState] = useState(shownLanguage === 'english')
  const dispatch = useDispatch()

  const toggleStudyMode = () => {
    dispatch(changeStudyMode({ ...studyModeSettings, inStudyMode: !inStudyMode }))
  }

  const changeShownLanguage = (e) => {
    const { checked } = e.target
    if (checked) {
      dispatch(changeStudyMode({ ...studyModeSettings, shownLanguage: 'english' }))
    } else {
      dispatch(changeStudyMode({ ...studyModeSettings, shownLanguage: 'other' }))
    }
    setCheckedState(checked)
  }

  return (
    <StudyModeOptionsWrapper>
      <StudyModeButton onClick={toggleStudyMode} inStudyMode={inStudyMode}>Study Mode</StudyModeButton>
      <LangSelector inStudyMode={inStudyMode}>
        <p>日本語</p>
        <Toggle
          type='checkbox'
          checked={checkedState}
          onChange={(e) => changeShownLanguage(e)}
          inStudyMode={inStudyMode}
          disabled={!inStudyMode}
        />
        <p>ENG</p>
      </LangSelector>
    </StudyModeOptionsWrapper>
  )
}

export default StudyModeOptions
