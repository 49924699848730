import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import axios from 'src/api'

export const createAffiliateClick = async (affiliateId?: string, videoId?: string, adId?: string) => {
  if (!affiliateId) {
    return false
  }

  try {
    return axios.post('/affiliate/click', {
      affiliateId,
      videoId,
      adId,
    })
  } catch (e) {
    console.error(e)
    return false
  }
}

export const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const useAffiliateClick = (affiliateId?: string) => {
  const queryParams = useQuery()
  const mut = useMutation({
    mutationFn: () => createAffiliateClick(affiliateId, queryParams.get('utm_campaign')),
    onError: (e: AxiosError) => console.error(e),
  })

  useEffect(() => {
    if (affiliateId && queryParams.get('utm_campaign')) {
      mut.mutate()
    }
  }, [affiliateId])

  return {
    ...mut,
  }
}

export default useAffiliateClick
