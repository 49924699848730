import GridWidgetRow from './styles/GridWidget.styles'
import ImageGridRow from './styles/ImageGridWidgetStyles'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'

import TextBoxWidget from './TextBoxWidget'
import ConversationWidget from './ConversationWidget'
import TextListWidget from './TextListWidget'
import TableWidget from './TableWidget'
import ImageWidget from './ImageWidget'
import SentencesWidget from './SentencesWidget'
import QnAWidget from './QnAWidget'
import ExamplesWidget from './ExamplesWidget'
import StructureWidget from './StructureWidget'
import VideoWidget from './VideoWidget'
import MiniQuizWidget from './MiniQuizWidget'
import QnAStructureWidget from './QnAStructureWidget'
import UsageWidget from './UsageWidget'
import DialogueWidget from './DialogueWidget'
import KanjiWidget from './KanjiWidget'
import ListWidget from './ListWidget'

import useStaffRoles from 'src/hooks/useStaffRoles'

const WidgetRenderView = (props) => {
  const { widget, course, grid, isImageGrid, isGridWidget } = props
  const widgetProps = { id: widget?._id, widget, course, path: widget?._id, grid, isGridWidget, isImageGrid }

  return (
    <WidgetWrapper widget={{ widgetId: widget._id, widgetType: 'GridWidget' }}>
    <div
      data-t={`widget:grid-id:${widget._id}`}
    >
      {widget ? (
        {
          TextboxWidget: <TextBoxWidget { ...widgetProps } />,
          ConversationWidget: <ConversationWidget { ...widgetProps }/>,
          TextListWidget: <TextListWidget { ...widgetProps } />,
          TableWidget: <TableWidget { ...widgetProps } />,
          ImageWidget: (
            <ImageWidget { ...widgetProps } fitted colWidth={props.width} height="auto" width="auto" grid={grid} />
          ),
          SentencesWidget: <SentencesWidget { ...widgetProps } isGridWidget />,
          QnAWidget: <QnAWidget { ...widgetProps } />,
          ExamplesWidget: <ExamplesWidget { ...widgetProps } />,
          StructureWidget: <StructureWidget { ...widgetProps } />,
          VideoWidget: <VideoWidget { ...widgetProps } />,
          MiniQuizWidget: <MiniQuizWidget { ...widgetProps } />,
          QnAStructureWidget: <QnAStructureWidget { ...widgetProps }/>,
          UsageWidget: <UsageWidget { ...widgetProps } />,
          DialogueWidget: <DialogueWidget { ...widgetProps } />,
          KanjiWidget: <KanjiWidget { ...widgetProps } />,
          // eslint-disable-next-line no-use-before-define
          GridWidget: <GridWidget { ...widgetProps } />,
          ListWidget: <ListWidget { ...widgetProps } />,
        }[widget.type]
      ) : (
        <PlaceholderWidget widget_type="MixedWidget" />
      )}
    </div>
    </WidgetWrapper>
  )
}

const GridWidget = (props) => {
  const { widget } = props
  const { rows, columnWidth, isImageGrid } = widget // need to add proper styling if we want to use columnWidth
  const [isStaffRole] = useStaffRoles()

  if (isImageGrid) {
    return (
      <WidgetWrapper widget={{ widgetType: 'GridWidget', widgetId: widget._id }}>
        {rows?.map((r, idr) => (
          <ImageGridRow key={`row-${idr}`} roundCorners={idr === 0}>
            {r?.map((c, idc) => (
              <WidgetRenderView isGridWidget={true} isImageGrid={true} course={props.course} widget={c} key={`column-${idc}`} grid={widget._id} />
            ))}
          </ImageGridRow>
        ))}
      </WidgetWrapper>
    )
  }

  return (
      <WidgetWrapper widget={{ widgetType: 'GridWidget', widgetId: widget._id }}>
      {/* little bit of space padding in case the grid is empty */}
      {isStaffRole && !rows?.[0] && <div style={{ padding: '50px' }}>GRID EMPTY</div>}
      {rows?.map((r, idr) => (
        <GridWidgetRow key={`row-${idr}`}>
          {r?.map((c, idc) => (
            <WidgetRenderView
              key={`column-${idc}`}
              widget={c}
              course={props.course}
              grid={widget._id}
              isGridWidget={true}
            />
          ))}
        </GridWidgetRow>
      ))}
      </WidgetWrapper>
  )
}

export default GridWidget
