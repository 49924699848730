import styled, { css } from 'styled-components'

interface isMobileProp {
  isMobile?: boolean
}

interface ExamplesProps {
  isOrdered?: boolean
  isMobile?: boolean
  isVerticalGrid?: boolean
  english?: boolean
  swapColumnDisplay?: boolean
  secondPlayAll?: boolean
}

export const ExamplesGrid = styled.div<ExamplesProps>`
  font-family: 'Milliard Book', 'Yu Gothic UI Regular';
  font-size: var(--reg-font-size);
  display: grid;
  grid-gap: ${({ isMobile }) => (isMobile ? '1rem 0.5rem' : '1.5rem 0.5rem')};
  align-items: baseline;
  ${({ isVerticalGrid }) => isVerticalGrid
    && css<ExamplesProps>`
      grid-template-columns: ${({ isOrdered }) => (isOrdered ? 'auto 1fr' : '1fr')};
    `}
  ${({ isVerticalGrid }) => !isVerticalGrid
    && css<ExamplesProps>`
      grid-template-columns: ${({ isOrdered }) => (isOrdered ? 'auto 1fr 1fr' : '1fr 1fr')};
    `}
`

export const ExamplesHeader = styled.h2<ExamplesProps>`
  font-family: 'Milliard Bold';
  font-size: ${({ isMobile }) => (isMobile ? '' : 'var(--reg-font-size)')};
  color: var(--fz-course-primary);
  margin: ${({ isMobile }) => (isMobile ? '0' : '0 0 -1rem 0')} !important;
  padding: 0;
  grid-column: 1 / -1;
`

export const RenderedSound = styled.div<ExamplesProps>`
  grid-column: ${({ isVerticalGrid, isOrdered }) => isVerticalGrid && isOrdered && 2};
  margin: ${({ isVerticalGrid, english }) => isVerticalGrid && english && '-1rem 0 1rem 25px'}; // non-ideal negative margin but has to fight against the grid gap
`

export const Spacer = styled.div<ExamplesProps>`
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 2px dashed #ddd;
  width: 100%;
  grid-column: 1 / -1;
  margin-top: ${({ isMobile }) => isMobile && '-10px'};
`

export const ExamplesPlayAll = styled.button<ExamplesProps>`
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
  margin:  ${({ isVerticalGrid }) => isVerticalGrid ? '0 0 1rem 25px' : '1rem 0'};
  padding: 0.5rem;
  border-radius: 4px;
  max-width: 100px;
  grid-column: ${({ isVerticalGrid, isOrdered }) => isVerticalGrid && isOrdered && 2};
  grid-column: ${({ isVerticalGrid, isOrdered, swapColumnDisplay }) => !isVerticalGrid && isOrdered && swapColumnDisplay && 3};
  grid-column: ${({ isVerticalGrid, isOrdered, swapColumnDisplay }) => !isVerticalGrid && isOrdered && !swapColumnDisplay && 2};
  grid-column: ${({ isVerticalGrid, isOrdered, swapColumnDisplay }) => !isVerticalGrid && !isOrdered && swapColumnDisplay && 2};
`

const textStyle = css`
  font-family: 'Milliard Book';
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
  line-height: 1.8;
`

export const Commentary = styled.div<ExamplesProps>`
  ${textStyle};
  grid-column: ${({ isOrdered }) => isOrdered ? '2 / -1' : '1 / -1'};
  position: relative;
  font-size: ${({ isMobile }) => isMobile ? '1rem' : '1.2rem'};
  margin-top: ${({ isVerticalGrid }) => isVerticalGrid ? '-32px' : '-10px'};
  margin-top: ${({ isVerticalGrid, isMobile }) => isMobile && isVerticalGrid && '-30px'};
  margin-left: ${({ isVerticalGrid, swapColumnDisplay }) => (isVerticalGrid || !swapColumnDisplay) && 24}px;
`

export const ListSegment = styled.div<isMobileProp>`
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  background: #FFFFFF;
  padding: ${({ isMobile }) => isMobile ? '10px 14px' : '20px 45px'};
  border-style: none;
  border-color: ${({ isMobile }) => isMobile ? 'transparent' : ''};
  box-shadow: ${({ isMobile }) => isMobile ? 'none' : '0px 0px 50px -40px #03002F'};
`

export const Example = styled.div`
  ${textStyle}
`

export const DialogueFlex = styled.div<isMobileProp>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ isMobile }) => isMobile ? '15px' : '10px'};
`

interface SegmentProps {
  isMobile: boolean
  reading?: boolean
}

export const Segment = styled.div<SegmentProps>`
  position: relative;
  margin: ${({ isMobile }) => isMobile ? '' : '10px 45px'};
  background-color: white;
  border-radius: ${({ isMobile }) => isMobile ? '' : '15px'};
  border: ${({ isMobile }) => isMobile ? '' : '1px solid #777'};
  border-top: ${({ isMobile }) => isMobile ? '' : '1px solid #777'};
  border-color: ${({ reading }) => reading ? '#ccc' : '#777'};
  padding: ${({ isMobile }) => isMobile ? '10px 18px' : '30px'};
  font-family: 'Milliard Book, Yu Gothic UI Regular';
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`

export const Speaker = styled.div`
  font-family: 'Milliard Bold', 'Yu Gothic UI Regular';
  display: flex;
  align-items: center;
  flex-basis: 25%;
  padding-top: 3px;
`

export const TheRevealer = styled.div`
  button {
    border-radius: 10px !important;
  }

  &:focus-within {
    border-radius: 10px;
    outline: 2px solid #333;
  }
`

export const Title = styled.h2<isMobileProp>`
  font-family: 'Milliard Bold';
  font-size: ${({ isMobile }) => isMobile ? '' : 'var(--reg-font-size)'};
  color: var(--fz-course-primary);
  margin: 0;
  padding: 0;
  margin-left: 0px;
`

export const Subtitle = styled.h3`
  color: var(--light-blue-gray);
  font-family: 'Milliard Book';
  margin: 5px 0;
`

export const PairLine = styled.div`
  height: 1px;
  width: 100%;
  margin: -5px 0 -5px 0;
  border-top: 2px dashed transparent;
  grid-column: 1 / -1;
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 10px 0 10px 0;
  border-top: 1px solid #ddd;
`

export const ReadingRow = styled.div`
  position: relative;
  font-size: 1.5rem;
  color: var(--blue-gray);
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  grid-column-gap: 8px;
`

export const ReadingWrapper = styled.div`
  line-height: 3rem;
  margin-right: 20px;
  margin-top: 10px;
`

export const ReadingLabel = styled.div`
  display: grid;
  background-color: #2e64bc;
  justify-content: center;
  color: white;
  width: 24px;
  height: 24px;
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
  font-size: .9rem;
  padding: 5px;
  border-radius: 50%;
  font-weight: bold;
             
  & > p {
    position: relative;
    top: -3px;
    font-family: 'Roboto';
    font-weight: bold;
  }
`

export const ReadingReveal = styled.div`
  grid-column: 3;
`
