import useNavigationContext from 'src/hooks/context/useNavigationContext'
import styled from '@emotion/styled'

const SVG = styled.svg<{ isOpen: boolean }>`
  width: 25px;

  path {
    fill: ${({ isOpen }) => isOpen ? 'var(--fz-course-primary)' : '#C8CFEA'};
    transition: fill 0.15s;
  }

  @media (hover: hover) {
    &:hover path {
      fill: ${({ isOpen }) => isOpen ? '' : '#9DA7C3'};
    }
  }

  &:active path {
    fill: var(--fz-course-primary);
    transition: fill 0.05s;
  }
`

const HamburgerIcon = () => {
  const { openSidebarMenu, sidebarMenuIsOpen } = useNavigationContext()

  return (
    <div onClick={openSidebarMenu} style={{ cursor: 'pointer', marginRight: '12px' }}>
      <SVG xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24" fill="none" isOpen={sidebarMenuIsOpen}>
        <path d="M0 12C0 10.8954 0.895431 10 2 10H28C29.1046 10 30 10.8954 30 12C30 13.1046 29.1046 14 28 14H2C0.89543 14 0 13.1046 0 12Z" fill="#C8CFEA"/>
        <path d="M0 2C0 0.895431 0.895431 0 2 0H28C29.1046 0 30 0.895431 30 2C30 3.10457 29.1046 4 28 4H2C0.89543 4 0 3.10457 0 2Z" fill="#C8CFEA"/>
        <path d="M0 22C0 20.8954 0.895431 20 2 20H28C29.1046 20 30 20.8954 30 22C30 23.1046 29.1046 24 28 24H2C0.89543 24 0 23.1046 0 22Z" fill="#C8CFEA"/>
      </SVG>
    </div>
  )
}

export default HamburgerIcon
