import { CourseColors } from 'src/components/ui/styles'
import styled from '@emotion/styled'

interface StyleManagerProps {
  courseColors: CourseColors
}

export default styled.div<StyleManagerProps>`
  // main course colors
  --fz-course-primary: ${({ courseColors }) => courseColors.primary};
  --fz-course-secondary: ${({ courseColors }) => courseColors.secondary};
  --fz-course-tertiary: ${({ courseColors }) => courseColors.tertiary};
  --fz-course-highlight: ${({ courseColors }) => courseColors.highlight};

  // util course colors
  --fz-course-bg-light: ${({ courseColors }) => courseColors.secondary}25;


  // want to finish depreciating ↓↓↓ these ↓↓↓ in favor of css variables and normal styling
  // if we really want to use utility classes, we should use semantic names and single-purpose classes
  // that compose well together, like tailwind
  //
  // for example ↓↓↓
  // .fz-color-1 { color: var(--fz-course-primary) }
  // .fz-bg-2 { background-color: var(--fz-course-secondary) }
  // .fz-border-3 { border-color: var(--fz-course-tertiary) }
  // .fz-reg-font-size { font-size: var(--reg-font-size) }
  //
  // not completely against this, but I'm also not sure it's the best approach, since styles are often more complex
  // and need to be more dynamic than just a few colors and font sizes, in which case we should just use styled-components
  // OR use tailwind along with our custom utility classes

  .fz-dark-font {
    color: var(--blue-gray) !important;
    font-size: var(--reg-font-size) !important;
  }

  // should just have a play all button component that handles all this internally
  .fz-play-all {
    color: white !important;
    background-color: #28c6aa !important;

    &:focus-visible {
      outline: 2px solid #333;
    }
  }
  .fz-stop-all {
    color: white !important;
    background-color: #e83563 !important;

    &:focus-visible {
      outline: 2px solid #333;
    }
  }
`
