import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import axios from 'src/api'
import { CourseBookmark, UserStreak } from 'src/types/queries/user'
import { RootState } from 'src/configureStore'

export const useGetBookmark = () => {
  const username = useSelector((state: RootState) => state.auth.userId)

  return useQuery({
    queryKey: ['/user/bookmark', username],
    queryFn: () => axios.get('/user/bookmark').then((res): CourseBookmark => res.data.last_course)
  })
}

export const useUserStreak = () => {
  return useQuery({
    queryKey: ['/user/streak'],
    queryFn: () => axios.get('/user/streak').then((res): UserStreak => res.data.streak)
  })
}
