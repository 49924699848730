import { useRouteMatch, useLocation } from 'react-router-dom'
import { useGetBookmark } from 'src/hooks/queries/user'
import { getCourseColors } from '../ui/styles'
import useQuizProgress from './useQuizProgress'

// util

const courseNames = {
  jfz1: 'Japanese 1',
  jfz2: 'Japanese 2',
  jfz3: 'Japanese 3',
  jfz4: 'Japanese 4',
  jfz5: 'Japanese 5',
  test: 'Japanese 1',
  kanji1: 'Kanji 1',
  kanji2: 'Kanji 2',
  kanji3: 'Kanji 3',
  kansai: 'Kansai',
  jin5: 'Japanese in 5!',
  daily: 'Japanese Daily!',
  ask: 'Ask a Teacher!',
  hitokoto: 'Hitokoto',
  jtm: 'Japanese Topics Mania!',
  japanese1: 'Japanese 1',
  japanese2: 'Japanese 2',
  japanese3: 'Japanese 3',
  japanese4: 'Japanese 4',
  japanese5: 'Japanese 5',
  kfz1: 'Korean 1',
  kfz2: 'Korean 2',
  kfz3: 'Korean 3',
  korean1: 'Korean 1',
  korean2: 'Korean 2',
  korean3: 'Korean 3',
  spanish1: 'Spanish 1',
}

const courseNamesShort = {
  jfz1: 'JP 1',
  jfz2: 'JP 2',
  jfz3: 'JP 3',
  jfz4: 'JP 4',
  jfz5: 'JP 5',
  test: 'JFZ1',
  kanji1: 'Kanji 1',
  kanji2: 'Kanji 2',
  kanji3: 'Kanji 3',
  kansai: 'Kansai',
  jin5: 'JP in 5',
  daily: 'JP Daily',
  ask: 'AaT',
  hitokoto: '一言',
  jtm: 'JTM',
  japanese1: 'JP 1',
  japanese2: 'JP 2',
  japanese3: 'JP 3',
  japanese4: 'JP 4',
  japanese5: 'JP 5',
  kfz1: 'KR 1',
  kfz2: 'KR 2',
  kfz3: 'KR 3',
  korean1: 'KR 1',
  korean2: 'KR 2',
  korean3: 'KR 3',
  spanish1: 'ES 1',
}

// hook

export type CourseIdentifier = keyof typeof courseNames

// shouldn't call this hook directly
// it's data is exposed in the useNavigationContext hook
const useCourseInfo = () => {
  const { data: courseBookmark } = useGetBookmark()
  const courseOverviewMatch = useRouteMatch('/courses/:id') as { params: { id: CourseIdentifier } } | null
  const isCourseLesson = !!useRouteMatch('/courses/:id/:lid')
  const { pathname } = useLocation()

  const isCoursePath = !!courseOverviewMatch
  const courseIdentifier = isCoursePath ? courseOverviewMatch.params.id : courseBookmark?.id as CourseIdentifier ?? 'jfz1' // used to determine colors
  const courseColors = ['/korean'].includes(pathname) ? getCourseColors('jfz1') : getCourseColors(courseIdentifier)
  const { currentQuizProgress } = useQuizProgress(courseIdentifier)

  return {
    isCourseLesson,
    courseColors,
    courseTitle: courseNames[courseIdentifier],
    courseTitleShort: courseNamesShort[courseIdentifier],
    courseIdentifier,
    courseBookmark,
    currentQuizProgress,
    isKorean: courseIdentifier.startsWith('kfz') || courseIdentifier.startsWith('korean'),
  }
}

export default useCourseInfo
