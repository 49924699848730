import useNavigationContext from 'src/hooks/context/useNavigationContext'
import useAvatar from '../../useAvatar'

const Avatar = () => {
  // const useAvatarQuery = () => useQuery({
  //   queryKey: ['/user/signed_avatar'],
  //   queryFn: () => axios.get('/user/signed_avatar').then((res) => res.data)
  // })
  //
  // const { data: avatarData } = useAvatarQuery()
  // const { url, default_avatar } = avatarData || {}
  // const avatar = url || defaultAvatar(default_avatar?.color, default_avatar?.version).icon

  const { openDropdownMenu } = useNavigationContext()
  const { userAvatar, handleAvatarSrc } = useAvatar()

  return (
    <button onClick={openDropdownMenu} style={{ backgroundColor: 'transparent' }}>
      <img
        src={userAvatar}
        alt='avatar'
        onError={() => handleAvatarSrc()}
        onErrorCapture={() => handleAvatarSrc()}
        style={{ height: '50px', width: '50px', borderRadius: '50%', maxWidth: 'unset' }}
      />
    </button>
  )
}

export default Avatar
