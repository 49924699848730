import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { UnstyledLink } from 'src/components/ui/styledComponents'

const mobileNavStyles = css`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 8px 20px;
  justify-content: center;
  border-top: 1px solid #F1F1F1;
  border-radius: 24px 24px 0 0;
  z-index: 29; // needs to be smaller than navigation wrapper

  @media (max-width: 500px) {
    gap: 8px;
    justify-content: space-between; // for consisent positioning for nav buttons on phones so ppl can be on phone allll day
  }
`

export const CourseNavWrapper = styled.div<{ isMobileNav: boolean }>`
  display: flex;
  gap: 16px;
  align-items: center;

  ${({ isMobileNav }) => isMobileNav && mobileNavStyles}
`

export const CircleButton = styled.button<{ isMobileNav: boolean }>`
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  display: grid;
  place-content: center;
  place-items: center;
  background: '#F5F8FB';
  border: 1px solid white;
  transition: background 0.1s;

  & svg path {
    fill: var(--fz-course-primary);
  }

  &:hover:not(:disabled) {
    background: ${({ isMobileNav }) => !isMobileNav && 'var(--fz-course-primary)'};
  }

  // chevron icon
  &:hover:not(:disabled) svg path {
    fill: ${({ isMobileNav }) => !isMobileNav && 'white'};
  }

  &:disabled {
    background: #F5F8FB;
    cursor: default;

    & svg path {
      fill: #D9D9D9;
    }
  }
`

export const Breadcrumbs = styled.div<{ isMobileNav: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--fz-course-primary);
  background: #F5F8FB;
  padding: 4px 4px 4px 16px;
  border-radius: 24px;
  font-size: 1.1rem;
  font-family: 'Tilt Warp';
  min-width: 0;
`

const currentCrumbStyles = css`
  color: white;
  background: var(--fz-course-primary);
  border-radius: 24px;
  padding: 6px 16px;

  &:hover {
    color: white;
    cursor: default;
  }
`

export const CrumbLink = styled(UnstyledLink)<{ active: boolean }>`
  ${({ active }) => active && currentCrumbStyles}
  flex-shrink: 0;

  &:hover {
    color: ${({ active }) => !active && 'var(--fz-course-primary)'};
  }
`

export const SublessonCrumb = styled.p<{ isMobileNav: boolean }>`
  ${currentCrumbStyles}
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  // prevent long sublesson titles from making center nav too wide on desktop
  // guesstimating 25vw is a good max based on testing, really no need for it to be this wide ever anyway
  ${({ isMobileNav }) => !isMobileNav && 'max-width: 25vw'};

  // this ones to account for the admin/affilate badges on left nav at smaller sizes
  // makes sure center nav stays centered if titles are long
  @media (max-width: 1100px) {
    ${({ isMobileNav }) => !isMobileNav && 'max-width: 15vw'};
  }
`
