import { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Icons from './center_nav/Icons' // center nav - now unused
import DropdownIcons from './dropdown_menu/Menu/DropdownIcons'
import useNavigationContext from 'src/hooks/context/useNavigationContext'

// parentIsHovered is used for dropdown icons since they need to be in hovered state when the text is hovered too
const NavIcon = ({ item, dropdown = false, parentIsHovered = false }) => {
  const [isHovered, setIsHovered] = useState(false)
  const { slug } = useNavigationContext()
  const { pathname } = useLocation()

  const { href, key } = item
  const Icon = DropdownIcons[key]
  const active = slug === href.split('/')[1] && pathname.split('/').length === 2

  // Center nav icons
  const fill = isHovered || active ? 'var(--fz-course-primary)' : '#F5F8FB'
  const iColor = isHovered || active ? 'white' : '#9DA7C3'

  // Dropdown icons
  const dColor = isHovered || parentIsHovered || active ? 'var(--fz-course-tertiary)' : 'white'

  // general attributes
  const color = dColor
  const size = 39
  const viewBox = '0 0 39 39'
  const style = dropdown ? { width: '21px', height: '21px' } : { width: '25px', height: '25px' }
  const adjustments = { position: 'relative', top: 'unset' } as React.CSSProperties // no idea why I need this, ts is being weird

  // dropdown icon adjustments for inconsistent bounding boxes
  if (key === 'Ask') adjustments.top = '2px'
  if (key === 'Games') adjustments.top = '-2px'
  if (key === 'Vault') adjustments.top = '2px'
  if (key === 'Shop') adjustments.top = '-1px'
  if (key === 'Courses') adjustments.right = '-3px'

  // prevent sticking hover state on mobile
  useEffect(() => {
    setIsHovered(false)
  }, [pathname])

  return (
    <NavLink
      key={item.name}
      to={item.href}
      title={item.name}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={adjustments}
    >
      <svg width={size} height={size} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        {/* {!dropdown && <circle cx="36" cy="36" r="36" fill={fill} />} */}
        <Icon color={color} />
      </svg>
    </NavLink>
  )
}

export default NavIcon
