import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Bowser from 'bowser'
import { useViewportSize } from '@mantine/hooks'
import { Alert, Text } from '@mantine/core'

import axios from '../../api'

import useMobile from '../../hooks/useMobile'
import useSubscribed from 'src/hooks/useSubscribed'
import useAPI from 'src/hooks/useAPI'
import useStaffRoles from 'src/hooks/useStaffRoles'

import {
  Button,
  Checkbox,
  Form,
  Icon,
  Input,
  Label,
  Modal,
  Select,
  TextArea
} from 'semantic-ui-react'
import styled from 'styled-components'
import FZExclamation from '../FZExclamation'
import AskATeacherQuestionForm from '../pages/ask_a_teacher/views/ask/AskATeacherQuestionForm'

import { emailPattern } from 'src/util/commonRegexPatterns'
import { CLOUDFRONT_BASE } from 'src/util/constants'

const chibi = `${CLOUDFRONT_BASE}/images/modals/error_report/tomoko_welcome.png`

const BugReportHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom: 3px solid var(--jfz-blue);
  align-items: end;
  margin: 0 21px;
`

const ChibiContainer = styled.div`
  height: ${({ isMobile }) => isMobile ? 50 : 100}px;
  width: 150px;
  position: relative;
`

const Chibi = styled.img`
  transform: scaleX(-1);
  height: 250px;
  position: absolute;
  clip-path: inset(0 0 73px 0);
  bottom: -73px;
`

// top-left absolute positioned button within the modal
const GoToFlowButton = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px !important;
  z-index: 1000;
`

const AlertText = styled(Text)`
  font-family: 'Milliard Bold' !important;
`

const containsKorean = (str) => {
  return str.match(/[\u1100-\u11FF]/)
    || str.match(/[\u3130-\u318F]/)
    || str.match(/[\uA960-\uA97F]/)
    || str.match(/[\uAC00-\uD7AF]/)
    || str.match(/[\uD7B0-\uD7FF]/)
    || str.match(/[\uFF00-\uFFEF]/)
}

const languageOptions = [
  { key: 'jp', text: 'Japanese', value: 'jp' },
  { key: 'kr', text: 'Korean', value: 'kr' },
  // { key: 'es', text: 'Spanish', value: 'es' },
  // { key: 'zh', text: 'Chinese', value: 'zh' },
  // { key: 'de', text: 'German', value: 'de' },
  // { key: 'fr', text: 'French', value: 'fr' },
  // { key: 'it', text: 'Italian', value: 'it' },
]
const ErrorReportModal = (props) => {
  // console.log('widget type', props.widgetType, 'part', props.part)
  const [values, setValues] = useState({
    form: '',
    type: '',
    title: '',
    language: 'jp'
  })
  const [offlineUserEmail, setOfflineUserEmail] = useState('')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [consentToContact, setConsentToContact] = useState(true)
  const [visibility, setVisibility] = useState('private')
  const [submittedSuccess, setSubmittedSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const userHistory = useSelector((state) => state.user?.history)
  const isSignedIn = useSelector((state) => state.auth?.isSignedIn)
  const history = useHistory()
  const location = useLocation()
  const isMobile = useMobile()
  const [widgetId, setWidgetId] = useState(props.widgetId)
  const [widgetType, setWidgetType] = useState(props.widgetType)
  const [resIcon, loadingIcon, reqIcon] = useAPI(`/widgets/${widgetId}/no_report_icon`, 'LAZYGET')
  const { course, lesson, sub, part } = useParams()
  const { isPremiumSubscribed, isAdmin } = useSubscribed()
  const [isStaff] = useStaffRoles()
  const { width } = useViewportSize()
  const [koreanDisclaimer, setKoreanDisclaimer] = useState(false)
  
  const widgetIdPath = `/courses/${course}/${lesson}/${sub}/${widgetId}`
  const soundIdPath = `${location.pathname}?soundID=${props?.record?._id}`

  const flowPath = `/courses/${course}/${lesson}/${sub}/${props.part}/${widgetId}/flow`

  const baseTypeOptions = [
    { key: 'ask-a-teacher', text: 'Ask-a-Teacher', value: 'ask-a-teacher' },
    { key: 'bug/typo', text: 'Bug/Typo', value: 'bug/typo' },
    { key: 'account/billing', text: 'Account/Billing', value: 'account/billing' },
    { key: 'other', text: 'Other', value: 'other' },
  ]

  const typeOptions = isPremiumSubscribed || isAdmin ? baseTypeOptions : baseTypeOptions.filter((o) => o.key !== 'ask-a-teacher')

  useEffect(() => {
    if (widgetType) {
      reqIcon()
    }
  }, [widgetType])

  // watch for changes in form's "form" field, check if korean regex, set koreanDisclaimer to true else false
  useEffect(() => {
    if (values.type === 'ask-a-teacher' && (containsKorean(values.form) || containsKorean(values.title))) {
      setKoreanDisclaimer(true)
    } else {
      setKoreanDisclaimer(false)
    }
  }, [values.form, values.type, values.title])

  // 'bug' | 'ask'
  const [reportType, setReportType] = useState('bug')

  const handleChange = (e) => {
    // console.log('e name: value --', e.target.name, ':', e.target.value)
    setValues((p) => ({
      ...p,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSubjectChange = (e) => {
    setReportType(e.target.value === 'ask-a-teacher' ? 'aat' : 'bug')
    handleChange(e)
  }

  const handleVisibility = () => {
    if (visibility === 'hidden') return

    let _vis = visibility
    if (visibility === 'private') _vis = 'public'
    else _vis = 'private'

    setVisibility(_vis)
  }

  const handleSubmitted = (err) => {
    setSubmittedSuccess(true)
    let timeoutLength = 4000

    setValues({ form: '', type: '', title: '' })
    setLoading(false)

    if (err) {
      console.error(err)
      setSubmitError(true)
      timeoutLength = 6000
    }

    setTimeout(() => {
      // setOpen(false)
      setSubmittedSuccess(true)
      props?.setClosed?.()
      if (props?.forceOpen) {
        props?.setClosed()
        history.goBack()
      }
    }, timeoutLength)
  }

  const submitDisabled = useMemo(() => {
    if (loading) return true
    if (!values.form.trim().length) return true
    if (!isSignedIn) {
      if (!offlineUserEmail.trim().length) return true
      // @example.com is an email server/domain meant for testing, we don't want that showing up
      if (offlineUserEmail.includes('@example')) return true
      if (!emailPattern.test(offlineUserEmail)) return true
    }
    if (reportType === 'aat' && !values.title.trim().length) return true

    return false
  }, [loading, values.form, values.type, values.title, offlineUserEmail])

  const handleSubmit = async () => {
    if (!values.form.trim().length) return

    setLoading(true)

    const nav = window.navigator
    const browser = Bowser.getParser(window.navigator.userAgent).parsedResult

    let path = location.pathname

    if (location.search) path = `${location.pathname}${location.search}`
    if (widgetId) path = widgetIdPath
    if (props?.record) path = soundIdPath

    const metadata = {
      browser: browser.browser,
      engine: browser.engine,
      os: browser.os,
      platform: browser.platform.type,
      connection: nav?.connection?.effectiveType,
      cookiesEnabled: nav.cookieEnabled,
      installedLanguages: nav.languages,
      activeLanguage: nav.language
    }

    const body = values.form.trim()
    if (props?.record) {
      metadata.autofill = `${body}\n\n(Autofilled on submit)\nAssociated Record ID: ${props.record?._id}\n\nFull Record: ${JSON.stringify(props.record, null, 2)}\n\n`
    }

    if (reportType === 'aat') {
      axios.post('/questions', { title: values.title, body, widgetId, path, metadata, language: values.language })
        .then((res) => handleSubmitted(null))
        .catch((err) => handleSubmitted(err))
    } else {
      // handleSubmitted(null)
      await axios
        .post('/bugs', {
          body,
          bugType: values.type,
          consentToContact,
          location: path,
          metadata,
          userHistory: userHistory || undefined,
          visibility: visibility === 'public' ? 'pending' : visibility,
          offlineUserEmail,
          record: props?.record,
          bug_body: values.form.trim(),
          widgetId: widgetId || props?.record?._id,
          widgetType,
        })
        .then((res) => {
          if (!res.data.success) return handleSubmitted(res.data.message)
          handleSubmitted(null)
        })
        .catch((err) => handleSubmitted(err))
    }
  }

  if (widgetType && loadingIcon) return null

  if (resIcon && resIcon.no_report_icon) return null

  return (
    <>
      <Modal
        open={!!(open || props.forceOpen)}
        trigger={props.trigger}
        onClose={
          props?.setClosed
            ? () => props.setClosed()
            : () => setOpen(false)
        }
        onOpen={() => {
          setOpen(true)
          setSubmittedSuccess(false)
        }}
        style={{ fontFamily: '"Milliard Book", sans-serif' }}
      >
        <BugReportHeader isMobile={isMobile}>
          {/* flow button should open new tab */}
          {isStaff && (
            <GoToFlowButton
              onClick={() => window.open(flowPath, '_blank')}
              style={{
                backgroundColor: 'var(--jfz-blue)',
                color: 'white'
              }}
            >
              Flow
            </GoToFlowButton>
          )}
          <FZExclamation title={submittedSuccess ? 'Successfully Submitted!' : 'How Can We Help?'} color="blue" noExclam style={{ margin: '-15px' }} />
          <ChibiContainer isMobile={isMobile}>
            {!isMobile && (
              <Chibi src={chibi} className='no-select' draggable='false' />
            )}
          </ChibiContainer>
        </BugReportHeader>
        {submittedSuccess && !submitError && (
          <>
            <Modal.Content>
              <Modal.Description>
              We&apos;ve received your submission, and we&apos;ll be in contact with you soon.
              If you submitted a lesson related question, your question will become visible in the Ask-a-Teacher section after it has been answered.
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                onClick={() => {
                  setOpen(false)
                  props.setClosed?.()
                }}
                style={{
                  backgroundColor: 'var(--jfz-blue)',
                  color: 'white'
                }}
              >
                  OK
              </Button>
            </Modal.Actions>
          </>
        )}
        {submittedSuccess && submitError && (
          <>
            <Modal.Header>Oops! :(</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                It seems like there was an error on our end; very sorry for the
                inconvenience! Please reach out to{' '}
                <a href="mailto:support@fromzero.com">support@fromzero.com</a>{' '}
                directly with the details of your bug!
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setOpen(false)}>OK</Button>
            </Modal.Actions>
          </>
        )}
        {!submittedSuccess && (
          <>
            <Modal.Content>
              <Form onSubmit={handleSubmit}>
                <Form.Field
                  name='type'
                  control={Select}
                  label='Subject'
                  onChange={(_, { value, name }) => handleSubjectChange({ target: { value, name } })}
                  value={values.type}
                  placeholder="Choose an option..."
                  options={typeOptions}
                />
                {reportType === 'aat' && (
                  // flag option select for jp and kr for now
                  <Form.Field
                    name='language'
                    control={Select}
                    label='Topic Language'
                    onChange={(_, { value, name }) => handleChange({ target: { value, name } })}
                    value={values.language}
                    placeholder="Choose an option..."
                    options={languageOptions}
                  />
                )}
                {/* disclaimer if course contains "kfz" or "korean" */}
                {((reportType === 'aat' && (values.language === 'kr' || (location?.pathname.toLowerCase().includes('kfz') || location?.pathname.toLowerCase().includes('korean')))) || koreanDisclaimer) && (
                  <Alert color='red' style={{ marginBottom: '12px' }}>
                    <AlertText style={{ fontFamily: 'Milliard Bold' }}>
                      Please note that while <i>Korean From Zero!</i> courses are in beta,
                      they do not officially have <i>Ask-a-Teacher!</i> support.
                    </AlertText>
                  </Alert>
                )}
                {values.title.length === 120 && (<p style={{ color: 'red' }}>120 character limit</p>)}
                {reportType === 'aat' && (
                  <Form.Input
                    name="title"
                    label="Question Summary"
                    onChange={(_, { value, name }) => handleChange({ target: { value, name } })}
                    value={values.title}
                    maxLength={120}
                    style={{ backgroundColor: '#ebf0fa' }}
                  />
                )}
                {values.type && (
                  <Form.Field>
                    <label>
                      {reportType === 'aat'
                        ? 'Question Details'
                        : 'Please Describe:'
                      }
                    </label>
                    <TextArea
                      name="form"
                      onChange={(e) => handleChange(e)}
                      value={values.form}
                    />
                  </Form.Field>
                )}
                {reportType === 'aat' && (
                  <div style={{ display: 'grid', placeContent: 'center', fontWeight: 'bold' }}>
                    <p>This service is for asking questions related to lesson content.{!(width > 450) && ' Please do not submit proofreading and translation requests.'}</p>
                    {width > 450 && <p>Please do not submit proofreading and translation requests.</p>}
                  </div>
                )}
                <Form.Field>
                  {isSignedIn ? (
                    <></>
                  ) : (
                    <>
                      <label htmlFor='offline_email'>Email Address:&nbsp;</label>
                      <Input
                        type='email'
                        id='offline_email'
                        required
                        value={offlineUserEmail}
                        onChange={(e) => setOfflineUserEmail(e.target.value)}
                      />
                    </>
                  )}
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="green"
                disabled={submitDisabled}
                onClick={handleSubmit}
                type="submit"
              >
                <Icon name="checkmark" /> Submit
              </Button>
            </Modal.Actions>
          </>
        )}
      </Modal>
    </>
  )
}

export default ErrorReportModal
