import styled from 'styled-components'
import { motion } from 'framer-motion'

interface StudyModeProps {
  inStudyMode: boolean
}

export const StudyModeOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StudyModeButton = styled.button<StudyModeProps>`
  background-color: ${({ inStudyMode }) => inStudyMode ? 'var(--fz-course-tertiary)' : '#47DBC3'};
  padding: 12px 20px;
  border-radius: 24px;
  box-shadow: 0 3px rgba(0, 0, 0, 0.07);

  &:hover {
    filter: saturate(1.3);
  }
`

export const LangSelector = styled.div<StudyModeProps>`
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    font-size: 1.2rem;
    font-weight: bold;
    color: ${({ inStudyMode }) => inStudyMode ? 'white' : 'var(--fz-course-secondary)'};
  }
`

export const Toggle = styled(motion.input)<StudyModeProps>`
  position: relative;
  appearance: none;
  display: flex;
  align-items: center;
  height: 25px;
  width: 45px;
  padding: 4px;
  border-radius: 20px;
  border: 2px solid ${({ inStudyMode }) => inStudyMode ? 'white' : 'var(--fz-course-secondary)'};
  background-color: transparent;
  cursor: ${({ inStudyMode }) => inStudyMode ? 'pointer' : 'default'};

  &::before {
    content: '';
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background-color: ${({ inStudyMode }) => inStudyMode ? 'white' : 'var(--fz-course-secondary)'};
  }

  &:checked {
    justify-content: flex-end;
  }
`

export const LangOption = styled.p<{ active: boolean }>`
`
