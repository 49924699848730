import React, { useEffect, useState } from 'react'
import axios from '../../../api'
import { createPortal } from 'react-dom'
// import { useSelector } from 'react-redux'

import { Button, Divider, Form, Icon, Input, Header, Label, Message } from 'semantic-ui-react'
import { FZButton } from 'src/assets/styles/globalStyles'
import { AdminButtonGroup, AdminFields, AdminFieldView, AdminView, AlignedRight, KeyRing } from './SoundPopupStyles'

import SoundRecorder from '../admin/editor/SoundRecorder'
import SoundUploader from '../admin/editor/SoundUploader'

import useAPI from 'src/hooks/useAPI'
import usePortal from '../../../hooks/usePortal'
import { TagDot, TagDotContainer, TagRing, TagText } from 'src/components/pages/ask_a_teacher/views/open_bugs/styles/Tag.styles'

// i'm getting tired of the admin buttons being ugly and making the popup even uglier - germ
const AdminFieldsPortal = ({ children }) => {
  const portalTarget = usePortal('admin-fields-portal')
  return createPortal(children, portalTarget)
}

export const BreakdownEditor = (props) => {
  const [breakdown, setBreakdown] = useState(props.record?.breakdown || props.breakdown || [])
  const [selectedItem, setSelectedItem] = useState(0)
  const [loading, setLoading] = useState(false)

  const { widget } = props

  const setBreakdownKey = (key, val) => {
    const newBreakdown = [...breakdown]
    breakdown[selectedItem][key] = val
    setBreakdown(newBreakdown)
  }

  const removeBreakdownItem = (index) => {
    const newBreakdown = [...breakdown]
    newBreakdown.splice(index, 1)
    setBreakdown(newBreakdown)
    if (selectedItem >= index) {
      setSelectedItem(selectedItem - 1)
    }
  }

  const addBreakdownItem = () => {
    const newBreakdown = [...breakdown]
    newBreakdown.push({
      basic_form: '',
      conjugated_form: '',
      pronunciation: '',
      conjugated_type: '',
      pos: '',
      pos_detail_1: '',
      pos_detail_2: '',
      pos_detail_3: '',
      reading: '',
      surface_form: ''
    })
    setBreakdown(newBreakdown)
  }

  const save = (e) => {
    e.stopPropagation()
    setLoading(true)

    axios
      .put(`/lang_records/${props.record._id}/breakdown`, { breakdown, widget })
      .then(() => setLoading(false))
      .catch((err) => console.error(err))
  }

  const selectItem = (e, idx) => {
    e.stopPropagation()
    setSelectedItem(idx)
  }

  // -1 = move to the left, 1 = move to the right
  const moveSelectedItem = (direction) => {
    const newBreakdown = [...breakdown]
    const [item] = newBreakdown.splice(selectedItem, 1)
    newBreakdown.splice(selectedItem + direction, 0, item)
    setBreakdown(newBreakdown)
    setSelectedItem(selectedItem + direction)
  }

  return (
    <AdminView>
      <h3>Breakdown Editor</h3>
      {breakdown.map((p, idx) => (
        <Label
          key={`breakdown-${idx}`}
          color={idx === selectedItem ? 'blue' : 'white'}
          style={{ cursor: 'pointer' }}
          onClick={(e) => selectItem(e, idx)}
        >
          {p.surface_form}
          <Icon fitted name='close' onClick={() => removeBreakdownItem(idx)} />
        </Label>
      ))}
      {/* Adds a new breakdown item */}
      <Button
        color='orange'
        icon='plus'
        onClick={addBreakdownItem}
        compact
      />
      <Form loading={loading}>
        <Form.Group>
          <Form.Input
            label='Basic Form'
            value={breakdown[selectedItem]?.basic_form}
            onChange={(_, { value }) => setBreakdownKey('basic_form', value)}
            onFocus={() => props.setNoDrag(true)}
            onPointerDown={() => props.setNoDrag(true)}
            onMouseLeave={() => props.handleMouseLeave()}
            onBlur={() => props.handleMouseLeave()}
          />
          <Form.Input
            label='Conjugated Form'
            value={breakdown[selectedItem]?.conjugated_form}
            onChange={(_, { value }) => setBreakdownKey('conjugated_form', value)}
            onFocus={() => props.setNoDrag(true)}
            onPointerDown={() => props.setNoDrag(true)}
            onMouseLeave={() => props.handleMouseLeave()}
            onBlur={() => props.handleMouseLeave()}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            label='Conjugated Type'
            value={breakdown[selectedItem]?.conjugated_type}
            onChange={(_, { value }) => setBreakdownKey('conjugated_type', value)}
            onFocus={() => props.setNoDrag(true)}
            onPointerDown={() => props.setNoDrag(true)}
            onMouseLeave={() => props.handleMouseLeave()}
            onBlur={() => props.handleMouseLeave()}
          />
          <Form.Input
            label='Pos'
            value={breakdown[selectedItem]?.pos}
            onChange={(_, { value }) => setBreakdownKey('pos', value)}
            onFocus={() => props.setNoDrag(true)}
            onPointerDown={() => props.setNoDrag(true)}
            onMouseLeave={() => props.handleMouseLeave()}
            onBlur={() => props.handleMouseLeave()}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            label='Pos Detail 1'
            value={breakdown[selectedItem]?.pos_detail_1}
            onChange={(_, { value }) => setBreakdownKey('pos_detail_1', value)}
            onFocus={() => props.setNoDrag(true)}
            onPointerDown={() => props.setNoDrag(true)}
            onMouseLeave={() => props.handleMouseLeave()}
            onBlur={() => props.handleMouseLeave()}
          />
          <Form.Input
            label='Pos Detail 2'
            value={breakdown[selectedItem]?.pos_detail_2}
            onChange={(_, { value }) => setBreakdownKey('pos_detail_2', value)}
            onFocus={() => props.setNoDrag(true)}
            onPointerDown={() => props.setNoDrag(true)}
            onMouseLeave={() => props.handleMouseLeave()}
            onBlur={() => props.handleMouseLeave()}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            label='Pos Detail 3'
            value={breakdown[selectedItem]?.pos_detail_3}
            onChange={(_, { value }) => setBreakdownKey('pos_detail_3', value)}
            onFocus={() => props.setNoDrag(true)}
            onPointerDown={() => props.setNoDrag(true)}
            onMouseLeave={() => props.handleMouseLeave()}
            onBlur={() => props.handleMouseLeave()}
          />
          <Form.Input
            label='Pronunciation'
            value={breakdown[selectedItem]?.pronunciation}
            onChange={(_, { value }) => setBreakdownKey('pronunciation', value)}
            onFocus={() => props.setNoDrag(true)}
            onPointerDown={() => props.setNoDrag(true)}
            onMouseLeave={() => props.handleMouseLeave()}
            onBlur={() => props.handleMouseLeave()}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            label='Reading'
            value={breakdown[selectedItem]?.reading}
            onChange={(_, { value }) => setBreakdownKey('reading', value)}
            onFocus={() => props.setNoDrag(true)}
            onPointerDown={() => props.setNoDrag(true)}
            onMouseLeave={() => props.handleMouseLeave()}
            onBlur={() => props.handleMouseLeave()}
          />
          <Form.Input
            label='Surface Form'
            value={breakdown[selectedItem]?.surface_form}
            onChange={(_, { value }) => setBreakdownKey('surface_form', value)}
            onFocus={() => props.setNoDrag(true)}
            onPointerDown={() => props.setNoDrag(true)}
            onMouseLeave={() => props.handleMouseLeave()}
            onBlur={() => props.handleMouseLeave()}
          />
        </Form.Group>
        <div className='tw-flex tw-items-stretch'>
          {/* Buttons to move this selected breakdown item left or right */}
          <Button
            onClick={() => moveSelectedItem(-1)}
            color='blue'
            icon='arrow left'
            title='Move breakdown for selected word/particle left'
          />
          <Button
            onClick={() => moveSelectedItem(1)}
            color='blue'
            icon='arrow right'
            title='Move breakdown for selected word/particle right'
          />
          <AlignedRight>
            <Button onClick={save} color='green' icon='save' title='Save changes' />
          </AlignedRight>
        </div>
      </Form>
    </AdminView>
  )
}

const genderOptions = [
  { key: 'm', text: 'Male', value: 'm' },
  { key: 'f', text: 'Female', value: 'f' },
  { key: 'None', text: 'None', value: '' }
]

const kanaOptions = [
  { key: 'romaji', text: 'Romaji', value: 'romaji' },
  { key: 'kana', text: 'Kana', value: 'kana' },
  { key: 'kanji', text: 'Kanji', value: 'kanji' },
  { key: 'None', text: 'None', value: '' }
]

const speakerOptions = [
  { key: 'kanako', text: 'Kanako', value: 'Kanako' },
  { key: 'hikari', text: 'Hikari', value: 'Hikari' },
  { key: 'Kairo', text: 'Kairo', value: 'Kairo' },
  { key: 'None', text: 'None', value: '' }
]

const RecordBonusEditor = (props) => {
  const [forceCapital, setForceCapital] = useState(false)
  const [forceLowercase, setForceLowercase] = useState(false)
  const [forceDisplay, setForceDisplay] = useState('')
  const [forceGender, setForceGender] = useState('')
  const [forceSpeaker, setForceSpeaker] = useState('')
  const [noSound, setNoSound] = useState(false)
  const [noInjection, setNoInjection] = useState(false)
  const [noFuri, setNoFuri] = useState(false)
  const [particleOverride, setParticleOverride] = useState(false)
  const [kanaOverride, setKanaOverride] = useState(false)
  const [soundIconDisplay, setSoundIconDisplay] = useState()
  const [loading, setLoading] = useState(true)

  const { base_text } = props.record
  const { path, widget } = props

  const loadBonus = () => {
    axios
      .get('/lang_records/bonus', { params: { base_text, path: widget || path } })
      .then((res) => {
        console.log(res)
        if (res.data.success && res.data.bonus) {
          const {
            force_capitalize,
            force_lowercase,
            force_gender,
            force_display,
            no_sound,
            no_injection,
            no_furi,
            force_speaker,
            sound_icon_display,
            particle_override,
            kana_override
          } = res.data.bonus
          setForceCapital(force_capitalize)
          setForceLowercase(force_lowercase)
          setForceGender(force_gender)
          setForceDisplay(force_display)
          setForceSpeaker(force_speaker)
          setNoSound(no_sound)
          setNoInjection(no_injection)
          setNoFuri(no_furi)
          setSoundIconDisplay(sound_icon_display)
          setParticleOverride(particle_override)
          setKanaOverride(kana_override)
        }
        setLoading(false)
      })
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    loadBonus()
  }, [])

  const save = () => {
    setLoading(true)

    axios
      .put('/lang_records/bonus', {
        base_text,
        path: widget || path,
        forceCapital,
        forceLowercase,
        forceDisplay,
        forceGender,
        noSound,
        noInjection,
        noFuri,
        forceSpeaker,
        soundIconDisplay,
        particleOverride,
        kanaOverride
      })
      .then(() => {
        loadBonus()
        setLoading(false)
      })
      .catch((err) => console.error(err))
  }

  return (
    <AdminView>
      <h3>Presentation Options</h3>
      <Form onSubmit={save} loading={loading}>
        <Form.Checkbox
          label='Force Capitalize'
          onChange={() => setForceCapital(!forceCapital)}
          onFocus={() => props.setNoDrag(true)}
          onPointerDown={() => props.setNoDrag(true)}
          onMouseLeave={() => props.handleMouseLeave()}
          onBlur={() => props.handleMouseLeave()}
          checked={forceCapital}
        />
        <Form.Checkbox
          label='Force Lowercase'
          onChange={() => setForceLowercase(!forceLowercase)}
          onFocus={() => props.setNoDrag(true)}
          onPointerDown={() => props.setNoDrag(true)}
          onMouseLeave={() => props.handleMouseLeave()}
          onBlur={() => props.handleMouseLeave()}
          checked={forceLowercase}
        />
        <Form.Select
          label='Force Gender'
          onChange={(_, { value }) => setForceGender(value)}
          onFocus={() => props.setNoDrag(true)}
          onPointerDown={() => props.setNoDrag(true)}
          onMouseLeave={() => props.handleMouseLeave()}
          onBlur={() => props.handleMouseLeave()}
          value={forceGender}
          options={genderOptions}
        />
        <Form.Select
          label='Force Speaker'
          onChange={(_, { value }) => setForceSpeaker(value)}
          onFocus={() => props.setNoDrag(true)}
          onPointerDown={() => props.setNoDrag(true)}
          onMouseLeave={() => props.handleMouseLeave()}
          onBlur={() => props.handleMouseLeave()}
          value={forceSpeaker}
          options={speakerOptions}
        />
        <Form.Select
          label='Force Version'
          onChange={(_, { value }) => setForceDisplay(value)}
          onFocus={() => props.setNoDrag(true)}
          onPointerDown={() => props.setNoDrag(true)}
          onMouseLeave={() => props.handleMouseLeave()}
          onBlur={() => props.handleMouseLeave()}
          value={forceDisplay}
          options={kanaOptions}
        />
        <h4 style={{ margin: '0 0 7px 0', padding: 0 }}>Sound Icon Options</h4>
        <Form.Group inline>
          <Form.Radio
            label='No Icon'
            value='no icon'
            checked={soundIconDisplay === 'no icon'}
            onChange={(_, { value }) => setSoundIconDisplay(value)}
          />
          <Form.Radio
            label='Icon Only'
            value='icon only'
            checked={soundIconDisplay === 'icon only'}
            onChange={(_, { value }) => setSoundIconDisplay(value)}
          />
          <Form.Radio
            label='Default'
            value='default'
            checked={soundIconDisplay === 'default'}
            onChange={(_, { value }) => setSoundIconDisplay(value)}
          />
        </Form.Group>
        {/* <Form.Checkbox
          label='No Sound'
          onChange={() => setNoSound(!noSound)}
          onFocus={() => props.setNoDrag(true)}
          onPointerDown={() => props.setNoDrag(true)}
          onMouseLeave={() => props.handleMouseLeave()}
          onBlur={() => props.handleMouseLeave()}
          checked={noSound}
        /> */}
        <Form.Checkbox
          label='No Injection'
          onChange={() => setNoInjection(!noInjection)}
          onFocus={() => props.setNoDrag(true)}
          onPointerDown={() => props.setNoDrag(true)}
          onMouseLeave={() => props.handleMouseLeave()}
          onBlur={() => props.handleMouseLeave()}
          checked={noInjection}
        />
        <Form.Checkbox
          label='No Furi'
          onChange={() => setNoFuri((p) => !p)}
          onFocus={() => props.setNoDrag(true)}
          onPointerDown={() => props.setNoDrag(true)}
          onMouseLeave={() => props.handleMouseLeave()}
          onBlur={() => props.handleMouseLeave()}
          checked={noFuri}
        />
        <Form.Checkbox
          label='Particle override (は・へ・を)'
          onChange={() => setParticleOverride((p) => !p)}
          onFocus={() => props.setNoDrag(true)}
          onPointerDown={() => props.setNoDrag(true)}
          onMouseLeave={() => props.handleMouseLeave()}
          onBlur={() => props.handleMouseLeave()}
          checked={particleOverride}
        />
        <Form.Checkbox
          label='Kana override (e.g., は = ha)'
          onChange={() => setKanaOverride((p) => !p)}
          onFocus={() => props.setNoDrag(true)}
          onPointerDown={() => props.setNoDrag(true)}
          onMouseLeave={() => props.handleMouseLeave()}
          onBlur={() => props.handleMouseLeave()}
          checked={kanaOverride}
        />
        <Button icon='save' color='green' />
      </Form>
    </AdminView>
  )
}

const CopyToClipboardButton = ({ id }) => {
  const [copied, setCopied] = useState(false)

  const copy = () => {
    navigator.clipboard.writeText(id)
    setCopied(true)
  }

  return (
    <FZButton
      backgroundColor='#2185d0'
      borderColor='#2185d0'
      onClick={copy}
    >
      Copy ID{' '}
      <Icon
        fitted
        name={copied ? 'checkmark' : 'clipboard'}
        style={{ position: 'relative', top: '-1px' }}
      />
    </FZButton>
  )
}

const AddTag = (props) => {
  const [allTags, setAllTags] = useState([])
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(true)
  const [tagText, setTagText] = useState('')
  const [selectedTag, setSelectedTag] = useState(null)
  const [, setTagOptions] = useState([])

  // console.log(props.record._id)

  // useEffect(() => {
  //     console.log(tagOptions)
  // }, [tagOptions])

  useEffect(() => {
    // console.log(tags)
  }, [tags])

  const loadAllTags = () => {
    axios
      .get('/tags')
      .then((res) => {
        if (res.data.success) {
          setAllTags(res.data.tags)
          setTagOptions(
            res.data.tags.map((t) => {
              return { text: t.text, value: t._id, key: t.text }
            })
          )
        }
      })
      .catch((err) => console.error(err))
  }

  const loadTags = () => {
    axios
      .get(`/lang_records/${props.record._id}/tags`)
      .then((res) => {
        if (res.data.success) {
          setTags(res.data.tags)
        }
        setLoading(false)
      })
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    loadAllTags()
    loadTags()
  }, [])

  const removeTag = (tag) => {
    setLoading(true)

    axios
      .delete(`/lang_records/${props.record._id}/tag`, { data: { tag } })
      .then(() => {
        loadAllTags()
        loadTags()
        setLoading(false)
      })
      .catch((err) => console.error(err))
  }

  const createTag = () => {
    if (!tagText) return

    axios
      .post('/tags/new', { text: tagText })
      .then((res) => {
        if (res.data.success) {
          loadAllTags()
        }
      })
      .catch((err) => console.error(err))
  }

  const addTag = () => {
    if (!selectedTag) return

    axios
      .post(`/lang_records/${props.record._id}/tag`, { tag: selectedTag })
      .then(() => loadTags())
      .catch((err) => console.error(err))
  }

  return (
    <AdminView>
      <h3>Tags</h3>
      <div className='tw-divide-y tw-divide-white tw-flex tw-flex-col tw-gap-4'>
        <KeyRing role='list'>
          {tags.map((t) => (
            <li key={t._id}>
              <TagRing>
                <TagDotContainer>
                  <TagDot aria-hidden='true' dotColor={'white'} />
                </TagDotContainer>
                <TagText className='tw-text-white'>
                  {t.text}{' '}
                  <Icon name='cancel' onClick={() => removeTag(t._id)} />
                </TagText>
              </TagRing>
            </li>
          ))}
        </KeyRing>
        <Form loading={loading} onSubmit={addTag} className='tw-pt-4'>
          <div className='tw-flex tw-items-center tw-justify-between'>
            <Input
              className='tw-w-4/5'
              list='tags'
              placeholder='Choose tag...'
              value={selectedTag}
              onChange={(_, { value }) => setSelectedTag(value)}
              onFocus={() => props.setNoDrag(true)}
              onPointerDown={() => props.setNoDrag(true)}
              onMouseLeave={() => props.handleMouseLeave()}
              onBlur={() => props.handleMouseLeave()}
            />
            <datalist id='tags'>
              {allTags.map((t) => (
                <option key={t._id} value={t._id}>
                  {t.text}
                </option>
              ))}
            </datalist>
            <Button color='green' icon='save' />
          </div>
        </Form>
        <Form loading={loading} onSubmit={createTag} className='tw-pt-4'>
          <div className='tw-flex tw-items-center tw-justify-between'>
            <Input
              className='tw-w-4/5'
              placeholder='Create New Tag'
              value={tagText}
              onChange={(_, { value }) => setTagText(value)}
              onFocus={() => props.setNoDrag(true)}
              onPointerDown={() => props.setNoDrag(true)}
              onMouseLeave={() => props.handleMouseLeave()}
              onBlur={() => props.handleMouseLeave()}
            />
            <Button color='green' icon='plus' type='submit' />
          </div>
        </Form>
      </div>
    </AdminView>
  )
}

const RecordFields = (props) => {
  const {
    english: e,
    handleMouseLeave,
    note,
    noteText,
    record,
    setNote,
    setNoDrag,
    setNoteText,
    // setRecord,
    widget,
    path,
    widgetType
  } = props

  const [, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pollyOverride, setPollyOverride] = useState('')
  const [bonus, setBonus] = useState({})
  const [english, setEnglish] = useState(e?.text || e)
  const [resOR, loadingOR, reqOR] = useAPI('/sounds/override', 'LAZYGET')
  const [resMOR, loadingMOR, reqMOR] = useAPI('/sounds/mark_override', 'PUT')

  useEffect(() => {
    setEnglish(e?.text || e)
  }, [e])

  const loadBonus = () => {
    setLoading(true)
    axios
      .get('/lang_records/bonus', { params: { base_text: record.base_text, path: widget || path } })
      .then((res) => {
        if (res.data.success && res.data.bonus) {
          setPollyOverride(res.data.bonus.polly_override)
        }
        setLoading(false)
      })
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    // console.log(resMOR, record)
    reqOR({ params: { base_text: record.base_text, path: widget || path } })
  }, [resMOR])

  useEffect(() => {
    // console.log(resOR)
  }, [resOR])

  useEffect(() => {
    loadBonus()
  }, [])

  const saveRecord = () => {
    setLoading(true)

    axios
      .post('/lang_records/save', { record: { ...record, english }, english, widget })
      .then(() => {
        setSaved(true)
        setLoading(false)
      })
      .catch((err) => console.error(err))

    // either update or create new teacher note depending
    if (note?.text) {
      axios
        .put(`/lang_records/teacher_notes/${record._id}/${widget}`, { text: noteText })
        .then((res) => setNote(res.data.note))
        .catch((err) => console.error(err))
    } else if (!note?.text && noteText) {
      axios
        .post(`/lang_records/teacher_notes/${record._id}/${widget}`, { text: noteText })
        .then((res) => setNote(res.data.note))
        .catch((err) => console.error(err))
    }
  }

  const polly = () => {
    setLoading(true)

    axios
      .post('/sounds/polly', { record: record._id, widget })
      .then(() => setLoading(false))
      .catch((err) => console.error(err))
  }

  const voiceText = () => {
    setLoading(true)

    axios
      .post(`/sounds/generate_missing/${record._id}`, { kanji: false })
      .then(() => setLoading(false))
      .catch((err) => console.error(err))
  }

  const voiceTextKanji = () => {
    setLoading(true)
    axios
      .post(`/sounds/generate_missing/${record._id}`, { kanji: true })
      .then(() => setLoading(false))
      .catch((err) => console.error(err))
  }

  const submitPollyOverride = () => {
    if (!pollyOverride) return

    setLoading(true)
    axios
      .post('/sounds/polly_override', { record: record._id, widget, override: pollyOverride })
      .then(() => setLoading(false))
      .catch((err) => console.error(err))
  }

  return (
    <AdminView>
      <Form loading={loading || loadingOR || loadingMOR}>
        <Header as='h4'>
          Sound Data
          <Header.Subheader>{record.type}</Header.Subheader>
        </Header>
        <Form.Input
          label='English'
          placeholder='English'
          value={english}
          onChange={(_, { value }) => setEnglish(value)}
          onMouseLeave={() => handleMouseLeave()}
          onPointerDown={() => setNoDrag(true)}
          onBlur={() => handleMouseLeave()}
        />
        <Form.TextArea
          label='Teacher Note'
          placeholder='Teacher Note'
          value={noteText}
          onChange={(_, { value }) => setNoteText(value)}
          onMouseLeave={() => handleMouseLeave()}
          onPointerDown={() => props.setNoDrag(true)}
          onBlur={() => handleMouseLeave()}
        />
        <div className='tw-flex tw-justify-end'>
          <Button {...{ loading }} disabled={loading} color='green' onClick={saveRecord}>
            <Icon name='save' fitted />
          </Button>
        </div>
        <Divider />
        <Form.TextArea
          label='Polly (Override)'
          value={pollyOverride}
          onChange={(_, { value }) => setPollyOverride(value)}
          placeholder='Enter override text for Polly...'
        />
        <div className='tw-flex tw-justify-between tw-items-center'>
          <Button disabled={loading} color='pink' onClick={polly} title='Add Polly Voices'>
            <Icon name='twitter' />
            Polly
          </Button>
          <Button
            disabled={loading}
            color='orange'
            onClick={submitPollyOverride}
          >
            <Icon name='save' />
            Submit Override
          </Button>
        </div>
        <Divider />
        {/* checkbox for Human Override (/sounds/override useAPI call) */}
        <Form.Checkbox
          loading={loadingOR || loadingMOR}
          label='Human Override'
          checked={resOR?.override}
          onChange={(_, { checked }) => {
            reqMOR({ record: record._id, base_text: record.base_text, path: widget || path, override: checked, speaker: 'Kanako' })
          }}
        />
      </Form>
    </AdminView>
  )
}

const noteToString = (note) => {
  if (typeof note === 'string') return note

  return note?.map((n) => n.text || n).join('')
}

const AdminAccessFields = (props) => {
  const {
    english,
    handleMouseLeave,
    note,
    onSave,
    path,
    record,
    setEnglish,
    setNoDrag,
    setNote,
    setRecord,
    widget,
    widgetType
  } = props

  const views = {
    recordFields: false,
    breakdown: false,
    bonus: false,
    tags: false,
    upload: false,
  }
  const [view, setView] = useState(views)
  const [noteText, setNoteText] = useState(noteToString(props.note?.text))

  const handleView = (newView) => {
    const updatedViews = {
      ...views,
      [newView]: !view[newView],
    }

    setView(updatedViews)
  }

  return (
    <AdminFields>
      <AdminButtonGroup>
        <FZButton
          onClick={() => handleView('recordFields')}
          backgroundColor={view.recordFields ? 'gold' : '#2185d0'}
          borderColor={view.recordFields ? 'gold' : '#2185d0'}
          color={view.recordFields ? '#333' : 'white'}
        >
          Sound Data
        </FZButton>
        <FZButton
          onClick={() => handleView('breakdown')}
          backgroundColor={view.breakdown ? 'gold' : '#a333c8'}
          borderColor={view.breakdown ? 'gold' : '#a333c8'}
          color={view.breakdown ? '#333' : 'white'}
        >
          Breakdown
        </FZButton>
        <FZButton
          onClick={() => handleView('bonus')}
          backgroundColor={view.bonus ? 'gold' : '#f2711c'}
          borderColor={view.bonus ? 'gold' : '#f2711c'}
          color={view.bonus ? '#333' : 'white'}
        >
          Presentation
        </FZButton>
        <FZButton
          onClick={() => handleView('tags')}
          backgroundColor={view.tags ? 'gold' : '#e03997'}
          borderColor={view.tags ? 'gold' : '#e03997'}
          color={view.tags ? '#333' : 'white'}
        >
          Tags
        </FZButton>
        <CopyToClipboardButton id={record._id} />
        <FZButton
          onClick={() => handleView('upload')}
          backgroundColor={view.upload ? 'gold' : '#2185d0'}
          borderColor={view.upload ? 'gold' : '#2185d0'}
          color={view.upload ? '#333' : 'white'}
        >
          <Icon name='microphone' fitted />
        </FZButton>
      </AdminButtonGroup>
      <AdminFieldsPortal>
        <AdminFieldView active={Object.values(view).reduce((a, b) => a || b, false)}>
          {Object.values(view).reduce((a, b) => a || b, false) && (
            <div>
              {view['recordFields'] && (
                <RecordFields
                  english={english}
                  handleMouseLeave={handleMouseLeave}
                  note={note}
                  noteText={noteText}
                  record={record}
                  setEnglish={setEnglish}
                  setNote={setNote}
                  setNoDrag={setNoDrag}
                  setNoteText={setNoteText}
                  setRecord={setRecord}
                  widget={widget}
                  path={path}
                  widgetType={widgetType}
                />
              )}
              {view['breakdown'] && (
                <BreakdownEditor
                  record={record}
                  setNoDrag={setNoDrag}
                  handleMouseLeave={handleMouseLeave}
                  widget={widget}
                />
              )}
              {view['bonus'] && (
                <RecordBonusEditor
                  record={record}
                  path={path}
                  setNoDrag={setNoDrag}
                  handleMouseLeave={handleMouseLeave}
                  widget={widget}
                />
              )}
              {view['tags'] && (
                <AddTag
                  record={record}
                  path={path}
                  setNoDrag={setNoDrag}
                  handleMouseLeave={handleMouseLeave}
                />
              )}
              {view['upload'] && (
                <AdminView className='tw-pt-4'>
                  <h3>Upload or Record Audio</h3>
                  <SoundUploader />
                  <SoundRecorder widget={widget} record={record} onSave={onSave} />
                </AdminView>
              )}
            </div>
          )}
        </AdminFieldView>
      </AdminFieldsPortal>
      {!Object.keys(record.sounds).length > 0 && (
        <Message error>Oops! This record currently has no associated sounds!</Message>
      )}
    </AdminFields>
  )
}

export default AdminAccessFields
