import { useLocation, useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { black, bluegrey } from 'src/components/ui/styles'

const Button = styled.button`
  // how it fits in the nav
  grid-column: 3;
  justify-self: end;

  // button styles
  background: none;
  border: 1px solid ${bluegrey};
  border-radius: 24px;
  color: ${black};
  font-family: 'Tilt Warp';
  cursor: pointer;
  font-size: 1.25rem;
  padding: 8px 24px;

  &:hover {
    background: ${bluegrey}30;
  }
`

const LoginButton = () => {
  const { pathname } = useLocation()
  const history = useHistory()

  if (['/es', '/login', '/forgot_password', '/claim'].includes(pathname)
    || pathname.indexOf('/reset/') !== -1) return <></>
  
  return (
    <Button data-cy='sign-in-btn' onClick={() => history.push('/login')}>Log In</Button>
  )
}

export default LoginButton
