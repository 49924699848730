import { useHistory } from 'react-router-dom'
import { Modal } from '@mantine/core'
import SubscriptionFlow from '../pages/account/subscriptions/SubscriptionFlow'
import useNavigationContext from 'src/hooks/context/useNavigationContext'

interface JapaneseFromZeroSubModalProps {
  subscriptionModalOpen?: boolean
  setSubscriptionModalOpen?: (arg: boolean) => void
  location?: string
}

const JapaneseFromZeroSubModal: React.FC<JapaneseFromZeroSubModalProps> = ({
  subscriptionModalOpen,
  setSubscriptionModalOpen,
  location,
}) => {
  const history = useHistory()
  const { courseInfo: { courseBookmark } } = useNavigationContext()

  const handleCloseModal = () => {
    if (setSubscriptionModalOpen != null) {
      return setSubscriptionModalOpen(false)
    }
    history.goBack()
  }

  // isn't used but leaving in case we actually want to use it -Mike
  const handleSuccess = () => {
    setTimeout(() => {
      setSubscriptionModalOpen(false)

      if (subscriptionModalOpen) {
        if (courseBookmark.id) {
          history.push(`/courses/${courseBookmark.id}/${courseBookmark.lesson || ''}`)
          process.nextTick(() => true)
          history.go(0)
        } else {
          window.location.reload()
        }
      }
    }, 5000)
  }

  return (
    <Modal opened={subscriptionModalOpen} onClose={handleCloseModal} size='xl' radius='xl'>
      <SubscriptionFlow onSuccess={handleSuccess} location={location} />
    </Modal>
  )
}

export default JapaneseFromZeroSubModal
