import { useQuery } from '@tanstack/react-query'
import axios from 'src/api'
import { QuizData } from 'src/types/queries/progress'

// eslint-disable-next-line import/prefer-default-export
export const useGetCourseQuizProgress = ({ courseIdentifier }: { courseIdentifier: string }) => {
  return useQuery({
    queryKey: ['/progress/course/:id/quiz', courseIdentifier],
    queryFn: () => axios.get(`/progress/course/${courseIdentifier}/quiz`).then((res): QuizData => res.data.quizData)
  })
}
