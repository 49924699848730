import { useViewportSize } from '@mantine/hooks'
import useNavigationContext from 'src/hooks/context/useNavigationContext'
import Streak from '../right_nav/Streak'
import Avatar from '../right_nav/Avatar'
import { IoClose } from 'react-icons/io5'
import { AdminIcon, AffiliateIcon } from '../Icons'
import { MobileHeader, MobileHeaderColumn, Xbutton } from '../../styles/DropdownStyles'

const MobileDropdownHeader = () => {
  const { isAffiliate, isAdmin, closeDropdownMenu } = useNavigationContext()
  const { width } = useViewportSize()
  const showMobileHeader = width <= 500

  if (!showMobileHeader) return <></>

  return (
    <MobileHeader>
      <MobileHeaderColumn>
        {isAdmin && <AdminIcon />}
        {isAffiliate && !isAdmin && <AffiliateIcon />}
      </MobileHeaderColumn>
      <MobileHeaderColumn right>
        <Streak dropdown />
        <Avatar />
        <Xbutton onClick={closeDropdownMenu}><IoClose size={23} color='var(--fz-course-primary)' /></Xbutton>
      </MobileHeaderColumn>
    </MobileHeader>
  )
}

export default MobileDropdownHeader
