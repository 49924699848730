import React, { useEffect, useState } from 'react'
import axios from '../../../api'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { Button } from 'semantic-ui-react'

import { dispatchSetting } from '../../../actions'
import useMobile from '../../../hooks/useMobile'
import useStaffRoles from 'src/hooks/useStaffRoles'

const ProgButtonsStyleWrapper = styled.div`
  display: inline-block;

  .progessive-settings:focus {
    outline: 2px solid yellow;
  }

  .progressive-settings {
    position: relative;
  }

  .opacity-slider,
  .opacity-slider > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .progressive-settings .opacity-slider {
    display: flex;
    flex-direction: column;
    background-color: var(--sub-blue);
    border-radius: 10px;
    padding: 8px;
    margin-bottom: 10px;

    div {
      width: 100%;
    }

    aside {
      font-weight: 300;
      font-size: 0.8rem;
    }
  }
`

const SliderWrapper = styled.div`
  span,
  label {
    font-size: 1rem;
    color: var(--blue-gray);
    font-family: 'Milliard Book', 'Jost', sans-serif;
  }
  > div {
    width: ${(props) => !props.isMobile && '80%'};
    padding: 10px;
    border-radius: 5px;
  }
  input {
    margin: 0;
  }
  div label {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }
  .slider {
    -webkit-appearance: none;
    height: 25px;
    background: var(--off-white);
    outline: none;
  }
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--jfz-blue);
    cursor: pointer;
  }
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--jfz-blue);
    cursor: pointer;
  }
`

const OpacitySlider = (props) => {
  const { current, isMobile, onSlideEnd, opacity, sliderVisible } = props
  const [furiOpacity, setFuriOpacity] = useState(opacity)

  const handleSlideEnd = (val) => {
    const value = parseInt(val, 10)
    if (value !== opacity) onSlideEnd(current, value)
  }

  if (!sliderVisible) return <></>

  return (
    <SliderWrapper isMobile={isMobile} value={furiOpacity} className='opacity-slider'>
      {isMobile && (
        <>
          <label htmlFor='opacity'>
            Furigana Visibility: {furiOpacity > 9 ? `${furiOpacity}%` : 'Hover'}
          </label>
          <aside>Tap a word once to reveal furigana, twice to open the popup.</aside>
        </>
      )}
      <div style={{ background: 'var(--fz-course-tertiary)' }}>
        <input
          type='range'
          min='0'
          max='100'
          step='10'
          value={furiOpacity}
          onChange={(e) => setFuriOpacity(e.target.value)}
          onTouchEnd={(e) => handleSlideEnd(e.target.value)}
          onPointerUp={(e) => handleSlideEnd(e.target.value)}
          className='slider'
          id='opacity'
        />
        {!isMobile && (
          <label htmlFor='opacity'>
            Furigana Visibility: {furiOpacity > 9 ? `${furiOpacity}%` : 'Hover'}
          </label>
        )}
      </div>
    </SliderWrapper>
  )
}

const ProgressiveLabels = styled.span`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -25px;
  left: -1px;

  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: rgba(0,0,0,0.85) !important;

  padding: 3px;
  z-index: 1;
`

const ProgressiveButtons = (props) => {
  const {
    color,
    hasEnglish,
    isMobile,
    setting,
    onSettingChange,
    variation,
    local,
    localProgSettings
  } = props

  const [currentSetting, setCurrentSetting] = useState(local ? localProgSettings : null)

  const buttonStyle = {
    border: '1px solid white',
    borderColor: variation === 'nav' ? 'var(--jfz-blue)' : 'white',
    borderRight: 'none',
    borderRadius: 0,
    position: 'relative',
    marginRight: '0',
    padding: isMobile ? '' : '9px 14px',
    minWidth: isMobile ? '' : '42px',
  }

  useEffect(() => {
    setCurrentSetting(setting)
  }, [setting])

  const handleSettingChange = (_setting, current) => {
    setCurrentSetting(current)
    onSettingChange(_setting)
  }

  const styleSetter = (_setting) => {
    const mobileDrawerStyles = {
      ...buttonStyle,
      color: currentSetting === _setting ? 'var(--jfz-blue)' : 'white',
      backgroundColor: currentSetting === _setting ? 'white' : 'var(--jfz-blue)',
      fontSize: '1.2rem',
    }

    const the_styles = {
      ...buttonStyle,
      color: currentSetting === _setting ? 'var(--blue-gray)' : 'white',
      backgroundColor: currentSetting === _setting ? 'white' : 'transparent',
      fontSize: '1.2rem',
    }

    return variation === 'nav' ? mobileDrawerStyles : the_styles
  }

  return (
    <>
      <Button
        className='progessive-settings'
        onClick={() => handleSettingChange('romaji', currentSetting)}
        onTouchEnd={() => handleSettingChange('romaji', currentSetting)}
        style={styleSetter('romaji')}
      >
        R
        <ProgressiveLabels color={color}>
          Romaji
        </ProgressiveLabels>
      </Button>
      <Button
        className='progessive-settings'
        onClick={() => handleSettingChange('progressive', currentSetting)}
        onTouchEnd={() => handleSettingChange('progressive', currentSetting)}
        style={styleSetter('progressive')}
      >
        P
        <ProgressiveLabels color={color}>
          Progressive
        </ProgressiveLabels>
      </Button>
      <Button
        className='progessive-settings'
        onClick={() => handleSettingChange('hirakata', currentSetting)}
        onTouchEnd={() => handleSettingChange('hirakata', currentSetting)}
        style={styleSetter('hirakata')}
      >
        か
        <ProgressiveLabels color={color}>
          Kana
        </ProgressiveLabels>
      </Button>
      <Button
        className='progessive-settings'
        onClick={() => handleSettingChange('kanji', currentSetting)}
        onTouchEnd={() => handleSettingChange('kanji', currentSetting)}
        style={{
          ...styleSetter('kanji'),
          // eslint-disable-next-line
          borderRight: isMobile
            ? '1px solid var(--jfz-blue'
            : (!hasEnglish)
              ? '1px solid white'
              : ''
        }}
      >
        字
        <ProgressiveLabels color={color}>
          Kanji
        </ProgressiveLabels>
      </Button>
      {hasEnglish && (
        <Button
          className='progessive-settings'
          onClick={() => handleSettingChange('english', currentSetting)}
          onTouchEnd={() => handleSettingChange('english', currentSetting)}
          style={{
            ...styleSetter('english'),
            borderRight: '1px solid white'
          }}
        >
          E
          <ProgressiveLabels color={color}>
            English
          </ProgressiveLabels>
        </Button>
      )}
    </>
  )
}

const ProgressiveSettingsToggle = (props) => {
  const {
    variation,
    color = 'var(--jfz-blue)',
    hasEnglish = false,
    local = false
  } = props

  const [setting, setSetting] = useState(props.localProgSettings || props.progressive_settings?.main_default)
  const [previousSetting, setPreviousSetting] = useState(props.progressive_settings?.main_default)
  const [sliderVisible, setSliderVisible] = useState(false)
  const [shouldShowSlider, setShouldShowSlider] = useState(false)
  const isMobile = useMobile()
  const [isStaffRole] = useStaffRoles()

  const handleSliderVisiblity = (progSetting) => {
    let _show = false
    if (progSetting === 'kanji' || (progSetting === 'progressive')) _show = true
    
    setShouldShowSlider(_show)
    setSliderVisible(_show)
  }

  const changeSetting = (val, opacity) => {
    if (local) {
      // for sound popup
      setSetting(val)
      props.onToggleChange(val) // throw setting back to sound popup
      setPreviousSetting(val)
      return
    }
    const progressive_settings = { main_default: val, furi_opacity: opacity }
    props.dispatchSetting(progressive_settings)
    setSetting(val)

    axios
      .post('/user/progressive_settings', { setting: val, furi_opacity: opacity })
      .catch((err) => console.error(err))

    handleSliderVisiblity(val) // check if opacity slider should still be shown
  }

  // ensure we at least have progressive if nothing has been selected
  useEffect(() => {
    if (!props.progressive_settings?.main_default) {
      changeSetting('progressive')
    }
  }, [])

  useEffect(() => {
    if (local || previousSetting === props.progressive_settings?.main_default) return
    setPreviousSetting(props.progressive_settings?.main_default)
  }, [setting, props.progressive_settings])

  useEffect(() => {
    handleSliderVisiblity(props.progressive_settings?.main_default)
  }, [props.progressive_settings?.main_default])

  return (
    <ProgButtonsStyleWrapper>
      <div className='progressive-settings'>
        <Button.Group size='tiny'>
          <ProgressiveButtons
            color={color}
            localProgSettings={props.localProgSettings}
            changeSetting={changeSetting}
            hasEnglish={hasEnglish}
            isMobile={isMobile}
            local={local}
            onSettingChange={changeSetting}
            setSliderVisible={setSliderVisible}
            setting={setting}
            sliderVisible={sliderVisible}
            variation={variation}
          />
        </Button.Group>
        {props.variation === 'nav' && shouldShowSlider && (
          <OpacitySlider
            current={props.progressive_settings?.main_default}
            isMobile={isMobile}
            opacity={props.progressive_settings?.furi_opacity}
            onSlideEnd={changeSetting}
            sliderVisible={sliderVisible}
          />
        )}
      </div>
    </ProgButtonsStyleWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    progressive_settings: state.auth.progressive_settings,
    colorized_furi: state.auth.colorized_furi
  }
}

export default connect(mapStateToProps, { dispatchSetting })(ProgressiveSettingsToggle)
