import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import axios from 'src/api'
import { ProgSelect } from '../../../styles/ProgSettingsTogglerStyles'
import { AxiosError } from 'axios'
import { ProgressiveSettingsResponse, REDUX_ACTIONS } from 'src/types'

const settings = [
  { id: 'romaji', text: 'Romaji' },
  { id: 'progressive', text: 'Progressive' },
  { id: 'hirakata', text: 'Kana' },
  { id: 'kanji', text: 'Kanji' }
]

const SelectArrow = () => (
  <svg
    width="23"
    height="18"
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ position: 'absolute', alignSelf: 'center', justifySelf: 'end', width: '12px', right: '16px' }}
  >
    <path d="M8.66436 16.1304C10.0689 18.0471 12.9311 18.0471 14.3356 16.1304L21.6906 6.09341C23.3923 3.7712 21.7339 0.5 18.855 0.5L4.14502 0.5C1.26605 0.5 -0.392311 3.7712 1.30939 6.09342L8.66436 16.1304Z" fill='var(--fz-course-primary)'/>
  </svg>
)

const ProgSettingsToggler = () => {
  const progSettings = useSelector((state: RootState) => state.auth.progressive_settings)
  const dispatchProg = useDispatch()

  const main_default = progSettings?.main_default ?? 'progressive'
  const furi_opacity = progSettings?.furi_opacity

  const handleChangeSetting = async (setting: string) => {
    try {
      // we don't care about the response in order to set redux state
      // it's only important to fire this off to save it their record
      // still gonna set the responose type tho
      await axios.post<ProgressiveSettingsResponse>('/user/progressive_settings', { setting, furi_opacity })
    } catch (err) {
      const error = err as AxiosError
      console.error(error?.code)
      console.error(error?.message)
    }

    dispatchProg({
      type: REDUX_ACTIONS.CHANGE_SETTING,
      payload: { ...progSettings, main_default: setting, furi_opacity }
    })
  }

  return (
    <div>
      <p style={{ paddingBottom: '8px' }}>Japanese Mode:</p>
      <div style={{ position: 'relative', display: 'grid' }}>
        <ProgSelect defaultValue={main_default} onChange={(e) => handleChangeSetting(e.target.value)}>
          {settings.map((setting) => (
            <option key={setting.id} value={setting.id}>
              {setting.text}
            </option>
          ))}
        </ProgSelect>
        <SelectArrow />
      </div>
    </div>
  )
}

export default ProgSettingsToggler
