import styled from 'styled-components'

export default styled.div`
  p { font-weight: lighter; }

  > div {
    display: flex;
    justify-content: space-around;
    flex: 0 1 auto;

    button {
      color: #cbcbcb !important;
      background-color: var(--off-white);
      padding: 5px 15px;
      border-radius: 12px;
      width: 50px;
    }

    button.active {
      color: var(--jfz-blue) !important;
    }
  }
`

export const ProgSelect = styled.select`
  width: 100%;
  padding: 10px 20px;
  border-radius: 24px;
  font-family: 'Figtree' !important;
  font-weight: 700;
  appearance: none; // remove default dropdown arrow
  color: var(--fz-course-primary) !important;
  cursor: pointer;

  * {
    font-family: 'Figtree' !important;
    font-weight: 700;
    color: var(--fz-course-primary) !important;
  }
`
