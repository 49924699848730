import styled from '@emotion/styled'

const NavigationWrapper = styled.nav<{ white: boolean, isMobileCourseNav: boolean }>`
  position: fixed;
  z-index: 30;
  left: 0;
  right: 0;
  top: 0;
  height: 70px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: 0 16px;
  align-items: center;
  gap: 16px;
`

export default NavigationWrapper
