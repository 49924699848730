import styled, { css } from 'styled-components'

interface isMobileProp {
  isMobile?: boolean
}

// eslint-disable-next-line import/prefer-default-export
export const KanjiSegment = styled.div<isMobileProp>`
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  background: #FFFFFF;
  padding: ${({ isMobile }) => isMobile ? '10px 14px' : '14px 14px'};
  box-shadow: ${({ isMobile }) => isMobile ? 'none' : '0px 0px 50px -40px #03002F'};
  border-top: 4px solid var(--fz-course-tertiary);
`
