/* eslint-disable no-multi-spaces */
import styled from '@emotion/styled'
import { CLOUDFRONT_BASE } from 'src/util/constants'

const desktopBaseURL = `${CLOUDFRONT_BASE}/images/landing_page/v3/desktop`
const mobileBaseURL = `${CLOUDFRONT_BASE}/images/landing_page/v3/mobile`

// desktop assets
export const arrowSwirl                     = `${desktopBaseURL}/arrow-swirl-flex.svg`
export const bkgdDecorObjectBlue            = `${desktopBaseURL}/bkgd-decor-object-blue.svg`
export const bkgdHangulBottom               = `${desktopBaseURL}/bkgd-hangul-bottom.svg`
export const bkgdHangulTop                  = `${desktopBaseURL}/bkgd-hangul-top.svg`
export const bkgdKanaBottom                 = `${desktopBaseURL}/bkgd-kana-bottom.svg`
export const bkgdKanaTop                    = `${desktopBaseURL}/bkgd-kana-top.svg`
export const bkgdPatternWave                = `${desktopBaseURL}/bkgd-pattern-wave-offwhite.svg`
export const bkgdPolygon                    = `${desktopBaseURL}/bkgd-polygon.svg`
export const bkgdSakuraPetals               = `${desktopBaseURL}/bkgd-sakura-petals.webp`
export const bkgdTileBlue                   = `${desktopBaseURL}/bkgd-tile-blue.webp`
export const bkgdWaveFooter                 = `${desktopBaseURL}/bkgd-wave-footer.svg`
export const buttonArrow                    = `${desktopBaseURL}/button-arrow.svg`
export const buttonPatternDefault           = `${desktopBaseURL}/button-pattern-default.svg`
export const buttonPatternDefaultHangul     = `${desktopBaseURL}/button-pattern-default-hangul.svg`
export const buttonPatternDefaultHangulBlue = `${desktopBaseURL}/button-pattern-default-hangul-blue.svg`
export const buttonPatternHover             = `${desktopBaseURL}/button-pattern-hover.svg`
export const chibiKenji                     = `${desktopBaseURL}/chibi-kenji.webp`
export const chibiMari                      = `${desktopBaseURL}/chibi-mari.webp`
export const chibiRen                       = `${desktopBaseURL}/chibi-ren.webp`
export const chibiTomokoCheer               = `${desktopBaseURL}/chibi-tomoko-cheer.webp`
export const headerSlantLeft                = `${desktopBaseURL}/header-slant-left-flex.svg`
export const headerSlantRight               = `${desktopBaseURL}/header-slant-right-flex.svg`
export const headerSlantLeftBlue            = `${desktopBaseURL}/header-slant-left-flex-blue.svg`
export const headerSlantRightBlue           = `${desktopBaseURL}/header-slant-right-flex-blue.svg`
export const iconPasswordHide               = `${desktopBaseURL}/icon-password-hide.svg`
export const iconPasswordShow               = `${desktopBaseURL}/icon-password-show.svg`
export const iconCard                       = `${desktopBaseURL}/icon-card.svg`
export const kfzCharLogin                   = `${desktopBaseURL}/character-yuna-promotional.webp`
export const kfzCharStandard                = `${desktopBaseURL}/character-yuna-standard.webp`
export const logoFromZero2024               = `${desktopBaseURL}/logo-fromzero-2024.svg`
export const logoFromZeroFooter             = `${desktopBaseURL}/logo-fromzero-footer.svg`
export const patternTransitionRidges        = `${desktopBaseURL}/pattern-transition-ridges.svg`
export const pricePlanPaperClipDefault      = `${desktopBaseURL}/price-plan-paper-clip-default.svg`
export const pricePlanPaperClipHover        = `${desktopBaseURL}/price-plan-paper-clip-hover.svg`
export const priceTierSealMonthly           = `${desktopBaseURL}/price-tier-seal-monthly.svg`
export const sampleMacbook                  = `${desktopBaseURL}/sample-macbook.webp`
export const segment1                       = `${desktopBaseURL}/segment-1.webp`
export const segment2                       = `${desktopBaseURL}/segment-2.webp`
export const segment3                       = `${desktopBaseURL}/segment-3.webp`
export const siteReview1                    = `${desktopBaseURL}/site-review-1.webp`
export const siteReview2                    = `${desktopBaseURL}/site-review-2.webp`
export const siteReview3                    = `${desktopBaseURL}/site-review-3.webp`
export const siteScreenshotCollage          = `${desktopBaseURL}/site-screenshot-collage.webp`
export const tomokoLogin                    = `${desktopBaseURL}/tomoko_login.webp`
export const tomokoExcitedDefault           = `${desktopBaseURL}/tomoko-excited-default.webp`
export const writingGuide                   = `${desktopBaseURL}/writing-guide-flex.svg`

// mobile assets
export const bkgdDecorObjectBlueMobile      = `${mobileBaseURL}/bkgd-decor-object-blue-mobile.svg`
export const bkgdKanaTopMobile              = `${mobileBaseURL}/bkgd-kana-top-mobile.svg`
export const bkgdSakuraPetalsMobile         = `${mobileBaseURL}/bkgd-sakura-petals-mobile.webp`
export const patternTransitionRidgesMobile  = `${mobileBaseURL}/pattern-transition-ridges-mobile.svg`
export const siteScreenshotCollageMobile    = `${mobileBaseURL}/site-screenshot-collage-mobile.webp`
export const speechBubbleMobile             = `${mobileBaseURL}/speech_bubble_mobile.svg`
export const speechBubbleMobileKorean       = `${mobileBaseURL}/speech_bubble_mobile_korean.svg`

// colors
export const black = '#3C4043'
export const white = '#FFFFFF'
export const blue = '#7BBBFF'
export const red = '#FF3559'
export const highlight = '#FFEEB1'

const colors = { black, white, blue, red }

// shared styles
export const ColoredSpan = styled.span<{ color: string }>`
  color: ${({ color }) => colors[color]};
`

export const SmallText = styled.p<{ isMobile?: boolean }>`
  font-family: Outfit;
  font-weight: 600;
  font-size: ${({ isMobile }) => isMobile ? '1.25rem' : '1.5rem'};
  color: ${black};
  z-index: 1;
`

export const Highlight = styled.span`
  display: inline-grid;
  margin-bottom: -.7em; // offset the height of the ::after pseudo-element

  &::after {
    content: '';
    height: .7em;
    background-color: ${highlight};
    border-radius: .2em;
    width: calc(100% + 0.3em);
    justify-self: center;
    position: relative;
    bottom: 110%;
    z-index: -1;
  }
`

export const GeneralHeaderText = styled.h2<{ fontSize?: string }>`
  font-family: 'Tilt Warp';
  font-size: ${({ fontSize }) => fontSize || '3.5rem'} !important;
  color: ${black};

  @media (max-width: 575px) { font-size: 2.75rem !important; }
  @media (max-width: 400px) { font-size: 2.25rem !important; }
`
