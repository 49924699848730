import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useNavigationContext from 'src/hooks/context/useNavigationContext'
import { RootState } from 'src/configureStore'
import CoursePositionBar from './CoursePositionBar'

const CenterNav = () => {
  const [domIsReady, setDomIsReady] = useState(false)
  const isSignedIn = useSelector((state: RootState) => state.auth.isSignedIn)
  const isLessonContent = useRouteMatch('/courses/:id/:lid')
  const { isMobileCourseNav } = useNavigationContext()
  const { pathname } = useLocation()

  useEffect(() => {
    setDomIsReady(true)
  }, [])

  if (!isSignedIn || !isLessonContent || pathname === '/es' || pathname === '/claim') return <></>

  // if course nav is mobile version (fixed at bottom), render it in the style manager div to avoid the jank
  return isMobileCourseNav && domIsReady
    ? createPortal(<CoursePositionBar />, document.getElementById('fz-style-manager'))
    : <CoursePositionBar />
}

export default CenterNav
