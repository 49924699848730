import { AnimatePresence } from 'framer-motion'
import useNavigationContext from 'src/hooks/context/useNavigationContext'
import { DropdownWrapper, HorizontalDivider } from '../../styles/DropdownStyles'
import NavLinkItemsSection from '../NavLinkItemsSection'
import { dropdownNavigationItems } from '../../util'
import Tools from './Tools/Tools'
import LogoutButton from './Menu/LogoutButton'
import MobileDropdownHeader from './MobileDropdownHeader'

const DropdownMenu = () => {
  const { dropdownMenuIsOpen } = useNavigationContext()

  return (
    <AnimatePresence>
      {dropdownMenuIsOpen && (
        <DropdownWrapper
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          onClick={(e) => e.stopPropagation()} // prevent closeDropdownMenu() from firing when clicking inside; called at Navigator.tsx->TailwindResetUnreset
        >
          <MobileDropdownHeader />
          <NavLinkItemsSection items={dropdownNavigationItems} dropdown />
          <HorizontalDivider white />
          <Tools />
          <LogoutButton />
        </DropdownWrapper>
      )}
    </AnimatePresence>
  )
}

export default DropdownMenu
