import React, { useEffect, useState } from 'react'

import { Button, Grid, Header, Segment } from 'semantic-ui-react'

import useWidget from '../../hooks/useWidget'

const LineConnectWidget = props => {
    const [pairs, setPairs] = useState([])
    const [correctnessMap, setCorrectnessMap] = useState({})
    const [userPairs, setUserPairs] = useState([])
    const [widget, fetchWidget, loading] = useWidget(props.id)

    const [completedPairs, setCompletedPairs] = useState([])

    const [selecting, setSelecting] = useState(false)
    const [selection, setSelection] = useState(null)

    useEffect(() => {
        setPairs(widget?.pairs)

        let tempMap = {}
        widget?.pairs && widget.pairs.map(p => {
            tempMap[p.left] = p.right
            tempMap[p.right] = p.left
        })
        setCorrectnessMap(tempMap)
    }, [widget])

    const selectItem = (idx, side, val) => {
        if (!selection) {
            let alreadyComplete = false

            // ensure we haven't already added this to completed pairs
            completedPairs.forEach(cp => {
                if (cp[side] === val) alreadyComplete = true 
            })

            if (alreadyComplete) return;

            setSelection({side, num: idx, val})
        } else {
            if (selection.side === side) return;

            // add to completed pairs with either correct or wrong flag
            let tempCompleted = [...completedPairs]
            tempCompleted.push({[`${side}`]: val, [`${selection.side}`]: selection.val, 
                correct: correctnessMap[val] === selection.val})
            setCompletedPairs(tempCompleted)
            setSelection(null)

            // flag segments with correct or wrong
            let tempPairs = [...pairs]
            tempPairs[selection.num][correctnessMap[val] === selection.val ? 'correct' : 'wrong'] = true
            tempPairs[idx][correctnessMap[val] === selection.val ? 'correct' : 'wrong'] = true
            setPairs(tempPairs)
        }
    }

    const reset = () => {

    }

    return (
        <Segment style={{ backgroundColor: '#2e64bc', paddingTop: '30px', paddingBottom: '30px', background: 'var(--fz-course-tertiary)' }}>
            <Header as='h2' inverted style={{fontFamily: 'Milliard Book'}}>Line Connect</Header>
            <Grid centered style={{ color: 'var(--blue-gray)', fontSize: 'var(--reg-font-size)' }}>
                {pairs?.map((p, idx) =>
                    <Grid.Row>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={5}>
                            <Segment
                                onClick={() => selectItem(idx, 'left', p.left)} 
                                style={{
                                    fontSize: '2rem', fontFamily: 'Yu Gothic UI Regular, Milliard Bold',
                                    backgroundColor: (selection?.side === 'left' && selection.num === idx) ? '#faf5c8' : 
                                        (p.correct ? '#d6ffd6' : (p.wrong ? '#ffd6d6' : '#fff'))
                                }}
                            >
                                {p.left}
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={4}></Grid.Column>
                        <Grid.Column width={5}>
                            <Segment
                                onClick={() => selectItem(idx, 'right', p.right)} 
                                style={{
                                    fontSize: '2rem', fontFamily: 'Yu Gothic UI Regular, Milliard Bold',
                                    backgroundColor: (selection?.side === 'right' && selection.num === idx) ? '#faf5c8' : '#fff'
                                }}
                            >
                                {p.right}
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                    </Grid.Row>)}
            </Grid>
            <Button style={{marginTop: '30px', textAlign: 'right'}} onClick={reset}>Reset</Button>
        </Segment>
    )
}

export default LineConnectWidget