import styled from 'styled-components'
import { Modal } from '@mantine/core'

import { CLOUDFRONT_BASE } from 'src/util/constants'

import tomokoCheer from 'src/assets/quiz/chibi_tomoko_cheer_quiz_results.png'

const streakModalBase = `${CLOUDFRONT_BASE}/images/modals/streak_alert`
const streakModalConfetti = `${streakModalBase}/confetti.png`
const streakModalBurstTop = `${streakModalBase}/burst-top.png`
const streakModalPatternDiagonal = `${streakModalBase}/ptn-diagonal-small-white.png`
const streakModalSpeechBubble = `${streakModalBase}/speech-bubble.svg`
const streakModalButtonClose = `${streakModalBase}/button-close.png`

export const StreakModalTop = styled.div`
  // combine confetti and burst top backgrounds
  background: url(${streakModalConfetti}), url(${streakModalBurstTop}), #fff;
  background-repeat: no-repeat, no-repeat;
  background-position: center top, center top;
  background-size: 100%, 100%;
  padding: 1rem;
  text-align: center;
  position: relative;
  height: 350px;
  width: 450px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  
  @media (max-width: 500px) {
    width: 100vw;
  }
`

export const StreakModalText = styled.div`
  position: relative;
  display: inline-block;
`

export const StreakText = styled.span`
  font-size: 3.5rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: 1rem;
  font-family: 'Tilt Warp';
  -webkit-text-stroke: 5px #000;
  letter-spacing: -1px;
  paint-order: stroke fill;
  filter: drop-shadow(0px 4px 0 #000);
`

export const GoldText = styled.span<{ text: string }>`
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  font-family: 'Tilt Warp';
  background: -webkit-linear-gradient(90deg, #F0B478, #FFE297, #FFE297, #F0B478);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px #000;
  paint-order: stroke fill;
  text-shadow: none;
  filter: drop-shadow(0px 4px 0 #000);

  &:after {
    -webkit-text-stroke: 1px #000;
  }
`

export const StreakModalBottom = styled.div`
  background: url(${streakModalPatternDiagonal}), linear-gradient(90deg, #F0B478, #FFE297, #FFE297, #F0B478);
  padding: 1rem;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  height: 180px;
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  @media (max-width: 500px) {
    width: 100vw;
  }
`

export const StreakModalBottomText = styled.p`
  font-size: 1.25rem;
  color: #fff;
  font-family: 'Figtree';
  font-weight: 700;
  -webkit-text-stroke: 1px #fff;
  paint-order: stroke fill;
  text-shadow: 0px 0px 12px #8D1717;

  @media (max-width: 344px) {
    font-size: 1.15rem;
  }
`

export const StreakModalBottomButton = styled.button`
  // four-color gradient background
  color: #3c4043;
  font-size: 1.75rem;
  font-family: 'Tilt Warp';
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  border-bottom: 6px solid rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
  -webkit-background-clip: padding-box;

  @media (max-width: 344px) {
    font-size: 1.5rem;
  }
`

export const KeepItUp = styled.img.attrs({ src: streakModalSpeechBubble })`
  position: absolute;
  top: 105px;
  left: 8%;
  max-width: 150px;
  z-index: 1;
`

export const TomokoCheer = styled.img.attrs({ src: tomokoCheer })`
  position: absolute;
  bottom: -35%;
  right: 30px;
  width: 100%;
  max-width: 250px;
`

export const StreakModalWrapper = styled(Modal)`
  background-color: transparent;

  .mantine-Paper-root {
    width: 500px;
    flex-basis: 500px;
    position: relative;
    left: 10px;
    overflow-y: visible;
    background-color: transparent;
    box-shadow: none;

    @media (max-width: 548px) {
      width: 100vw;
      left: 0;
    }
  }

  .mantine-Modal-body {
    padding: 0;
    left: 50px;

    @media (max-width: 500px) {
      width: 100%;
      left: 0;
    }
  }

  .mantine-Modal-header {
    display: none;
  }

  .mantine-Modal-inner {
    @media (max-width: 548px) {
      padding-left: 1vw;
      padding-right: 1vw;
    }
    @media (max-width: 500px) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
`

export const ExitModalButton = styled.img.attrs({ src: streakModalButtonClose })`
  position: absolute;
  top: -10px;
  right: 40px;
  border: none;
  cursor: pointer;
  z-index: 3;
  border-radius: 100%;
  height: 32px;
  width: 29px;
  color: white;

  span {
    font-size: 1.25rem;
    font-weight: bold;
    position: relative;
    font-family: 'Tilt Warp';
    /* center vertically and horizontally in circle */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  @media (max-width: 500px) {
    top: -10px;
    right: 10px;
  }
`
