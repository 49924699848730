import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const Obscured = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
`

interface ModalStylesProps {
  flat?: boolean,
  isMobile?: boolean,
  isSubPage?: boolean
}

export const ModalStyles = styled.div<ModalStylesProps>`
  top: ${(props) => props.flat ? '0' : '50px'};
  left: 0;
  right: 0;
  z-index: ${(props) => props.flat ? '1' : '10001'};
  position: ${(props) => props.flat ? 'relative' : 'fixed'};
  margin: ${(props) => props.flat ? '20px auto' : '0 auto'};
  min-width: 350px;
  width: 30%;
  max-width: 800px;
  ${(props) => !props.isSubPage && css`background-color: white;`}
  box-shadow: ${(props) => props.flat ? '0 0 9px 3px rgba(0,0,0,0.15)' : '0 0 20px 10px rgba(0,0,0,0.15)'};
  ${(props) => props.isSubPage && css`box-shadow: none;`}
  border-radius: 25px;

  h2 {
    text-align: center;
    margin-bottom: 15px;
    color: var(--blue-gray);
    font-family: "Mont Extra Light";
    z-index: 2;
  }

  h2, h3 {
    margin: ${(props) => props.isMobile ? '0' : ''};
  }

  h3.message {
    margin: 10px auto;
  }

  .fz-cast {
    left: 0;
    right: 0;
    top: -10px;
    width: 96%;
    margin: 0 auto;
    position: "relative";
    overflow: visible;
    z-index: 1;
    object-fit: contain;

    img {
      width: 100%;
    }
  }

  .actions {
    gap: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 15px;

    a {
      color: var(--jfz-blue);

      &:hover {
        color: var(--sub-blue);
      }
    }
  }

  .container {
    padding: 20px 50px;
    width: 100%;

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  #registration-success {
    padding: 10px 0;

    h1, p { text-align: center; }
  }

@media (min-width: 2000px) {
  .spacer {
    margin-bottom: ${(props) => props.flat ? '' : '300px'};
  }
}
@media (max-width: 1600px) {
  .spacer {
    margin-bottom: ${(props) => props.flat ? '' : '200px'};
  }
}
@media (max-width: 1400px) {
  .spacer {
    margin-bottom: ${(props) => props.flat ? '' : '130px'};
  }
}
@media (max-height: 700px) {
    top: 15px;
}
@media (max-height: 550px) {
  width: 90vw;

  .fz-cast {
    width: 70%;
  }
  .spacer {
    margin-top: -20px;
  }

  .container form {
    flex-direction: row;
    align-items: center;
    gap: 20px;
    div {
      width: 33%;
    }
    button {
      height: 50px;
    }
  }
  .actions {
    flex-direction: row;
    justify-content: center;
    gap: 20px;
  }
}
`

export const ProgressModalStyles = styled.div`
  top: 10px;
  left: 0;
  right: 0;
  z-index: 10001;
  position: fixed;
  margin: 0 auto;
  min-width: 350px;
  width: 60%;
  max-width: 800px;
  height: 90vh;
  background-color: white;
  box-shadow: 0 0 20px 10px rgba(0,0,0,0.15);
  border-radius: 15px;
  overflow-y: scroll;
  padding: 12px;

  scroll-behavior: smooth;

  p, a {
    font-family: "Milliard Book", sans-serif;
  }

  .lesson-progress {
    a {
      font-size: 0.9rem;
    }

    p {
      font-size: 1.2rem;
    }

    >div {
      display: flex;
      flex-direction: column;
    }
  }

  .lesson {
    padding: 6px 0;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    gap: 4px;

    div {
      justify-self: end;
    }

    &:hover {
      background-color: #ccc;
    }
  }

  #progress {
    position: relative;

    .exit {
      position: absolute;
      background-color: #f57504;
      top: -74px;
      right: 0;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      border: 4px solid var(--off-white);
      z-index: 2;
      cursor: pointer;

      & > div {
        position: relative;
        border: 2px solid black;
        width: 32px;
        height: 32px;
        bottom: 15px;
        right: 15px;
        border-radius: 50%;
      }

      i {
        position: relative;
        color: var(--off-white);
        top: 3.5px;
        left: 5.75px;
      }
    }
  }

  @media (max-width: 768px) {
    width: 95%;
  }
`

// letting '✕' be DOM element vs pseudo-element for focusability/accessibility
export const ModalXContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  position: absolute;
  right: -15px;
  top: -15px;
  font-weight: 900;
  color: hsl(0deg 0% 90%);

  :hover {
    cursor: pointer;
    color: hsl(0deg 0% 80%);
  }
`
