import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import { REDUX_ACTIONS } from 'src/types'
import { FuriSlider, SliderExplanation, SliderMain } from '../../../styles/InputSlider.styles'

const FuriOpacitySlider = () => {
  const progSettings = useSelector((state: RootState) => state.auth.progressive_settings)
  const dispatchProg = useDispatch()

  const setting = progSettings?.main_default
  const furi_opacity = progSettings?.furi_opacity

  const handleChangeFuriOpacity = async (value: number) => {
    dispatchProg({
      type: REDUX_ACTIONS.CHANGE_SETTING,
      payload: { ...progSettings, main_default: setting, furi_opacity: value }
    })
  }

  return (
    <SliderMain>
      <label htmlFor='opacity'>
        <p>Furigana Visibility: {furi_opacity > 0 ? `${furi_opacity}%` : 'When Hovering'}</p>
      </label>
      <FuriSlider
        id='opacity'
        step={10}
        thumbSize={26}
        label={null}
        value={furi_opacity}
        onChange={(value) => handleChangeFuriOpacity(value)}
        furiOpacity={furi_opacity}
      />
      <SliderExplanation>Change transparency of the Kana above Kanji</SliderExplanation>
    </SliderMain>
  )
}

export default FuriOpacitySlider
