import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useAPI from 'src/hooks/useAPI'
import { RootState } from 'src/configureStore'
import { DropdownCheckbox } from '../../../styles/DropdownStyles'
import { changeSettings } from 'src/actions/index'

const CalligraphyModeToggle = () => {
  const calligraphyChecked = useSelector((state: RootState) => state.auth?.calligraphy_mode)
  const [resC, _, reqC] = useAPI('/user/calligraphy_mode', 'PUT')
  const dispatch = useDispatch()

  useEffect(() => {
    if (!resC?.success) return
    dispatch(changeSettings({ calligraphy_mode: resC?.calligraphy_mode }))
  }, [resC])

  return (
    <DropdownCheckbox
      checked={calligraphyChecked}
      onChange={(e: React.FormEvent<HTMLInputElement>) => reqC({ calligraphy_mode: e.currentTarget.checked })}
      label='Calligraphy Mode (Beta):'
      labelPosition='left'
      size='md'
      isChecked={calligraphyChecked}
    />
  )
}

export default CalligraphyModeToggle
