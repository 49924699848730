import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { Checkbox } from '@mantine/core'

interface TabProps {
  active: boolean
}

interface DropdownCheckboxProps {
  isChecked: boolean
}

export const DropdownWrapper = styled(motion.div)`
  position: absolute;
  z-index: 4;
  top: 70px;
  right: 16px;
  width: 320px;
  display: grid;
  gap: 16px;
  border-radius: 20px;
  background-color: var(--fz-course-primary);
  padding: 16px;
  overflow: auto;
  max-height: 80vh;

  // general styles for dropdown items, can be overridden by specific dropdown items
  * {
    font-size: 1.2rem;
    font-family: 'Tilt Warp';
    color: white;
  }

  @media (max-width: 500px) {
    width: 100%;
    height: 100vh;
    max-height: unset;
    right: 0;
    top: 0;
    border-radius: 0;
    align-content: start;
  }
`

export const HorizontalDivider = styled.div<{ white?: boolean }>`
  width: 100%;
  height: 1px;
  background-color: ${({ white }) => white ? 'white' : 'var(--fz-course-secondary)'};
`

export const MenuCourseSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
`

export const MenuCourseHeader = styled.h2<TabProps>`
  cursor: pointer;
  color: ${({ active }) => active ? 'var(--fz-course-tertiary)' : 'white'};
`

export const DropdownCheckbox = styled(Checkbox)<DropdownCheckboxProps>`
  .mantine-Checkbox-body {
    justify-content: space-between;
  }

  .mantine-Checkbox-input {
    background-color: ${({ isChecked }) => isChecked ? 'var(--fz-course-tertiary)' : 'transparent'};
    border: ${({ isChecked }) => isChecked ? 'none' : '1px solid #FFFFFF99'};
    border-radius: 5px;
  }
`

//
// MobileNavHeader Styles
//

export const MobileHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr auto;
`

export const MobileHeaderColumn = styled.div<{ right?: boolean }>`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: ${({ right }) => right ? 'flex-end' : 'flex-start'};
`

export const Xbutton = styled.button`
  background: white;
  border-radius: 50%;
  padding: 4px;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.1);

  & > svg {
    fill: var(--fz-course-primary);
  }
`
