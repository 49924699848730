// used with the sound icon to keep track of whether the sound mapping to it is playing
// also has the play method within to allow manual triggering
//
import axios from 'axios'

import { useCallback, useContext, useEffect, useRef, useState, RefObject } from 'react'

import { Record } from 'src/types'

import { AudioContext } from 'src/contexts/AudioContext'

const shortenSoundURL = (url: string) => {
  const split = url?.split('?')
  return split?.[0]
}

// we want to perform a HEAD request on the record's `sounds` (starting with `src` arg)
// until we get a sound that actually returns a 200, else return false
const getFallbackAudioSrc = async (record: Record, src: string) => {
  // do an initial HEAD request on the src
  const head = await axios.head(src).catch(() => false)
  if (head) return src

  // if the head request fails, try the other sounds
  const sounds = Object.values(record.sounds)
  for (let i = 0; i < sounds.length; i++) {
    const sound = sounds[i] as any
    const newHead = await axios.head(sound).catch(() => false)
    if (newHead) return sound
  }

  return false
}

const useIsPlaying = (record: Record, playingNum: number | string, num: number | string, cafe: boolean, buttonRef: RefObject<HTMLButtonElement>, ping: () => void) => {
  const [playing, setPlaying] = useState(false)
  const [playingFrom, setPlayingFrom] = useState('')
  const [debugMessage, setDebugMessage] = useState('')
  const [currentSoundClicked, setCurrentSoundClicked] = useState(false)
  const [internalAudioSrc, setInternalAudioSrc] = useState('')
  const playingRef = useRef(playing)
  const { audio, setAudioSrc, clickTriggered, setClickTriggered, pauseAudio: pauseA, playAudio: playA, changeAudioSrc, clickedElement, setClickedElement } = useContext(AudioContext)

  useEffect(() => {
    if (!playing) setPlayingFrom('')
    playingRef.current = playing
  }, [playing])

  useEffect(() => {
    if (clickedElement !== buttonRef.current) {
      setClickTriggered(false)
      setPlaying(false)
      setCurrentSoundClicked(false)
    }
  }, [clickedElement, buttonRef])

  const [currentSoundCallbackChange, setCurrentSoundCallbackChange] = useState(false)

  // console.log(record?.base_text, props.playing, playing)
  useEffect(() => {
    // console.log('audio changed', currentSoundCallbackChange, playing, currentSoundClicked)
    if (!currentSoundCallbackChange && !currentSoundClicked && !(typeof playingNum === 'number' && playingNum === num)) {
      setDebugMessage(`${record?.base_text} audio changed`)
      // console.log(record?.base_text + ' audio changed')
      setPlaying(false)
      setCurrentSoundClicked(false)
    }
    setCurrentSoundCallbackChange(false)
  }, [audio.onended])

  useEffect(() => {
    // console.log(debugMessage)
  }, [debugMessage])

  const playAudio = useCallback((e, a11y) => {
    // console.log('record from play', record)
    const shouldPrint = false
    // console.log('before play audio click handler stop propagation')
    // if (!a11y) e.stopPropagation()
    // console.log('after play audio click handler stop propagation')

    // broadcast global click triggered event to context so anything else playing (including hooks)
    // will stop
    if (shouldPrint) console.log('before setting click triggered to true')
    setClickTriggered(true)
    setClickedElement(e.target)
    if (shouldPrint) console.log('after setting click triggered to true')

    if (shouldPrint) console.log('playAudio', e.target, buttonRef.current, currentSoundClicked)

    // call a pause on the audio to trigger other audio to set other icons to not playing
    try {
      if (shouldPrint) console.log('calling pre-pause')
      pauseA()
    } catch (err) {
      console.error('Error pausing audio:', err)
    }

    // if the current sound was clicked (detected via ref), set currentSoundClicked to true
    if (buttonRef?.current === e.target) {
      if (shouldPrint) console.log(`${record?.base_text} setting current sound clicked to true because same target`)
      setDebugMessage(`${record?.base_text} setting current sound clicked to true because same target`)
      setCurrentSoundClicked(true)
    }

    // if the audio is already playing, stop it and set playing to false
    try {
      if (playing) {
        setDebugMessage(`${record?.base_text} setting playing to false because already playing`)
        if (shouldPrint) console.log(`${record?.base_text} setting playing to false because already playing`)
        pauseA()
        setPlaying(false)
        return
      }
    } catch (err) {
      console.error('Error pausing audio:', err)
    }

    try {
      if (audio.src === internalAudioSrc) {
        if (shouldPrint) console.log('already playing source is the same')
        setDebugMessage('already playing source is the same')
        setCurrentSoundCallbackChange(true)

        try {
          if (shouldPrint) console.log('pausing audio in playAudio')
          pauseA()
          if (shouldPrint) console.log('setting onended in playAudio')
          audio.onended = (n) => {
            setPlaying(false)
            setCurrentSoundClicked(false)
          }
          if (shouldPrint) console.log('playing audio in playAudio')
          if (shouldPrint) console.log('audio object', audio)
          playA()
          if (shouldPrint) console.log('audio played in playAudio')

          setPlayingFrom('AUDIO SRC === INTERNAL AUDIO SRC')
          setPlaying(true)
        } catch (err) {
          console.error('Error playing audio:', err)
        }
        return
      }
    } catch (err) {
      console.error('Error pausing audio:', err)
    }

    try {
      setDebugMessage(`setting audio src to ${internalAudioSrc}`)
      if (shouldPrint) console.log('setting audio src to', internalAudioSrc)
      changeAudioSrc(internalAudioSrc)
      setPlayingFrom('AUDIO SRC SET TO INTERNAL AUDIO SRC')
      setPlaying(true)
    } catch (err) {
      console.error('Error setting audio src:', err)
    }

    if (shouldPrint) console.log('audio.onended pre')
    audio.onended = (n) => {
      // console.log(`audio ended for ${record?.base_text}`)
      setPlaying(false)
      setCurrentSoundClicked(false)
      if (cafe) ping()
    }

    // set playing to false if audio is paused
    if (shouldPrint) console.log('audio.onpause pre')
    audio.onpause = () => {
      setPlaying(false)
      setCurrentSoundClicked(false)
    }

    // detect if the user is on iOS
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

    if (!iOS) {
      if (shouldPrint) console.log('non ios detected, waiting for audio to load data before playing')
      audio.onloadeddata = () => {
        if (shouldPrint) console.log('audio loaded data for ' + record?.base_text)
        try {
          playA()
        } catch (err) {
          console.error('Error playing audio:', err)
        }
        if (shouldPrint) console.log('audio played for ' + record?.base_text)
      }
    } else {
      if (shouldPrint) console.log('ios detected, playing audio immediately')
      audio.autoplay = true
      // console.log(audio)
      // console.log('audio played for ' + record?.base_text)
    }
  }, [audio.src, audio.paused, internalAudioSrc, playing, buttonRef, record])

  useEffect(() => {
    // console.log('playingNum', playingNum, 'num', num, 'internalAudioSrc', internalAudioSrc, 'playing', playing, 'currentSoundClicked', currentSoundClicked, 'clickTriggered', clickTriggered)
    // if (playing && !clickTriggered && currentSoundClicked && playingNum !== num) {
    //   setPlaying(false)
    //   setCurrentSoundClicked(false)
    // }
    if (currentSoundClicked && playingNum !== -1 && playingNum !== undefined && !clickTriggered) {
      // console.log('current sound clicked and playing num is not -1, setting playing to false', playingNum, num, internalAudioSrc, playing, currentSoundClicked, clickTriggered)
      setPlaying(false)
      setCurrentSoundClicked(false)
    }
  }, [playing, currentSoundClicked, playingNum, num, internalAudioSrc, clickTriggered])

  // watch for the audio source changing and set playing to false, unless
  // currentSoundClicked is true, in which case we want to keep playing
  useEffect(() => {
    const shouldPrint = false
    setDebugMessage('playing audio hook')
    // console.log(audio.src, internalAudioSrc, clickTriggered, currentSoundClicked, playingNum, num, playingRef.current)
    // console.log('playing audio hook', record?.base_text, playingRef.current)
    // if (shouldPrint) console.log('playing audio hook')
    if (cafe && playingNum === num && !playingRef.current) {
      setPlaying(true)
      return playAudio({ stopPropagation: () => {} }, false)
    }

    // if they're both identical and numbers, we're playing
    if (playingNum === num && typeof playingNum === 'number') {
      // setDebugMessage(record?.base_text + ' playingNum and num are identical numbers, returning')
      if (shouldPrint) console.log(record?.base_text + ' playingNum and num are identical numbers, returning')
      return setPlaying(true)
    }

    // catch sound already playing having been changed
    if (!currentSoundClicked && playingRef.current && audio?.src?.indexOf('mp3') !== -1 && (audio?.src !== internalAudioSrc)) {
      // console.log(audio?.src, internalAudioSrc)
      // console.log(record?.base_text + ' audio src changed, setting playing to false')
      // setDebugMessage(record?.base_text + ' audio src changed, setting playing to false')
      setPlaying(false)
      setCurrentSoundClicked(false)
      return
    }

    if (((num === '' && playingNum === '') || (num === undefined && playingNum === undefined)) && !currentSoundClicked) {
      // setDebugMessage(record?.base_text + ' num and playing num are empty strings, returning')
      // console.log(record?.base_text + ' num and playing num are empty strings, returning')
      return setPlaying(false)
    }

    // if the num and playing num are strings, then "internal playing" is being activated within the soundicon
    // this means that play all has been triggered on a cell with multiple nested sounds
    // in this case, we want to set the audio src to this sound's internal audio src, then trigger ping on the
    // sound once it has finished playing
    if (!clickTriggered && typeof playingNum === 'string' && typeof num === 'string' && playingNum === num) {
      if (shouldPrint) console.log('playing num and num are strings', playingNum, num, internalAudioSrc)
      changeAudioSrc(internalAudioSrc)
      // setDebugMessage(record?.base_text + ' set playing true because playingNum === num and both are strings')
      setPlaying(true)
      audio.onended = (n) => {
        setPlaying(false)
        setCurrentSoundClicked(false)
        // setDebugMessage(record?.base_text + ' audio ended, setting playing to false')
        // console.log(record?.base_text + ' audio ended, setting playing to false')
        // console.log('audio ended, triggering ping from useIsPlaying')
        ping()
      }
  
      // set playing to false if audio is paused
      audio.onpause = () => {
        setPlaying(false)
        setCurrentSoundClicked(false)
        // setDebugMessage(record?.base_text + ' audio paused, setting playing to false')
        // console.log(record?.base_text + ' audio paused, setting playing to false')
      }
  
      audio.onloadeddata = () => {
        setTimeout(() => {
          try {
            playA()
          } catch (err) {
            console.error('Error playing audio after delay on load:', err)
          }
        }, 300)
      }
      return
    }

    // to skip non-sound text entries in the soundrenderer
    if (!record && ping) {
      // console.log('no record, pinging')
      return ping()
    }

    if (currentSoundClicked) {
      // setDebugMessage('current sound clicked, returning')
      setPlayingFrom('SOUND CLICKED')
      if (shouldPrint) console.log('current sound clicked, returning')
      return setPlaying(true)
    }

    if (audio && !currentSoundClicked && playingNum !== num) {
      // console.log(audio, currentSoundClicked, playingNum, num)
      // setDebugMessage(record?.base_text + ' setting playing to false because playingNum !== num')
      return setPlaying(false)
    }

    // setDebugMessage('setting playing to whether playingNum === num')
    if (playingNum === num) {
      setPlayingFrom('PLAYING NUM === NUM')
      // setDebugMessage(record?.base_text + ' setting playing to whether playingNum === num')
    }

    // setDebugMessage(record?.base_text + ' setting playing to whether playingNum === num')
    return setPlaying(playingNum === num)
  }, [playingNum, audio.src, clickTriggered, currentSoundClicked])

  return { playing, setPlaying, playAudio, playingFrom, debugMessage, setInternalAudioSrc }
}

export default useIsPlaying
