import { useState, useEffect, createContext, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { useViewportSize } from '@mantine/hooks'
import useCourseInfo from 'src/components/navigation/useCourseInfo'
import useStaffRoles from 'src/hooks/useStaffRoles'
import useMobile from 'src/hooks/useMobile'
import { RootState } from 'src/configureStore'

interface NavigationContextType {
  dropdownMenuIsOpen: boolean
  sidebarMenuIsOpen: boolean
  isMobileNav: boolean
  isMobileCourseNav: boolean
  slug: string
  courseInfo: ReturnType<typeof useCourseInfo>
  isAdmin: boolean
  isAffiliate: boolean
  openDropdownMenu: () => void
  closeDropdownMenu: () => void
  openSidebarMenu: () => void
  closeSidebarMenu: () => void
}

const navigationContext = createContext<NavigationContextType>(null)

export const NavigationProvider = ({ children }) => {
  const [dropdownMenuIsOpen, setDropdownMenuIsOpen] = useState(false)
  const [sidebarMenuIsOpen, setSidebarMenuIsOpen] = useState(false)
  const { pathname } = useLocation()
  const history = useHistory()
  const isSignedIn = useSelector((state: RootState) => state.auth.isSignedIn)
  const isMobileNav = useMobile(960) // where it stops looking decent in course nav based on "reading comprehension"
  const courseInfo = useCourseInfo()
  const [isAdmin, _, isAffiliate] = useStaffRoles('admin', 'dev')
  const { width } = useViewportSize()

  const { isCourseLesson } = courseInfo
  const isMobileCourseNav = isMobileNav && isCourseLesson
  const slug = pathname.split('/')[1]

  const openDropdownMenu = () => {
    if (dropdownMenuIsOpen) return // just to avoid unnecessary state updates in mobile nav where avatar appears in dropdown

    setDropdownMenuIsOpen(true)
    if (width <= 500) document.body.style.overflowY = 'hidden' // prevent page scrolling when dropdown is open
  }

  const closeDropdownMenu = () => {
    if (!dropdownMenuIsOpen) return

    setDropdownMenuIsOpen(false)
    document.body.style.overflowY = 'unset'
  }

  const openSidebarMenu = () => setSidebarMenuIsOpen(true)

  const closeSidebarMenu = () => {
    if (!sidebarMenuIsOpen) return
    setSidebarMenuIsOpen(false)
  }

  const handleKeyUp = (e: KeyboardEvent) => { if (e.key === 'Escape') closeDropdownMenu() }

  useEffect(() => {
    window.addEventListener('keyup', (e) => handleKeyUp(e))
    return () => window.addEventListener('keyup', (e) => handleKeyUp(e))
  }, [])

  useEffect(() => {
    closeDropdownMenu()
    closeSidebarMenu()
  }, [pathname])

  useEffect(() => {
    if (isSignedIn && pathname === '/') history.replace('/home')
  }, [pathname])

  const contextValue = {
    dropdownMenuIsOpen,
    sidebarMenuIsOpen,
    isMobileNav,
    isMobileCourseNav,
    slug,
    courseInfo,
    isAdmin,
    isAffiliate,
    openDropdownMenu,
    closeDropdownMenu,
    openSidebarMenu,
    closeSidebarMenu
  }

  return <navigationContext.Provider value={contextValue}>{children}</navigationContext.Provider>
}

const useNavigationContext = () => useContext(navigationContext)
export default useNavigationContext
