import useNavigationContext from 'src/hooks/context/useNavigationContext'
import NavLinkItemsSection from '../NavLinkItemsSection'
import { sidebarNavigationItems } from '../../util'
import { AnimatePresence } from 'framer-motion'
import { SidebarWrapper } from '../../styles/SidebarMenu.styles'

const SidebarMenu = () => {
  const { sidebarMenuIsOpen } = useNavigationContext()

  return (
    <AnimatePresence>
      {sidebarMenuIsOpen && (
        <SidebarWrapper
          initial={{ x: -250 }}
          animate={{ x: 0 }}
          exit={{ x: -250 }}
          transition={{ type: 'spring', stiffness: 200, damping: 25 }}
          onClick={(e) => e.stopPropagation()} // prevent closeSidebarMenu() from firing when clicking inside; called at Navigator.tsx->TailwindResetUnreset
        >
          <NavLinkItemsSection items={sidebarNavigationItems} />
        </SidebarWrapper>
      )}
    </AnimatePresence>
  )
}

export default SidebarMenu
