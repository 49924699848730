import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useViewportSize } from '@mantine/hooks'
import useShrinkTitle from 'src/components/fz_courses/hooks/useShrinkTitle'
import useSubscribed from 'src/hooks/useSubscribed'
import useNavigationContext from 'src/hooks/context/useNavigationContext'
import { RootState } from 'src/configureStore'
import { CourseNavWrapper, CircleButton, Breadcrumbs, CrumbLink, SublessonCrumb } from '../../styles/CoursePosBarStyles'
import { Chevron } from '../Icons'
import { FaAngleRight } from 'react-icons/fa'

const CoursePositionBar = () => {
  const navigator = useSelector((state: RootState) => state.navigator)
  const location = useLocation()
  const history = useHistory()
  const { width } = useViewportSize()
  const { isPremiumSubscribed, isAdmin } = useSubscribed()
  const { isMobileNav, courseInfo: { courseTitle, courseTitleShort } } = useNavigationContext()
  const [shrinkTitle] = useShrinkTitle()

  const { nSlug, nDisabled, pSlug, pDisabled, linkToPaywall, subLesson } = navigator
  const [course, lesson, sublesson] = location.pathname.split('/').filter((p) => p.length && p !== 'courses')
  const sublessonLink = `${course}/${lesson}/${sublesson}`

  const handleGoPrevious = () => pSlug && history.push(`/courses/${pSlug}`)

  const handleGoNext = () => {
    if (linkToPaywall && !isPremiumSubscribed && !isAdmin) history.push(`/courses/${sublessonLink}/upgrade`)
    else if (nSlug) history.push(`/courses/${nSlug}`)
  }

  return (
    <CourseNavWrapper isMobileNav={isMobileNav}>
      <CircleButton onClick={() => handleGoPrevious()} disabled={pDisabled} isMobileNav={isMobileNav}>
        <Chevron left />
      </CircleButton>
      <Breadcrumbs isMobileNav={isMobileNav}>
        <CrumbLink to={`/courses/${course}`} active={false}>
          {width < 600 ? courseTitleShort : courseTitle || 'From Zero'}
        </CrumbLink>
        <FaAngleRight style={{ flexShrink: 0 }} />
        <CrumbLink to={`/courses/${course}/${lesson}`} active={!sublesson}>
          L-{lesson}
        </CrumbLink>
        {sublesson && subLesson?.title && (
          <>
            <FaAngleRight style={{ flexShrink: 0 }} />
            <SublessonCrumb isMobileNav={isMobileNav}>{shrinkTitle(subLesson.title)}</SublessonCrumb>
          </>
        )}
      </Breadcrumbs>
      <CircleButton onClick={() => handleGoNext()} disabled={nDisabled} isMobileNav={isMobileNav}>
        <Chevron />
      </CircleButton>
    </CourseNavWrapper>
  )
}

export default CoursePositionBar
