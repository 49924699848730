import { useEffect, useState } from 'react'

import { Record } from 'src/types'

const useSound = (record: Record, male: boolean, female: boolean, preferred_speaker: string) => {
  const [sound, setSound] = useState(null)
  // initialization of sound based on props/record/bonus data
  useEffect(() => {
    if (!record) return

    // choose the sound based on the speaker override of the bonus data first
    const { sounds, bonus } = record

    if (!sounds) return
    let a = {
      kanako: sounds['Kanako'],
      hikari: sounds['Hikari'],
      kairo: sounds['Takeru'],
      seoyeon: sounds['Seoyeon'],
      jiyoon: sounds['jiyoon'],
      hyunwoo: sounds['hyunwoo'],
      sunhee: sounds['sunhee'],
      tomoko: sounds['Tomoko'],
      natsumi: sounds['Natsumi'],
      mia: sounds['Mia'],
      andres: sounds['Andres'],
    }[bonus?.force_speaker?.toLowerCase()]

    // choose the sound based on the gender override of the bonus data second (if it exists)
    if (!a && bonus?.force_gender) {
      const gender = ['m', 'M', 'male', 'Male', 'man', 'Man', 'boy'].includes(bonus.force_gender)
        ? 'M' : 'F'

      a = {
        M: sounds['Takeru'] || sounds['Mr. Fukumachi'] || sounds['hyunwoo'] || sounds['Andres'],
        F: sounds['Kanako'] || sounds['Hikari'] || sounds['Tomoko'] || sounds['Natsumi'] || sounds['Mia'] || sounds['sunhee'] || sounds['jiyoon'] || sounds['Seoyeon']
      }[gender]
    }

    // if male or female are passed as a prop instead (from convos etc)
    if (!a && female) {
      a = record.sounds['Kanako'] || record.sounds['Hikari'] || record.sounds['Seoyeon'] || record.sounds['jiyoon'] || record.sounds['sunhee'] || record.sounds['Mia'] || record.sounds['Natsumi'] || record.sounds['Tomoko']
    } else if (!a && male) {
      a = record.sounds['Takeru'] || record.sounds['Andres'] || record.sounds['Mr. Fukumachi']
    }

    // default to their preferred speaker if they haven't had one force-picked already
    if (!a) {
      a = record.sounds[preferred_speaker]
      || record.sounds['Kanako'] || record.sounds['Mr. Fukumachi']
      || record.sounds['Yukari'] || record.sounds['Hikari']
      || record.sounds['Takeru'] || record.sounds['default']
      || record.sounds['hyunwoo'] || record.sounds['sunhee']
      || record.sounds['Seoyeon'] || record.sounds['Mia']
      || record.sounds['Andres'] || record.sounds['jiyoon']
      || record.sounds['Tomoko'] || record.sounds['Natsumi']
    }

    setSound(a)
  }, [record, preferred_speaker, male, female])

  return { sound }
}

export default useSound
