import { useDispatch, useSelector } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'
import styled from '@emotion/styled'
import { DropdownCheckbox } from '../../../styles/DropdownStyles'
import { black } from 'src/components/ui/styles'

import { RootState } from 'src/configureStore'
import { REDUX_ACTIONS } from 'src/types'

const ColorKey = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  background-color: var(--off-white);
  padding: 8px;
  border-radius: 20px;
  gap: 5px;

  p {
    font-family: Figtree;
    font-weight: 500;
    color: ${black};
    justify-self: left;
  }

  button {
    grid-row: 1 / span 2;
    grid-column: 3;
    background-color: #47DBC3;
    border-radius: 32px;
    padding: 12px 20px;
    margin-left: 8px;
    justify-self: end;
    align-self: center;
    box-shadow: 0 3px rgba(0, 0, 0, 0.08);

    &:hover {
      filter: saturate(1.3);
    }
  }
`

const ColorizedFuriToggle = () => {
  const isColorized = useSelector((state: RootState) => state.auth.colorized_furi)
  const isSwapped = useSelector((state: RootState) => state.auth.swap_furi_colors)
  const progSettings = useSelector((state: RootState) => state.auth.progressive_settings)
  const dispatchColorized = useDispatch()

  const handleChangeSettings = (setting: boolean) => {
    dispatchColorized({
      type: REDUX_ACTIONS.SWITCH_COLORIZED_FURI,
      payload: setting
    })
  }
  const swapFuriColors = () => {
    dispatchColorized({
      type: REDUX_ACTIONS.SWAP_FURI_COLORS
    })
  }

  return (
    <>
      <DropdownCheckbox
        checked={isColorized}
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleChangeSettings(e.currentTarget.checked)}
        label='Colorized Furigana:'
        labelPosition='left'
        size='md'
        isChecked={isColorized}
      />
      <AnimatePresence initial={false}>
        {isColorized && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
          >
            <div className='tw-h-4' /> {/* spacer; for avoiding glitches with framer motion */}
            <ColorKey>
              <p>{isSwapped ? '🔵' : '🔴'}</p>
              <p>kun-yomi</p>
              <button onClick={swapFuriColors}>Swap Colors</button>
              <p>{isSwapped ? '🔴' : '🔵'}</p>
              <p>on-yomi</p>
            </ColorKey>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default ColorizedFuriToggle
