import { usePostLogoutUser } from 'src/hooks/mutations/user'
import { bluegrey } from 'src/components/ui/styles'
import styled from '@emotion/styled'

const Button = styled.button`
  background: none;
  border: 1px solid white;
  border-radius: 24px;
  font-family: 'Tilt Warp';
  cursor: pointer;
  font-size: 1.25rem;
  padding: 8px 24px;
  margin: 12px auto;

  &:hover {
    background: ${bluegrey}20;
  }
`

const LogoutButton = () => {
  const { mutate: logout } = usePostLogoutUser()

  return (
    <Button onClick={() => logout()} className="dropdown-item">
      Log Out
    </Button>
  )
}

export default LogoutButton
